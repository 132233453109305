import { SET_PROCESS } from '../Action/ActionType';
const processReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PROCESS:
      return {
        process: action.payload,
      };
    case 'kdkk':
      return action.payload;
    case 'yasddf':
      return action.payload;
    default:
      return state;
  }
};
export default processReducer;
