import { SET_PORTFOLIO } from '../Action/ActionType';
const portfolioReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PORTFOLIO:
      return { portfolio: action.payload };
    case 'kdkk':
      return { portfolio: action.payload };
    case 'yasddf':
      return { portfolio: action.payload };
    default:
      return { state };
  }
};

export default portfolioReducer;
