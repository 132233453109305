import React from 'react';
// import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import testimonialImg from '../assets/skyward/testimonial_bg.png';

const backgroundImage = testimonialImg;

const TestimonialSection = ({ testimonials }) => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const totalTestimonials = testimonials.length;

  // const goToPrevious = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === 0 ? totalTestimonials - 1 : prevIndex - 1
  //   );
  // };

  // const goToNext = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === totalTestimonials - 1 ? 0 : prevIndex + 1
  //   );
  // };

  return (
    <section
      className='testimonial_top_spacing relative flex justify-center items-center isolate overflow-hidden py-24 sm:py-32 w-full font-Inter'
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: '50px',
      }}
    >
      {/* <BsChevronLeft
        className='lg:block hidden absolute left-4 text-[#000] text-4xl cursor-pointer hover:text-gray-600 z-10'
        onClick={goToPrevious}
      /> */}

      <div className='relative overflow-hidden mx-auto max-w-2xl lg:max-w-4xl'>
        <h6 className='text-center font-semibold leading-[40px] text-[#000] text-[36px] mt-5'>
          Testimonials
        </h6>
        <p className='font-normal text-[16px] pt-5 text-[#000] text-center'>
          Let’s see what our client says
        </p>
        <div
          className='relative flex transition-transform duration-500 ease-in-out'
          // style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className='flex-shrink-0 w-full px-4'
              style={{ minWidth: '100%' }}
            >
              <div className=' bg-opacity-75 md:p-8 p-0 rounded-lg'>
                <div className='mt-10 pt-8'>
                  <img
                    alt='client logo'
                    src={testimonial.clientlogo}
                    className='mx-auto rounded-full w-[100px] h-[100px]'
                  />
                </div>
                <div className='font-medium w-full leading-8 text-xl text-center pt-5 mt-10 md:p-0'>
                  <div className='pb-8'>
                    <p className='italic text-[#000]'>
                      {testimonial.description}
                    </p>
                    <div className='mt-8'>
                      <h6 className='font-semibold text-[20px] text-[#000]'>
                        {testimonial.author}
                      </h6>
                      <p className='text-[#5A5A5A] text-[16px]'>
                        {testimonial.founder}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <BsChevronRight
        className='absolute right-4 text-[#000] text-4xl cursor-pointer hover:text-gray-600 z-10 lg:block hidden'
        onClick={goToNext}
      /> */}
    </section>
  );
};

export default TestimonialSection;
