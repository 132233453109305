import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <div
      className={`w-[1360px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[97px] max-w-full text-center text-45xl text-mediumslateblue-200 font-inter lg:flex-wrap mq750:gap-12 mq450:gap-6 ${className}`}
    >
      <div className='w-[484px] flex flex-col items-start justify-start pt-[231px] px-0 pb-0 box-border min-w-[484px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[150px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[25px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
            <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
              Planwell
            </h1>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[30px] text-xl text-dimgray-200'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
              <div className='h-px w-[261px] relative border-lightgray border-t-[1px] border-solid box-border' />
            </div>
            <div className='h-[60px] relative leading-[30px] inline-block mq450:text-base mq450:leading-[24px]'>
              <p className='m-0'>
                Making confident financial decisions for retirement,
              </p>
              <p className='m-0'>
                home buying, and college savings endeavours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Planwell/mask-group@2x.png'
      />
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
