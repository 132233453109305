import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/scheduling/frame1.svg';
import logo from '../../../assets/scheduling/logo-2-2-1@2x.png';
import benefitLogo from '../../../assets/scheduling/logo-2-2-1@2x.png';
import rectangle from '../../../assets/scheduling/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/scheduling/icon1.svg';
import icon2 from '../../../assets/scheduling/icon2.svg';
import icon3 from '../../../assets/scheduling/icon3.svg';
import icon4 from '../../../assets/scheduling/icon4.svg';
import interfaceImg from '../../../assets/scheduling/interface.svg';
import clientlogo from '../../../assets/scheduling/clientlogo1.png';
import benefitIcon from '../../../assets/scheduling/bxuparrow@2x.png';
import benefitImage from '../../../assets/scheduling/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';
import TestimonialSection from '../../../common/TestimonialSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'User Role Management',
    description:
      'Handling multiple user roles with distinct permissions was complex and prone to errors.',
    boxClass: '#014558',
  },
  {
    number: 2,
    title: 'Appointment Scheduling',
    description:
      'Managing therapists schedules and ensuring optimal use of their availability was inefficient.',
    boxClass: '#036C89',
  },
  {
    number: 3,
    title: 'Data Organization',
    description:
      'Organizing and accessing data across multiple tables was cumbersome.',
    boxClass: '#038CB1',
  },
  {
    number: 4,
    title: 'Communication',
    description:
      'Lacked a streamlined messaging solution for group discussions and attachment sharing.',
    boxClass: '#04A9D6',
  },
];

const solutionData = [
  {
    title: 'User Role Management',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'Designed a dynamic system for creating and managing user permissions,',
      'ensuring tailored access for roles such as admin, super admin, therapist,',
      'secretary, school placement division (SPD), and caseworker.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Appointment Scheduling',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: [
      'Developed a powerful appointment management tool allowing secretaries to',
      'manage therapists schedules efficiently. The app offers multiple views (weekly,',
      'daily, monthly, and list) and real-time updates for appointment statuses.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Data Organization',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Implemented multiple tables for better data organization and access control,',
      'ensuring a smooth user experience.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Communication',
    imageSrc: icon4,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Created a custom messaging solution enabling group discussions, attachment',
      'sharing, and more, enhancing internal communication.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Optimized Appointment Management',
    description:
      'Secretaries could manage therapists schedules more efficiently, reducing appointment conflicts by',
    percentage: '50%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Enhanced Data Organization',
    description:
      'Using multiple tables improved data accessibility and organization by',
    percentage: '60%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Improved Communication',
    description:
      'The custom messaging solution facilitated better internal communication, increasing collaboration efficiency by',
    percentage: '40%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Secure and Tailored Access',
    description:
      'Role-based access control ensured data security and provided a customized user experience, enhancing user satisfaction by',
    percentage: '30%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
];

const testimonials = [
  {
    description:
      'Devtrust Ezeryeshiva App has transformed our appointment management process. The tailored user roles and efficient scheduling system have significantly reduced our workload and improved our service efficiency.',
    author: 'Mordy Stern ',
    founder: '',
    clientlogo: clientlogo,
  },
  {
    description:
      'Devtrust Ezeryeshiva App has transformed our appointment management process. The tailored user roles and efficient scheduling system have significantly reduced our workload and improved our service efficiency.',
    author: 'Mordy Stern ',
    founder: '',
    clientlogo: clientlogo,
  },
  // Add more testimonials here
];

const SkywardPage = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Ezeryeshiva'
          textcolor='#014558'
          description='Optimizing Appointment Management
          with Devtrust'
          image={frame1}
          How='How'
          DEVtrust={''}
          highlightedText={
            'Devtrust Enhanced Appointment Scheduling and Data Management for Mordy Stern'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Ezeryeshiva needed an efficient system to manage appointments and client/student data. Previously relying on Airtable, they found it inadequate for their growing requirements. This case study explores how DEVtrust developed a robust app with role-based access, appointment management, and custom messaging.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Mordy Stern faced several challenges with their existing setup'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #199FC5, #014558)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#0B5266'}
        textColor={'#0EC5F8'}
        gradient='linear-gradient(135deg, #199FC5, #014558 60.5%)'
      />
      <InterfaceSection
        heading='The Ezeryeshiva App’s user-friendly interface'
        Description='simplifies appointment management and data organization'
        interfaceImg={interfaceImg}
      />
      <TestimonialSection testimonials={testimonials} />
    </div>
  );
};

export default SkywardPage;
