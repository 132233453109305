import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const OutcomeIcons = ({
  className = '',
  enhancedUserInterface,
  propDisplay,
  theAdditionOfUserFriendlyFilte,
  users,
}) => {
  const enhancedUserInterfaceStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div
      className={`w-[738px] flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter mq750:flex-wrap ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[2]'
          alt=''
          src='/caseStudies/OZ-Party/bxuparrow@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
        <b
          className='relative z-[2] mq450:text-base'
          style={enhancedUserInterfaceStyle}
        >
          {enhancedUserInterface}
        </b>
        <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
          <p className='m-0'>{theAdditionOfUserFriendlyFilte}</p>
          <p className='m-0'>{users}</p>
        </div>
      </div>
    </div>
  );
};

OutcomeIcons.propTypes = {
  className: PropTypes.string,
  enhancedUserInterface: PropTypes.string,
  theAdditionOfUserFriendlyFilte: PropTypes.string,
  users: PropTypes.string,

  /** Style props */
  propDisplay: PropTypes.any,
};

export default OutcomeIcons;
