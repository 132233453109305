import React from 'react';
import Aanalysis from '../../assets/images/servicesimg1.webp';
import sideIcon from '../../assets/images/leftTrangle.webp';
import style from './Summury.module.css';
import { Link } from 'react-router-dom';

function Summary() {
  const handleScroll = () => {
    window.scroll({
      top: 6800,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`${style.Summary} mx-auto`}>
      <div className={`${style.SummaryMain}`}>
        <div className='' data-aos='fade-right'>
          <div className={`${style.SummaryRightContent}`}>
            <h3 className={`${style.SummaryHeadingOne}`}>DEVtrust is a</h3>
            <h3 className={`${style.SummarysecondHeadingOne}`}>
              <span className='text-custom-green xsm:text-[30px] md:text-[40px]'>
                {' '}
                Full-Service software &nbsp; development agency{' '}
              </span>
            </h3>

            <h3 className={`${style.SummaryHeadingTwo}`}>
              offering solutions businesses deserve.
            </h3>
            <p className={`${style.SummaryPara}`}>
              Be it a start-up or an established enterprise, we strive to exceed
              expectations by working with a razor-sharp focus on craftsmanship
              & communication.
            </p>
          </div>
        </div>
        <div className='xsm:mx-auto md:mx-0' data-aos='fade-left'>
          <div className={`${style.RightCardOne}`}></div>
          <br />
          <div className={`${style.RightCardTwo}`}>
            <img className={`${style.CardTwoImage}`} src={Aanalysis} />
            <h5 className={`${style.CardTwoTextOne}`}>How Can We Help</h5>
            <h3 className={`${style.CardTwoTextTwo}`}>Your business to Grow</h3>
            <Link to='/get-in-touch'>
              <button
                className='common_btn py-2.5 xl:px-[25px] md:px-3	xl:mt-10 md:mt-4 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                onClick={() => handleScroll()}
              >
                <span className='relative  xl:text-lg block w-full text-base font-normal font-[Inter] leading-[21px]  px-3'>
                  Get In Touch
                </span>
              </button>
            </Link>
          </div>
          <img
            className={`${style.SideIcon}`}
            width='100'
            height='100'
            src={sideIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default Summary;
