import React, { useMemo } from 'react';
import Indevidual from '../../assets/images/Individual.svg';
import Team from '../../assets/images/Team.svg';
import Button from '../../atoms/button/Button';
import FormCard from '../../atoms/formcard/FormCard';
import Progressbar from '../../atoms/progress/Progressbar';
const SecondStep = ({ nextStep, prevStep, fvalue, steps }) => {
  const data = [
    {
      id: '1',
      title: 'Hire an individual developer',
      img: Indevidual,
      value: 'Single developer',
    },
    {
      id: '2',
      title: 'Build a team of developers',
      img: Team,
      value: 'team',
    },
  ];
  const selectItem = (value) => {
    fvalue.needs = value;
    nextStep();
  };
  const formcard = useMemo(() => {
    return data.map((item, index) => (
      <FormCard data={item} key={index} second='sec' select={selectItem} />
    ));
  }, []);
  return (
    <div className=' bg-white py-24'>
      <h1 className='font-Gelasio xl:text-[50px] py-0 text-[40px] font-bold xl:leading-[63.48px] block mx-auto text-center pt-5 leading-[51px]'>
        How Many Developers <span className='text-[#25C7C7]'>Do You Need?</span>
      </h1>

      <div className='container mx-auto '>
        <div className='flex justify-center py-10 pb-14'>
          <Progressbar steps={steps} prevStep={prevStep} nextStep={nextStep} />
        </div>

        <div className='box-content flex lg:flex-nowrap flex-wrap gap-7 justify-center p-4'>
          {formcard}
        </div>
        <Button steps={steps} prevStep={prevStep} />
      </div>
    </div>
  );
};

export default SecondStep;
