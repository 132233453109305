import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './DevtrustAssistance.module.css';

const DevtrustAssistance = ({ className = '' }) => {
  return (
    <section className={[styles.devtrustAssistance, className].join(' ')}>
      <div className={styles.assistanceContent}>
        <div className={styles.assistanceDescription}>
          <h2 className={styles.discoverHowDevtrust}>Partner with DEVtrust</h2>
          <div className={styles.contactPrompt}>
            <div className={styles.contactUsForContainer}>
              {`Contact us for more information or to `}
              <span className={styles.scheduleAMeeting}>
                schedule a meeting
              </span>{' '}
              with our experts
            </div>
          </div>
        </div>
        <div className={styles.contactButton}>
          <Button
            className={styles.contactButtonChild}
            disableElevation
            variant='contained'
            sx={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '18',
              background: '#474747',
              borderRadius: '0px 0px 0px 0px',
              '&:hover': { background: '#474747' },
              width: 143,
              height: 42,
            }}
          >
            Contact us
          </Button>
        </div>
      </div>
    </section>
  );
};

DevtrustAssistance.propTypes = {
  className: PropTypes.string,
};

export default DevtrustAssistance;
