import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/alimac/frame1.svg';
import logo from '../../../assets/alimac/logo1.png';
import benefitLogo from '../../../assets/alimac/logo1.png';
import rectangle from '../../../assets/alimac/img4.svg';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/alimac/1.svg';
import icon2 from '../../../assets/alimac/2.svg';
import icon3 from '../../../assets/alimac/3.svg';
import icon4 from '../../../assets/alimac/4.svg';
import icon5 from '../../../assets/alimac/5.svg';
import interfaceImg from '../../../assets/alimac/interface.svg';

import benefitIcon from '../../../assets/alimac/bxuparrow@2.png';
import benefitImage from '../../../assets/alimac/img5.svg';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Multiple Platforms',
    description:
      'Businesses were hiring their employees using different employee onboarding & payroll platforms including but not limited to Efficient Hire & Talent Reef, iSolved, Crunchtime & Altametrics',
    boxClass: '#0070B9',
  },
  {
    number: 2,
    title: 'Manual Processes',
    description:
      'The employee data was entered manually through a difficult and time-consuming process into different onboarding and payroll systems.',
    boxClass: '#1F8FD9',
  },
  {
    number: 3,
    title: 'Complex Payroll Generation',
    description:
      'Difficulty managing employee bank details, paystubs, salary, paycheck & other payroll-related data.',
    boxClass: '#39AFFC',
  },
];

const solutionData = [
  {
    title: 'Single Platform',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'Alimac provided a platform to connect multiple onboarding, and payroll software',
      'through API integrations and transfer employees data from onboarding to payroll',
      'systems (iSolved, Crunchtime, Altametrics) through automated processes.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Automated Salary Calculation',
    imageSrc: icon2,
    imageAlt: 'Transcription',
    descriptions: [
      'The application pulls data from punch recording software (like 7shift) and posts',
      'it on the payroll system to calculate the salary and then transfer it to the',
      'employees bank account.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Paystub',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Employees receive automated paystubs one day before the paycheck date. Each',
      'company has a different paycheck period based on that, each employee gets',
      'their paystub report as per their pay period weekly, bi-weekly, and monthly.',
      'Boldesk API integration to read, create, edit, and delete service requests.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Automated Hire and Termination',
    imageSrc: icon4,
    imageAlt: 'Document Management Image',
    descriptions: ['The system automated employee termination and hiring.'],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Reports & Analytics',
    imageSrc: icon5,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Businesses can view multiple reports with graphs and charts',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Organized Process',
    description: 'Streamlined onboarding & Payroll management.',
    // percentage: '60%',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Easy Access',
    description: 'All the information is now accessible on a single platform.',
    // percentage: '80%',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Time-Saving',
    description:
      'Automated processes took care of the complexities thus reducing overheads and saving time.',
    // percentage: '100%',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Transparent Flow',
    description:
      'Significant increase in employee satisfaction levels due to prompt and transparent flow of information.',
    // percentage: '100%',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
];

const Alimac = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Alimac'
          textcolor='#0070B9'
          description=''
          image={frame1}
          How=''
          DEVtrust={''}
          highlightedText={
            'A SaaS application for payroll and employee management'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Alimac, an employee onboarding and payroll management SAAS application catering to the hospitality<br/> sector contacted DEVtrust to automate its business operations.<br/> <br/>
        DEVtrust intended to use a wide range of third-party APIs and tools to develop a well-rounded SAAS<br/> solution.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #F36F21, #0070B9 90.5%)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#005B96'}
        textColor={''}
        gradient='linear-gradient(135deg, #F36F21, #0070B9 60.5%)'
      />
      <InterfaceSection
        heading='Alimac’s user-friendly interface'
        Description='Simplified employee onboarding and payroll management through automated processes'
        interfaceImg={interfaceImg}
      />
    </div>
  );
};

export default Alimac;
