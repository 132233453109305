import React from 'react';
import { InlineWidget } from 'react-calendly';
import { MdCancel } from 'react-icons/md';

function CalendarComponent({ handleCancel }) {
  return (
    <div className='calender_pic relative'>
      <InlineWidget
        url='https://calendly.com/abhianjsri/30min?hide_event_type_details=1&hide_gdpr_banner=1'
        styles={{ minWidth: '320px', height: '700px' }}
      />
      <button
        onClick={handleCancel}
        className='absolute top-[0px] left-[-10px] bg-black rounded-xl'
      >
        <MdCancel size={24} />
      </button>
    </div>
  );
}

export default CalendarComponent;
