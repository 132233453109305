import React, { useEffect, useMemo, useState } from 'react';
import TechTab from '../../atoms/tab/TechTab';
import ReactSlicky from '../reactSlicky/ReactSlicky';
import { getTechnologies } from '../../utils/index';
import TechnologyImage from '../../atoms/technologyImage/TechnologyImage';
const Technologies = () => {
  const [technology, setTechnology] = useState('Back-end');
  const [technologydata, setTechnologyData] = useState([]);
  const [filtertechnologies, setFilterTechnologies] = useState([]);

  const chooseTechnology = (technologyname) => {
    let filtertech = technologydata?.filter(
      (item) => item.acf.tech_type === technologyname
    );
    setFilterTechnologies(filtertech);
    setTechnology(technologyname);
  };
  const getTechnologiesData = async () => {
    let data = await getTechnologies();
    let filtertech = data?.filter((item) => item.acf.tech_type === technology);
    setTechnologyData(data);
    setFilterTechnologies(filtertech);
  };

  useEffect(() => {
    getTechnologiesData();
  }, []);
  const technologyTabs = useMemo(() => {
    return tech.map((item, index) => (
      <TechTab
        data={item}
        key={index}
        myfun={chooseTechnology}
        active={technology}
      />
    ));
  }, [technology, technologydata]);

  const technologiesDetails = useMemo(() => {
    return (
      <ReactSlicky length={filtertechnologies.length} max={6}>
        {filtertechnologies?.map((item) => (
          <TechnologyImage key={item.id} data={item} />
        ))}
      </ReactSlicky>
    );
  }, [filtertechnologies]);
  return (
    <div
      className='bg-white xl:py-28 xsm:py-12 overflow-hidden '
      data-aos='fade-right'
    >
      <div className='mx-auto lg:max-w-7xl xl:px-20 md:px-6 xsm:px-4'>
        <h1 className='text-center xl:text-[40px] xsm:text-[30px] leading-[41px] xl:leading-[63px] font-Gelasio text-custom-green font-bold pb-6'>
          Technologies
        </h1>
        <div className='flex justify-between flex-wrap xl:gap-0 xsm:gap-3 xl:my-8 xsm:mb-10 xsm:mt-4 xsm:border-0 xl:border-custom-gray xl:border-b-[1px]	'>
          {technologyTabs}
        </div>
        <div className='py-2 technology-slick'>{technologiesDetails}</div>
      </div>
    </div>
  );
};
const tech = [
  'Mobile',
  'Front-end',
  'Back-end',
  'Databases',
  'CMS',
  'Cloud',
  'DevOps',
];
export default Technologies;
