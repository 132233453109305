import React from 'react';

const HomeBanner = ({
  title,
  subtitle,
  description,
  image,
  How,
  DEVtrust,
  highlightedText,
  textcolor,
  subtextcolor,
}) => {
  return (
    <>
      <div className='case-study'>
        <div className='content-wrapper'>
          <div className='frame-container'>
            <div className='header-content-wrapper'>
              <div className='banner-content'>
                <div className='title-wrapper'>
                  <h1 className='skyward'>
                    <span style={{ color: textcolor }}>{title}</span>
                    <span style={{ color: subtextcolor }}>{subtitle}</span>
                  </h1>
                </div>
                <p className='transforming-real-estate'>{description}</p>
              </div>
            </div>
            <div className='header-divider'>
              <div className='header-divider-child'></div>
            </div>
            <div className='banner-text'>
              <p className='how-devtrust-enabled-skyward-t'>
                <span className='how'>{How} </span>
                <b className='devtrust'>{DEVtrust}</b>
                <span>{highlightedText}</span>
              </p>
            </div>
          </div>
        </div>
        <img className='mask-group-icon' loading='lazy' alt='img' src={image} />
      </div>
    </>
  );
};

export default HomeBanner;
