import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[67%] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '45px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#72EDF2,_#5151E5)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-16 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,_#72EDF2,_#5151E5)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 text-[48px] self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[48.5px] w-[50px] relative object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Doctornal-Health/group-1000001326.svg'
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-wrap justify-start gap-[60px] max-w-full text-center mt-20'>
          <div className='w-full grid grid-cols-3 gap-[60px]'>
            <MarketplaceItems
              placeholder='1'
              marketplace='Intuitive Interface Design'
              propBackgroundColor='#535AE6'
              propBackgroundColor1='#535AE6'
              definingAndImplementingFeatures='Designing an intuitive and user-friendly 
      interface for both patients and 
      healthcare providers. '
            />
            <MarketplaceItems
              propFlex='1'
              propBackgroundColor='#666DF5'
              propBackgroundColor1='#666DF5'
              placeholder='2'
              marketplace='Stable Connections'
              definingAndImplementingFeatures='Ensuring high-quality, stable video and 
      audio connections, even in areas with
      low bandwidth.'
            />
            <MarketplaceItems
              propMinWidth='308px'
              propBackgroundColor='#7B82FD'
              propBackgroundColor1='#7B82FD'
              placeholder='3'
              marketplace='Minimizing Latency'
              definingAndImplementingFeatures='Implementing technologies to 
      minimize latency and prevent 
      disruptions during consultations.'
            />
          </div>

          <div className='w-full grid grid-cols-3 gap-[60px]'>
            <MarketplaceItems
              propMinWidth='308px'
              propBackgroundColor='#8F94FF'
              propBackgroundColor1='#8F94FF'
              placeholder='4'
              marketplace='Efficient Scheduling'
              definingAndImplementingFeatures='Implementing an efficient scheduling 
      system to manage appointments, 
      cancellations, and rescheduling.'
            />
            <MarketplaceItems
              propMinWidth='308px'
              propBackgroundColor='#A1A6FF'
              propBackgroundColor1='#A1A6FF'
              placeholder='5'
              marketplace='Balancing Availability and Demand'
              definingAndImplementingFeatures='Balancing provider availability with 
      patient demand to minimize 
      wait times.'
            />
            {/* This div will be an empty column to fill space */}
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
