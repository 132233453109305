import React from 'react';
import HeaderContent from './components/HeaderContent';
import ProblemStatement from './components/ProblemStatement';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import DevtrustAssistance from './components/DevtrustAssistance';

const CaseStudiesAirConcierge = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[83px] box-border gap-[145px] leading-[normal] tracking-[normal] mq750:gap-[72px] mq450:gap-9'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Air-Concierge/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Air-Concierge/background.svg'
      />
      <img
        className='w-full h-[823px] absolute !m-[0] top-[1417px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Air-Concierge/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1251px] right-[-48px] rounded-26xl object-cover z-[1]'
        alt=''
        src='/caseStudies/Air-Concierge/shape@2x.png'
      />
      <section className='self-stretch flex flex-col items-end justify-start max-w-full'>
        <HeaderContent />
      </section>
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[90px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            <p className='m-0'>
              Air Concierge, a leading hospitality management team, needed a
              robust and
            </p>
            <p className='m-0'>
              scalable web platform to effectively market and service properties
              for their clients. The
            </p>
            <p className='m-0'>
              platform required integration with multiple APIs to handle
              property listings, secure
            </p>
            <p className='m-0'>
              transactions, and streamline contract execution.
            </p>
          </div>
        </div>
      </section>
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2751px] right-[-187.4px] z-[1]'
        alt=''
        src='/caseStudies/Air-Concierge/group-3051956.svg'
      />
      <div className='w-2 h-2 absolute !m-[0] top-[2913px] left-[490px] rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
      <ProblemStatement />
      <FrameComponent />
      <FrameComponent1 />
      <FrameComponent2 />
      <DevtrustAssistance />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesAirConcierge;
