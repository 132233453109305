import { SET_ALERT } from '../Action/ActionType';
const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ALERT:
      return action.payload;
    case 'kdkk':
      return action.payload;
    case 'yasddf':
      return action.payload;
    default:
      return state;
  }
};
export default alertReducer;
