import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setLoader } from '../../redux/Action/actions';
import closeimg from '../../assets/images/close.webp';
import logo from '../../assets/images/logo.webp';
import { FIELD_REQUIRED } from '../../redux/constant/ErrorMessage';
import { checkEmail } from '../../utils/Validation';
import { submitForm } from '../../utils';
import style from './Modal.module.css';
export default function HireTalentModal(props) {
  const { showModal, hideModal } = props;
  const [data, setData] = useState([]);
  const [service, setService] = useState('Other');
  const [validateErr, setValidateErr] = useState({});
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
    setData({ ...data, [name]: value });
  };
  const handleCheck = (checktype) => {
    setService(checktype);
  };
  const isValid = () => {
    let valid = true;
    if (data?.fullname === undefined || data?.fullname.trim() === '') {
      validateErr.fullname = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.email === undefined || data?.email.trim() === '') {
      validateErr.email = FIELD_REQUIRED;
      valid = false;
    } else {
      let { status, msg } = checkEmail(data?.email);
      if (status !== undefined) {
        valid = status;
        validateErr.email = msg;
      }
    }
    if (data?.help === undefined || data?.help.trim() === '') {
      validateErr.help = FIELD_REQUIRED;
      valid = false;
    }
    setValidateErr({ ...validateErr });
    return valid;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid();
    if (validateData === true) {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', data?.fullname);
      formdata.append('email', data?.email);
      formdata.append('help', data?.help);
      formdata.append('service', service);
      formdata.append('type', 'techtalent');
      let res = await submitForm(formdata);
      dispatch(setLoader(false));
      if (res.status === 200) {
        dispatch(setAlert({ type: 'success', msg: 'File Sent Successfully.' }));
        setData({});
        setService('Other');
        return;
      }
      dispatch(setAlert({ type: 'danger', msg: 'Somethings Went Wrong.' }));
    }
  };
  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
            <div
              className='relative w-auto my-6   max-w-auto mx-4'
              data-aos='fade-down'
              data-aos-offset='100'
              data-aos-delay='100'
            >
              <div className={`${style.modalbg}`}>
                <div className=' p-5  text-end'>
                  <button
                    className=' ml-auto  border-0 text-black  text-3xl leading-none font-semibold'
                    onClick={() => hideModal()}
                  >
                    <img src={closeimg}></img>
                  </button>
                </div>

                <div className='relative pt-6 flex flex-col items-center  justify-center '>
                  <img
                    className='h-[80px] xl:w-[342.12px] w-[200px] object-contain'
                    src={logo}
                  ></img>
                  <p className=' font-Inter my-6 xl:w-[560px] w-auto text-justify  text-[#000] text-lg font-medium leading-[30px]'>
                    Thank you for your interest in DEVtrust. We’d like to ask
                    you a few questions to get to know you and your requirements
                    better.
                  </p>
                </div>

                <div className=' font-Inter xl:p-10 p-2 xl:px-16 '>
                  <form onSubmit={(e) => onSubmit(e)} className='w-full'>
                    <div className='w-full  px-3 mb-8'>
                      <input
                        name='fullname'
                        value={data?.fullname || ''}
                        onChange={(e) => handleChange(e)}
                        className=' appearance-none  block w-full bg-white text-gray-700 border border-gray-200 rounded py-4 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                        id='grid-last-name'
                        type='text'
                        placeholder='Full Name'
                      />
                      {validateErr && (
                        <span className='text-end	block	w-[100%] text-red-600	'>
                          {validateErr?.fullname}
                        </span>
                      )}
                    </div>

                    <div className='w-full  px-3 mb-8'>
                      <input
                        name='email'
                        value={data?.email || ''}
                        onChange={(e) => handleChange(e)}
                        className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-4 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                        id='grid-last-name'
                        type='text'
                        placeholder='Email Address'
                      />
                      {validateErr && (
                        <span className='text-end	block	w-[100%] text-red-600	'>
                          {validateErr?.email}
                        </span>
                      )}
                    </div>

                    <div className=' w-full  px-3 mb-8'>
                      <p className='text-lg font-medium text-[#000] leading-[30px]'>
                        Select your service
                      </p>
                      <div className='w-full flex justify-between flex-wrap py-5 mb-8'>
                        <label className=' block text-[#808080] font-normal'>
                          <input
                            className='mr-2 leading-tight w-4 h-4 text-teal-500'
                            checked={
                              service === 'Staff Augmentation' ? true : false
                            }
                            type='checkbox'
                            name='checkbox'
                            onChange={() => handleCheck('Staff Augmentation')}
                          />
                          <span className='text-lg  leading-[30px]'>
                            Staff Augmentation
                          </span>
                        </label>
                        <label className=' block text-[#808080] font-normal'>
                          <input
                            name='checkbox'
                            className='mr-2 leading-tight w-4 h-4 text-teal-500'
                            checked={
                              service === 'Autonomous Team' ? true : false
                            }
                            type='checkbox'
                            onChange={() => handleCheck('Autonomous Team')}
                          />
                          <span className='text-lg  leading-[30px]'>
                            Autonomous Team
                          </span>
                        </label>
                        <label className=' block text-[#808080] font-normal'>
                          <input
                            name='checkbox'
                            className='mr-2 leading-tight w-4 h-4 text-teal-500'
                            checked={service === 'Other' ? true : false}
                            type='checkbox'
                            onChange={() => handleCheck('Other')}
                          />
                          <span className='text-lg  leading-[30px]'>Other</span>
                        </label>
                      </div>
                    </div>

                    <div className='w-full px-3 mb-8'>
                      <textarea
                        name='help'
                        onChange={(e) => handleChange(e)}
                        value={data?.help || ''}
                        rows='6'
                        className='appearance-none  block w-full bg-white text-gray-700 border border-gray-200 rounded py-4 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                        id='grid-last-name'
                        type='text'
                        placeholder='How can we help?'
                      />
                      {validateErr && (
                        <span className='text-end	block	w-[100%] text-red-600	'>
                          {validateErr?.help}
                        </span>
                      )}
                    </div>

                    <div className='flex justify-center '>
                      <button
                        type='submit'
                        className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block '
                      >
                        <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                        <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21px]'>
                          Send Message
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
}
