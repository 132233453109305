import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`w-[100%] flex flex-row items-center justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-29xl text-white font-Inter mq450:pb-[39px] mq450:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[1117px] flex flex-col items-start justify-start gap-[90px] max-w-full lg:gap-[45px] mq750:gap-[22px] '>
        <div
          className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#0c83a2,_#01cabb)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[30px] box-border relative gap-[15.4px] max-w-full z-[2] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border ml-4 mt-20'
          style={{ borderRadius: '45px' }}
        >
          <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#0c83a2,_#01cabb)] hidden max-w-full z-[0]' />
          <div className='self-stretch flex flex-row items-start justify-end'>
            <img
              className='h-[45.2px] w-[70px] relative object-cover z-[1]'
              alt=''
              src='/caseStudies/Krugg/blklogo-1-1-1@2x.png'
            />
          </div>
          <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[1] font-inherit mq450:text-10xl mq1050:text-19xl text-5xl'>
            <p className='m-0'>
              <b>{`Problem `}</b>
            </p>
            <p className='m-0'>Statement</p>
          </h1>
          <h2 className='m-0 h-[70px] relative text-2xl leading-[35px] font-medium font-inherit inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
            <p className='m-0'>Here are the challenges which we faced</p>
            <p className='m-0'>at the time of develop the application</p>
          </h2>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start gap-[63px] max-w-full text-center text-13xl mq750:gap-4 mq1050:flex-wrap pt-8'>
          <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[381px] max-w-full mq450:min-w-full mq750:gap-5'>
            <div className='flex items-center justify-center h-[69px] w-[69px] bg-[#0B85A2] rounded-lg z-[1]'>
              <div className='h-[69px] w-[69px] hidden'></div>
              <b className='text-white text-3xl font-bold z-[1]'>1</b>
            </div>

            <div className='self-stretch flex flex-col items-start justify-start gap-[33px] text-left text-3xl text-[#000]'>
              <h3 className='m-0 font-semibold z-[1] mq450:text-lg'>
                Warehouse Management
              </h3>

              <div className='text-lg leading-[30px] z-[1] text-[#5A5A5A]'>
                <ol className='list-decimal ml-5'>
                  <li className='mb-2'>
                    The need to determine available rack space and item
                    placements based on the inventory levels.
                  </li>
                  <li className='mb-2'>
                    Manual efforts leading to potential errors and
                    inefficiencies.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className='w-[498px] flex flex-col items-start justify-start gap-10 min-w-[498px] max-w-full mq750:gap-5 mq750:min-w-full mq1050:flex-1'>
            <div className='flex items-center justify-center h-[69px] w-[69px] bg-[#16A79C] rounded-lg z-[1]'>
              <div className='h-[69px] w-[69px] hidden'></div>
              <b className='text-white text-3xl font-bold z-[1]'>2</b>
            </div>

            <div className='self-stretch flex flex-col items-start justify-start gap-3.5 text-left text-3xl text-[#000]'>
              <h3 className='m-0 font-semibold z-[1] mq450:text-lg'>
                Shipping and Invoicing
              </h3>

              <div className='text-lg leading-[30px] z-[1] text-[#5A5A5A]'>
                <ol className='list-decimal ml-5'>
                  <li className='mb-2'>
                    Inefficient comparison and management of shipping quotes
                    from FedEx and UPS.
                  </li>
                  <li className='mb-2'>
                    Lack of automated shipment label generation, packing slips,
                    email notifications, and real-time tracking.
                  </li>
                  <li className='mb-2'>
                    Complicated invoicing and bill uploading processes.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
