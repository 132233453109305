import React, { useState, useEffect } from 'react';
import TechnologyTools from '../../atoms/technologytools/TechnologyTools';
import style from './Qaprocess.module.css';
import { useLocation } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import MenualTesting from './manualTesting/MenualTesting';
import ToolsAutomation from './toolsAutomation/ToolsAutomation';
import { Link } from 'react-router-dom';
const Qaprocess = () => {
  const [newdata, setNewdata] = useState();
  const location = useLocation();
  useEffect(() => {
    console.log('location', location.state);
    if (location?.state) {
      localStorage.setItem('mydata', JSON.stringify(location?.state?.carddata));
      setNewdata(location?.state?.carddata);
    }
  }, [location]);
  useEffect(() => {
    if (localStorage.getItem('mydata')) {
      setNewdata(JSON.parse(localStorage.getItem('mydata')));
    }
  }, [localStorage.getItem('mydata')]);

  return (
    <div className="className='bg-white xl:py-28 py-24'" data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='QA process'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='lg:max-w-7xl xl:px-20 md:px-6 xsm:px-4 mx-auto'>
        <div className='py-20 flex flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#000] font-bold leading-[50.78px] font-Gelasio'>
              {newdata?.acf?.title?.slice(0, 13)}

              <span className='text-[#43BDC1]'>
                {newdata?.acf?.title?.slice(13, newdata?.acf?.title.length)}
              </span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto mt-5 font-normal leading-[30px] py-2 font-[Inter]'>
              {newdata?.acf?.description}
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get In Touch
                </span>
              </Link>
            </div>
          </div>
          <div
            className='p-0'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(newdata?.acf?.header_image),
            }}
          ></div>
        </div>
        <div
          className={style.list}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(newdata?.acf?.processtype),
          }}
        ></div>
        <div
          className='grid  place-items-center'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(newdata?.acf?.processtype2),
          }}
        ></div>
      </div>

      <MenualTesting />
      <ToolsAutomation />
      <TechnologyTools
        title='API Testing Technology'
        images={newdata?.acf?.testing_tool4_images}
      />
      <TechnologyTools
        title='Programming Language'
        images={newdata?.acf?.testing_tool3_images}
      />
    </div>
  );
};
export default Qaprocess;
