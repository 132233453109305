import React from 'react';
import CalendarComponent from '../atoms/getIntouch/CalendarComponent';
import FormComponent from '../atoms/getIntouch/FormComponent';

const CallSchedule = ({ showCalendly, handleBookCall, handleCancel }) => {
  return (
    <div className={`flip-card ${showCalendly ? 'flipped' : ''}`}>
      <div className='flip-card-inner'>
        <div className='flip-card-front'>
          <FormComponent handleBookCall={handleBookCall} />
        </div>
        <div className='flip-card-back'>
          <CalendarComponent handleCancel={handleCancel} />
        </div>
      </div>
    </div>
  );
};

export default CallSchedule;
