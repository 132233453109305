import axios from 'axios';
const baseurl = 'https://wpapi.devtrust.biz/index.php/wp-json/';
const instance = axios.create({
  baseURL: baseurl,
});
//p
instance.interceptors.request.use(
  function (config) {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
