// components/ChallengeWrapper.js
import React, { useState, useEffect } from 'react';
import ChallengeCard from './ChallengeCard'; // Adjust the path as needed

const ChallengeWrapper = ({
  icon,
  bg,
  rectangle,
  cardDes1,
  cardDes2,
  challengeCards,
  gradient,
}) => {
  const numCols = challengeCards.length === 4 ? 4 : 3;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine if the screen width is less than or equal to 640px
  const isMobile = windowWidth <= 640;
  return (
    <div>
      <section className='challenge-wrapper-sec'>
        <img className='work-step-bg-icon' alt='' src={bg} />
        <img
          className='case-studies-inner'
          loading='lazy'
          alt=''
          src={rectangle}
        />
        <div className='challenge-wrapper'>
          <div
            className='challenge-content sky-challenge-content'
            style={{ background: gradient }}
          >
            <div className='challenge-content-child'></div>
            <div className='challenge-header'>
              <div className='challenge-title'>
                <div className='problem-description'>
                  <h3 className='problem'>
                    <b>Challenges </b>
                  </h3>
                  <p className='description'>at a Glance</p>
                </div>
              </div>
              <img className='logo-new-icon' alt='' src={icon} />
            </div>
            <div className='skyward-faced-several-container'>
              <p className='skyward-faced-several'>{cardDes1}</p>
              <p className='real-estate-management'>{cardDes2}</p>
            </div>
          </div>
          <div className='challenge-breakdown'>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${isMobile ? 1 : numCols}, 1fr)`,
                gap: '1rem',
              }}
            >
              {challengeCards.map((card) => (
                <ChallengeCard
                  key={card.number}
                  number={card.number}
                  title={card.title}
                  description={card.description}
                  boxClass={card.boxClass}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChallengeWrapper;
