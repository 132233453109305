import React, { useMemo } from 'react';
import Need from '../../assets/images/Need.svg';
import Button from '../../atoms/button/Button';
import FormCard from '../../atoms/formcard/FormCard';
import Progressbar from '../../atoms/progress/Progressbar';
const ThirdStep = ({ nextStep, fvalue, prevStep, steps }) => {
  let data = [
    { img: Need, title: '< 1 Month' },
    { img: Need, title: '3 Months' },
    { img: Need, title: '3+ Months' },
  ];
  const selectItem = (value) => {
    fvalue.timeframe = value;
    nextStep();
  };
  const formcard = useMemo(() => {
    return data.map((item, index) => (
      <FormCard data={item} key={index} select={selectItem} />
    ));
  }, [data]);
  return (
    <div className=' bg-white py-24'>
      <h1 className='font-Gelasio  xl:text-[50px]  text-[40px] font-bold capitalize block mx-auto text-center pt-5 xl:leading-[63px] leading-[51px]'>
        How long will{' '}
        <span className='text-custom-green leading-[63.48px] font-Gelasio'>
          you need us?
        </span>
      </h1>
      <div className='flex justify-center py-7 pb-10 '>
        <Progressbar steps={steps} prevStep={prevStep} nextStep={nextStep} />
      </div>
      <div className='container mx-auto'>
        <div className='box-content flex md:flex-nowrap flex-wrap w-auto  gap-8 justify-center p-7'>
          {formcard}
        </div>
        <Button prevStep={prevStep} />
      </div>
    </div>
  );
};
export default ThirdStep;
