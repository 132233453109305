import React, { useEffect, useMemo } from 'react';
import BlogCard from '../../atoms/blogcard/BlogCard';
import { useDispatch, useSelector } from 'react-redux';
import { getblog } from '../../redux/Action/actions';
import { useNavigate } from 'react-router-dom';
const LatestBlog = () => {
  const dispatch = useDispatch();
  const select = useSelector((state) => state.blogreducer?.blogdata) ?? [];
  useEffect(() => {
    dispatch(getblog());
  }, []);

  const blogCard = useMemo(() => {
    return select
      .filter((item, index) => {
        if (index <= 1) return item;
      })
      .map((item) => <BlogCard blogdata={item} key={item.id} />);
  }, [select]);
  const navigate = useNavigate();
  const navigateToBlog = () => {
    navigate('/blog');
  };
  return (
    <div className='max-w-7xl xl:p-20 md:p-6 xsm:px-4 mx-auto xl:py-16 xsm:py-12 pt-10'>
      <div className='flex  justify-between items-center xl:pb-16 pb-10'>
        <h1 className='xl:text-[40px] text-[32px]	font-bold leading-10 xsm:leading-[50.78px]	font-Gelasio'>
          Latest{' '}
          <span className='text-custom-green font-[Playfair]'>Blogs</span>
        </h1>
        <button
          className=' py-2.5 px-6 xl:block xsm:hidden leading-[21.78px] font-[Inter] text-[#43BDC1] text-[18px] font-normal'
          onClick={navigateToBlog}
        >
          View All Blogs
        </button>
      </div>
      <div className='flex xl:flex-row md:flex-row flex-col  gap-x-8 	 gap-y-8 xl:flex-nowrap md:flex-nowrap flex-wrap	items-center'>
        {blogCard}
      </div>
      <p
        className='text-[24px] text-center xl:hidden xsm:block py-7 font-[Inter]'
        onClick={navigateToBlog}
      >
        View All Blogs
      </p>
    </div>
  );
};

export default LatestBlog;
