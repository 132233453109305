import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/AI-Assistant/frame1.svg';
import logo from '../../../assets/AI-Assistant/group-1000001294.svg';
import benefitLogo from '../../../assets/AI-Assistant/group-1000001294.svg';
import rectangle from '../../../assets/AI-Assistant/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/AI-Assistant/icon1.svg';
import icon2 from '../../../assets/AI-Assistant/icon2.svg';
import icon3 from '../../../assets/AI-Assistant/icon3.svg';
import icon4 from '../../../assets/AI-Assistant/icon4.svg';
import icon5 from '../../../assets/AI-Assistant/icon5.svg';
import interfaceImg from '../../../assets/AI-Assistant/interface.svg';

import benefitIcon from '../../../assets/AI-Assistant/bxuparrow@2x.png';
import benefitImage from '../../../assets/AI-Assistant/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Documentation Time',
    description:
      'Physicians often spend plenty of time in documenting patient information, treatment plans, and other medical details resulting in significant overheads.',
    boxClass: '#51428C',
  },
  {
    number: 2,
    title: 'Compliance',
    description:
      'Medical documentation must adhere to strict legal and regulatory standards.',
    boxClass: '#6858A9',
  },
  {
    number: 3,
    title: 'Distraction',
    description:
      'Significant distraction from addressing the core area (consultation) due to manual note taking process.',
    boxClass: '#8372C5',
  },
];

const solutionData = [
  {
    title: 'Automated Documentation',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'AI Assistant can create documentation including treatment plans with all the',
      'medical details automatically which can save physician`s significant time.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Efficient Integration with Stripe',
    imageSrc: icon2,
    imageAlt: 'Transcription',
    descriptions: [
      'Azure speech-to-text convertor to transcribe the conversation.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Accuracy',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'AI-generated documents ensure more accuracy and completeness based on',
      'Standardized templates that adhere to the legal and regulatory standards.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'HIPAA Compliance',
    imageSrc: icon4,
    imageAlt: 'Google Calendar Image',
    descriptions: ['The documents are HIPAA compliant.'],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Focus on Core Responsibilities',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'As the administration work and documentation are done by the AI Assistant,',
      'physicians can focus more on their primary clinical responsibilities.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Time Savings',
    description:
      'OpenAI (ChatGPT 4.0) completes the documentation and administration work in ',
    percentage: '60% ',
    rest: 'lesser time.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Reduced Overhead',
    description:
      'Azure speech-to-text convertor driven transcription reduced the overhead by',
    percentage: '80%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Data Confidentiality',
    description: 'HIPAA compliant generated SOAP note ensured',
    percentage: '100% ',
    rest: 'data confidentiality.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
];

const AI_Assistant = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='AI-'
          subtitle='Assistant'
          textcolor='#2C5197'
          description=''
          image={frame1}
          How=''
          DEVtrust={''}
          highlightedText={
            '“AI Assistant,” an OpenAI-powered tool designed to automate and enhance physician documentation through HIPAA-compliant transcription and note generation.'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='The client contacted DEVtrust to build “AI Assistant”, a tool for automating the documentation of physicians with the help of OpenAI.<br/> DEVtrust intended to work on transcribing the conversation between the patient and the physician bycreating a HIPAA compliant SOAP note (treatment plan).'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #144D83, #51428C)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#2C3968'}
        textColor={'#9C8CFF'}
        gradient='linear-gradient(135deg, #144D83, #51428C 60.5%)'
      />
      <InterfaceSection
        heading='AI-Assistant’s user-friendly interface'
        Description='Documentation Process and Results'
        interfaceImg={interfaceImg}
      />
    </div>
  );
};

export default AI_Assistant;
