import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import { month } from '../../redux/constant/month';
import * as DOMPurify from 'dompurify';
const BlogCard = (props) => {
  const { blogdata } = props;
  const [description, setDescription] = useState('');
  const [btntxt, setBtnTxt] = useState('Read More');
  const navigate = useNavigate();
  const settextLimit = () => {
    let text = '';
    if (btntxt === 'Read More') {
      text = blogdata.excerpt.rendered.substring(0, 160);
      setDescription(text);
      setBtnTxt('Read Less');
      return;
    }
    text = blogdata.excerpt.rendered.substring(0, 120);
    setDescription(text);
    setBtnTxt('Read More');
  };
  useEffect(() => {
    let text = blogdata.excerpt.rendered.substring(0, 120);
    setDescription(text);
  }, []);
  const handleDescription = (data) => {
    navigate('/blogdetails', { state: { details: data } });
    settextLimit();
  };

  return (
    <div className='bg-white shadow xsm:h-full xl:w-[555px] w-full '>
      <img
        className='pt-6 px-6 h-auto max-h-[284px] sm:object-cover xl:object-cover object-top w-[100%] scale-100 hover:scale-90 ease-in duration-500 '
        alt='blogdata'
        src={blogdata.feature_img_url ? blogdata.feature_img_url : ''}
      ></img>
      <div className='p-6'>
        <div className='flex justify-between  items-center'>
          <h3 className='text-lg basis-1/2	font-normal'>
            {new Date(blogdata.date).getDate() +
              ' ' +
              month[new Date(blogdata.date).getMonth()] +
              ' ' +
              new Date(blogdata.date).getFullYear()}
          </h3>
          <h1
            className='text-lg basis-1/2 text-end font-semibold leading-7 font-[Inter] truncatesone'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(blogdata?.title.rendered.trim()),
            }}
          ></h1>
        </div>
        <p
          className='text-[16px]	my-4 truncates leading-[24px] font-[Inter]'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        ></p>
        <button
          data-testid='btn'
          className='text-xl	font-semibold	text-custom-green'
          onClick={() => {
            handleDescription(blogdata);
          }}
        >
          {btntxt}
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
