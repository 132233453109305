import React from 'react';
import { useSelector } from 'react-redux';
import DEVTRUSTLOADER from '../../assets/images/devtrustloader.gif';

function Loader(props) {
  const loader = useSelector((reducers) => reducers.loaderreducer);
  return props.status || loader ? (
    <div
      className='top-0 left-0 w-full flex justify-center fixed h-full items-center bg-[rgba(0,0,0,0.4)]'
      style={{ zIndex: '999' }}
    >
      <div>
        <img src={DEVTRUSTLOADER} alt='devtrust-loader' />
      </div>
    </div>
  ) : null;
}

export default Loader;
