import React from 'react';
import prev from '../../assets/images/prev.svg';

const Button = ({ prevStep }) => {
  return (
    <>
      <button
        onClick={() => {
          prevStep();
        }}
        data-testid='btn-increment'
        className='font-normal text-base text-[#25C7C7] font-Inter py-2  rounded inline-flex items-center'
      >
        <img className='w-[6px] h-[11px]' src={prev} alt='back' />
        <span className='pl-2'>Back</span>
      </button>
    </>
  );
};

export default Button;
