import React from 'react';

const InterfaceSection = ({ heading, Description, interfaceImg }) => {
  return (
    <div className='interface_top_spacing'>
      <section className='interface-content-wrapper'>
        <div className='interface-content'>
          <div className='interface-description'>
            <div className='interface-paragraph'>
              <h2 className='skywards-user-friendly-interf'>{heading}</h2>
              <div className='interface-details'>
                <div className='simplifies-property-exploratio'>
                  {Description}
                </div>
              </div>
            </div>
          </div>
          <div className='wrapper-group-1000001283'>
            <img
              className='wrapper-group-1000001283-child'
              loading='lazy'
              alt=''
              src={interfaceImg}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default InterfaceSection;
