import React, { useEffect, useState } from 'react';
import { aboutUsData } from '../../utils/index';
import { setLoader } from '../../redux/Action/actions';
import style from './About.module.css';
import { useDispatch } from 'react-redux';
import * as DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getAboutData();
  }, []);

  const getAboutData = async () => {
    dispatch(setLoader(true));
    let aboutData = await aboutUsData();
    dispatch(setLoader(false));
    setData(aboutData);
  };
  return (
    <div
      className='bg-grey xl:pt-32 pt-24 pb-8 overflow-hidden'
      data-testid='text'
    >
      <Helmet
        meta={[
          {
            name: 'title',
            content: 'About DEVtrust - Dev Tech Enterprises Pvt. Ltd.',
          },
          {
            name: 'description',
            content:
              'DEVtrust partner with clients & make them grow by building great apps, products & services. Be it a start-up or a big established organisation, we work with the same focus on communication & craftsmanship',
          },
          {
            name: 'keywords',
            content:
              'Custom Website Development, Android App Development, Apps Development, Custom Website Design, Software Development, Web Development',
          },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: 'English' },
        ]}
        title='AboutUs'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto lg:pb-8'>
        <div className={`${style.main}`}>
          <div
            className={`${style.head}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data[2]?.content?.rendered),
            }}
          ></div>
          <div
            className={style.aboutimg}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data[2]?.acf?.content),
            }}
          ></div>
        </div>
        <div
          className={`${style.secondiv}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[1]?.content?.rendered),
          }}
        ></div>
      </div>
      <div className={`${style.thirddiv}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[0]?.content?.rendered),
          }}
        ></div>
        <div
          className={style.cardheader}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[0]?.acf?.content),
          }}
        ></div>
      </div>
    </div>
  );
};

export default AboutUs;
