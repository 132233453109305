import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Loader from '../../shared/loader/loader';

import HeaderCopy from '../../shared/Header/HeaderCopy';
import CardHeader from '../../shared/cardHeader/CardHeader';
import Summary from '../../shared/summry/Summary';
import ServicesPortfolio from '../../shared/servicesPortfolio/ServicesPortfolio';
import Clients from '../../shared/clients/Clients';
// import LatestBlog from '../../shared/latestBlog/LatestBlog';
// import EngagementModal from '../../shared/engagementModal/EngagementModal';
import Technologies from '../../shared/technology/Technologies';
import { GetInTouchCopy } from '../../shared/contact/GetInTouch';
import Customer from '../../shared/customer/Customer';
import '../home/homeCopy.css';

function HomeCopy() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToGetInTouch) {
      const getInTouchElement = document.getElementById('get-in-touch');
      if (getInTouchElement) {
        getInTouchElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Suspense fallback={<Loader status={true} />}>
      <div className='overflow-hidden select-none'>
        <Helmet
          data-testid='homes'
          meta={[
            { content: '', name: 'description' },
            { content: '', name: 'description' },
          ]}
          title='Devtrust'
          link={[{ rel: 'preconnect', href: '#' }]}
        />
        <HeaderCopy />
        <CardHeader />
        <ServicesPortfolio />
        <Technologies />
        <Summary />
        {/* <EngagementModal /> */}
        <Clients />
        <Customer />
        {/* <LatestBlog /> */}
        <div id='get-in-touch'>
          <GetInTouchCopy />
        </div>
      </div>
    </Suspense>
  );
}

export default HomeCopy;
