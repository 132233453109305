import React from 'react';

const FormCard = ({ data, select, second }) => {
  return (
    <div className='formCards'>
      <div
        className={` bg-white shadow h-[409px]  ${
          second ? 'xl:w-[555px]' : 'w-[359px]'
        }`}
        onClick={() => {
          select(data?.title);
        }}
        key={data.id}
      >
        <div className='h-[280px]  bg-[#FCFCFC] hover:bg-[#F0FAFA] flex  items-center justify-center'>
          <img alt='form_img' src={data?.img}></img>
        </div>
        <div className='flex justify-center'>
          <h1 className='bg-white max-w-[278px]  text-center h-[129px] px-10 flex text-[24px] font-normal leading-[34px] text-[#000] font-Inter l gap-10 items-center justify-center'>
            {data?.title}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default FormCard;
