import React, { useMemo } from 'react';
import ModalCard from '../../atoms/modalcard/ModalCard';
const EngagementModal = () => {
  const modalCard = useMemo(() => {
    return modal.map((item) => <ModalCard carddata={item} key={item.id} />);
  }, []);
  return (
    <div className='bg-white pt-20 pb-24 hidden xl:block lg:block'>
      <div className='max-w-7xl xl:px-20 md:px-6 mx-auto'>
        <h1 className='text-center text-[40px] break-words  font-bold p-6'>
          <span className='leading-[63.48px] font-Gelasio'>Engagement</span>
          &nbsp;
          <span className='text-custom-green leading-[66.65px]'>Models</span>
        </h1>
        <div className='flex py-2 xl:flex-row md:flex-row flex-col  gap-x-8  gap-y-8 xl:justify-between justify-center  flex-nowrap	items-center'>
          {modalCard}
        </div>
      </div>
    </div>
  );
};
const modal = [
  {
    id: '1',
    heading: 'Fixed Price',
    list: [
      'Small & Medium',
      'Clearly defined requirements',
      'Project cost is fixed & paid as per the delivery milestones',
    ],
    summary:
      'Ideal for small sized projects with clearly defined scope, objectives & deliverables with nothing left as vague or subject to interpretations.',
  },
  {
    id: '2',
    heading: 'Time & Material',
    list: [
      'Mid to large sized projects',
      'Evolving requirements',
      'Billing & invoicing done on hours spent at a pre-discussed hourly rate',
    ],
    summary:
      'Ideal for mid to large sized projects with evolving requirements & clients who want to have significant control over their projects.',
  },
  {
    id: '3',
    heading: 'Dedicated Team',
    list: [
      'Large sized projects',
      '100% dedicated team',
      'Significant cost savings on hiring, infrastructure & social benifits with ability to scale the team quickly',
    ],
    summary:
      'Ideal for long, ongoing engagements with complete control of clients on their teams.',
  },
];
export default EngagementModal;
