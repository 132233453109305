import React from 'react';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import SolutionFeatures from './components/SolutionFeatures';
import FrameComponent3 from './components/FrameComponent3';
import InterfaceHighlight from './components/InterfaceHighlight';
import FrameComponent4 from './components/FrameComponent4';

const CaseStudiesPlanwell = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[86px] box-border gap-[135px] leading-[normal] tracking-[normal] mq750:gap-[67px] mq450:gap-[34px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Planwell/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Planwell/background.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-2.5 box-border max-w-full'>
        <FrameComponent1 />
      </section>
      <img
        className='w-full h-[862px] absolute !m-[0] top-[1457px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Planwell/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[1]'
        alt=''
        src='/caseStudies/Planwell/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-3.5 pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            Planwell is an advanced web-based tool designed in collaboration
            with Devtrust to revolutionize personal financial planning by
            transforming traditional Excel-based spreadsheets into an intuitive
            and accessible format. It offers three distinct
            calculators—Retirement, Home, and College Savings—each tailored to
            provide personalized recommendations swiftly and efficiently,
            catering to individuals at various stages of life and financial
            planning.
          </div>
        </div>
      </section>
      <FrameComponent2 />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[3013px] right-[-187.4px]'
        alt=''
        src='/caseStudies/Planwell/group-3051956.svg'
      />
      <section className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Solutions
            </h1>
          </div>
          <div className='self-stretch relative text-xl mq450:text-base'>
            Devtrust implemented a comprehensive solution to address these
            challenges
          </div>
        </div>
      </section>
      <SolutionFeatures />
      <FrameComponent3 />
      <InterfaceHighlight />
      <FrameComponent4 />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesPlanwell;
