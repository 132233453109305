import React from 'react';
import PropTypes from 'prop-types';

const ContentDelivery = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[92px] pl-5 pr-[26px] box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[39px] mq450:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[1134px] flex flex-col items-end justify-start gap-[13px] max-w-full'>
        <div className='w-[1076px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <img
            className='h-[73px] w-[60px] relative z-[2]'
            loading='lazy'
            alt=''
          />
        </div>
        <div className='self-stretch flex flex-row flex-wrap items-end justify-center gap-[46px] max-w-full mq750:gap-[23px]'>
          <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[537px] max-w-full mq450:gap-[22px] mq750:min-w-full mq1050:gap-[45px]'>
            <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#c9da2a,_#008541)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[30px] box-border gap-[11.5px] max-w-full z-[2] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border'>
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#c9da2a,_#008541)] hidden max-w-full' />
              <div className='self-stretch flex flex-row items-start justify-end'>
                <img
                  className='h-[48.5px] w-[50px] relative object-cover z-[1]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Koach/logoimage12x-1-1-1@2x.png'
                />
              </div>
              <div className='w-[249px] flex flex-row items-start justify-start pt-0 px-0 pb-[4.5px] box-border'>
                <h1 className='m-0 flex-1 relative text-inherit z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                  <h3 className='m-0'>
                    <b>{`Problem `}</b>
                  </h3>
                  <p className='m-0'>Statement</p>
                </h1>
              </div>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-col items-start justify-start gap-10 max-w-full text-center text-13xl mq450:gap-5'>
              <div className='w-[643px] flex flex-row items-start justify-between gap-5 max-w-full mq450:flex-wrap'>
                <div className='rounded-3xs bg-forestgreen-300 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-forestgreen-300 hidden' />
                  <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    1
                  </b>
                </div>
                <div className='rounded-3xs bg-forestgreen-200 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-forestgreen-200 hidden' />
                  <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    1
                  </b>
                </div>
                <div className='rounded-3xs bg-forestgreen-100 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-forestgreen-100 hidden' />
                  <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    2
                  </b>
                </div>
              </div>
              <div className='self-stretch flex flex-row items-start justify-start gap-[65px] text-left text-3xl text-gray-200 mq450:gap-4 mq1050:flex-wrap mq1050:gap-8'>
                <div className='flex flex-col items-start justify-start gap-[13px]'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    User Engagement
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <p className='m-0'>{`Ensuring that users could `}</p>
                    <p className='m-0'>{`easily discover and book `}</p>
                    <p className='m-0'>{`coaching sessions with `}</p>
                    <p className='m-0'>skilled professionals.</p>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start py-0 pl-0 pr-1.5 gap-[13px]'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    Payment Integration
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <p className='m-0'>{`Facilitating secure and `}</p>
                    <p className='m-0'>{`seamless payment `}</p>
                    <p className='m-0'>transactions for booking</p>
                    <p className='m-0'>sessions.</p>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start gap-[13px]'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    Content Delivery
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <p className='m-0'>{`Providing valuable content `}</p>
                    <p className='m-0'>{`through podcasts and other `}</p>
                    <p className='m-0'>{`media to inspire and educate `}</p>
                    <p className='m-0'>subscribers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[262px] flex flex-col items-start justify-start gap-10 text-center text-13xl mq450:gap-5'>
            <div className='rounded-3xs bg-yellowgreen flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
              <div className='h-[69px] w-[69px] relative rounded-3xs bg-yellowgreen hidden' />
              <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                3
              </b>
            </div>
            <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
              <div className='relative leading-[30px] font-semibold inline-block min-w-[111px] z-[1] mq450:text-lg mq450:leading-[24px]'>
                Scalability
              </div>
              <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                Developing a platform that could scale and handle increasing
                user traffic and interactions smoothly.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentDelivery.propTypes = {
  className: PropTypes.string,
};

export default ContentDelivery;
