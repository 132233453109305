import React, { useEffect, useState } from 'react';
import { getblog, setBlogTypes } from '../../redux/Action/actions';
import style from './BlogAction.module.css';
import { useDispatch, useSelector } from 'react-redux';
const BlogAction = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const select = useSelector((state) => state.blogreducer?.blogdata) ?? [];
  const blogtypes = useSelector((state) => state?.blogfilterreducer);

  useEffect(() => {
    if (select.length <= 0) {
      dispatch(getblog());
      return;
    }

    select?.map((item) => {
      if (!list.includes(item?._embedded?.['wp:term'][0][0].name)) {
        setList([...list, item?._embedded?.['wp:term'][0][0].name]);
      }
    });
  }, [select, list]);

  const selectCategory = (e) => {
    let { value } = e.target;
    dispatch(setBlogTypes({ category: value }));
  };

  return (
    <>
      <div className={style.actionmain}>
        <div className={style.selectdiv}>
          <select
            data-testid='select'
            value={blogtypes.category || 'Categories'}
            onChange={selectCategory}
            className={style.select}
          >
            <option disabled value='Categories'>
              Categories
            </option>
            {list.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
            <option value='All'>All</option>
          </select>
          <div className={style.svg}>
            <svg
              className={style.svgicon}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
            >
              <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogAction;
