import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './ContactUsComponent.css';
import SuccessAlert from '../../shared/alert/SuccessAlert';
import { setLoader } from '../../redux/Action/actions';
import { useDispatch } from 'react-redux';
import { submitForm } from '../../utils';
import { Helmet } from 'react-helmet';

const ContactUsComponent = () => {
  const fileInputRef = useRef();
  const [filePreviews, setFilePreviews] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      contact: '',
      comment: '',
      files: [],
      ndaRequest: false,
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required('Full name is required')
        .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
        .min(2, 'Must be at least 2 characters')
        .max(30, 'Must be 30 characters or less')
        .trim(),
      email: Yup.string()
        .required('Email address is required')
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email address'
        )
        .trim(),
      contact: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]*$/, 'Only numbers are allowed')
        .min(10, 'Must be at least 10 characters')
        .max(12, 'Must be at most 12 characters')
        .trim(),
      comment: Yup.string()
        .required('Comment is required')
        .matches(/^[\w\s]+$/, 'Only words are allowed')
        .min(1, 'Must be at least 1 word')
        .max(200, 'Must be at most 200 characters')
        .trim(),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', values.fullname);
      formdata.append('email', values.email);
      formdata.append('contact', values.contact);
      formdata.append('comment', values.comment);
      for (let i = 0; i < values.files.length; i++) {
        formdata.append('upload[]', values.files[i]);
      }

      let res = await submitForm(formdata);
      if (res.status === 200) {
        dispatch(setLoader(false));
        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
        }, 7000);
        setFilePreviews([]);
        resetForm();
      } else {
        alert('Something went wrong.');
      }
    },
  });

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const previews = [];

    if (files.length > 10) {
      alert('You can upload a maximum of 10 files.');
      return;
    }

    files.forEach((file) => {
      if (
        file.size <= 5 * 1024 * 1024 &&
        [
          'image/jpeg',
          'image/png',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ].includes(file.type)
      ) {
        validFiles.push(file);

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          previews.push(URL.createObjectURL(file));
        } else if (file.type === 'application/pdf') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/PDF.png'
          );
        } else if (file.type === 'application/msword') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Doc.png'
          );
        } else if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Docx.png'
          );
        } else if (file.type === 'application/vnd.ms-excel') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/XLS.png'
          );
        } else if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/EXL.png'
          );
        } else if (file.type === 'text/csv') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/CSV.png'
          );
        }
      } else {
        alert(
          `File ${file.name} is not allowed. Ensure it's a JPG, PNG, DOC, DOCX, PDF, XLS, XLSX, or CSV under 5MB.`
        );
      }
    });

    formik.setFieldValue('files', validFiles);
    setFilePreviews(previews);
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const removeFile = (index) => {
    const newFiles = [...formik.values.files];
    newFiles.splice(index, 1);

    const previews = newFiles.map((file) => {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return URL.createObjectURL(file);
      } else if (file.type === 'application/pdf') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/PDF.png';
      } else if (file.type === 'application/msword') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Doc.png';
      } else if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Docx.png';
      } else if (file.type === 'application/vnd.ms-excel') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/XLS.png';
      } else if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/EXL.png';
      } else if (file.type === 'text/csv') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/CSV.png';
      }
      return null;
    });

    formik.setFieldValue('files', newFiles);
    setFilePreviews(previews);
  };

  return (
    <>
      <div className='contact_sec'>
        <Helmet
          meta={[
            {
              name: 'Description',
              content:
                'If you have any questions. just fill in the contact form, and we will answer you shortly.',
            },
            {
              name: 'Keywords',
              content:
                'App Development, Web Development, Software Development, Dynamic Website Development, Responsive Web Development',
            },
            { name: 'robots', content: 'index, follow' },
            { name: 'language', content: 'English' },
          ]}
          title='Contact Us'
          link={[{ rel: 'preconnect', href: '#' }]}
        ></Helmet>
        <div className=''>
          <div className='banner_form_block'>
            <div className='contact_content'>
              <h3>Contact Info</h3>
              <div className='sub_content'>
                <h4>Address: (US)</h4>
                <p>221 E Indianola Ave, Phoenix, Arizona 85012</p>
                <p>
                  Phone # (USA):
                  <br /> +1-347-719-4819
                </p>
              </div>
              <br />
              <div className='sub_content'>
                <h4>Address: (India)</h4>
                <label>Lucknow</label>
                <p>
                  CP-6, 3rd Floor, Vikrant Khand, Gomti Nagar Lucknow, Uttar
                  Pradesh, 226010
                </p>
                <br />
                <label>Noida</label>
                <p>
                  Green Boulevard 5th Floor, Tower C, Block B, Sector 62, Noida,
                  Uttar Pradesh 201309
                </p>
                <p>
                  Phone # (India):
                  <br /> +91-8447154118
                </p>
              </div>
            </div>
            <div className='banner_form_sec contact_form_sec'>
              <h3>
                <span>Let&apos;s Get In</span> Touch
              </h3>
              {!isModalOpen ? (
                <>
                  <form className='my-4' onSubmit={formik.handleSubmit}>
                    <div className='form-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='fullname'
                        placeholder='Your name*'
                        {...formik.getFieldProps('fullname')}
                      />
                      {formik.touched.fullname && formik.errors.fullname ? (
                        <div className='error'>{formik.errors.fullname}</div>
                      ) : null}
                    </div>
                    <div className='form-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        placeholder='Your email address*'
                        {...formik.getFieldProps('email')}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className='error'>{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className='form-group mb-3'>
                      <input
                        type='tel'
                        className='form-control'
                        id='contact'
                        placeholder='Mobile number*'
                        {...formik.getFieldProps('contact')}
                      />
                      {formik.touched.contact && formik.errors.contact ? (
                        <div className='text-left text-red-600'>
                          {formik.errors.contact}
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group mb-3'>
                      <textarea
                        className='form-control'
                        id='comment'
                        rows='3'
                        placeholder='How can we help you?*'
                        {...formik.getFieldProps('comment')}
                      ></textarea>
                      {formik.touched.comment && formik.errors.comment ? (
                        <div className='error'>{formik.errors.comment}</div>
                      ) : null}
                    </div>

                    <div className='form-group upload_sec mb-3'>
                      <label>Upload Your Documents</label>
                      <button
                        className='upload_btn'
                        id='uploadBtn'
                        onClick={handleUploadClick}
                      >
                        <span>
                          <img src='img/upload-icon.png' alt='upload-icon' />
                        </span>
                        Upload File
                      </button>
                      <input
                        type='file'
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        accept='image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv'
                        multiple
                      />
                    </div>
                    {filePreviews.length > 0 && (
                      <div className='my-3'>
                        <h4 className='text-lg text-left'>Preview Files:</h4>
                        <div className='flex flex-wrap gap-2'>
                          {filePreviews.map((filePreview, index) => (
                            <div
                              key={index}
                              className='preview_get_form  relative'
                            >
                              <img
                                src={filePreview}
                                alt={`File Preview ${index + 1}`}
                                className='h-20 w-20 object-cover border rounded'
                              />
                              <button
                                type='button'
                                className='remove_btn absolute top-0 right-0 p-1 text-red-600 bg-white rounded-full'
                                onClick={() => removeFile(index)}
                              >
                                &times;
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className='card_buttons d-flex align-items-center'>
                      <button className='btn btn_send' type='submit'>
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <SuccessAlert
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                  title='Thank You!'
                  message='We have noted your request and will be in contact shortly.'
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <section className='map_sec'>
        <div className='map_content'>
          <img src='img/map-pic.png' alt='map' />
        </div>
      </section>
    </>
  );
};

export default ContactUsComponent;
