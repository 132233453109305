import React from 'react';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import SolutionDetails from './components/SolutionDetails';
import FrameComponent3 from './components/FrameComponent3';
import FrameComponent4 from './components/FrameComponent4';
import DevtrustAssistance from './components/DevtrustAssistance';
import styles from './CaseStudiesImagineScholars.module.css';

const CaseStudiesImagineScholars = () => {
  return (
    <div className={styles.caseStudiesImagineScholars}>
      <div className={styles.caseStudiesImagineScholarsChild} />
      <img
        className={styles.backgroundSimpleIcon}
        alt=''
        src='/caseStudies/Imagine-Scholarship/background-simple.svg'
      />
      <img
        className={styles.backgroundIcon}
        alt=''
        src='/caseStudies/Imagine-Scholarship/background.svg'
      />
      <img
        className={styles.workStepBgIcon}
        alt=''
        src='/caseStudies/Imagine-Scholarship/workstepbg@2x.png'
      />
      <img
        className={styles.shapeIcon}
        loading='lazy'
        alt=''
        src='/caseStudies/Imagine-Scholarship/rectangle-3416@2x.png'
      />
      <section className={styles.frameParent}>
        <FrameComponent1 />
      </section>
      <section className={styles.introductionContentWrapper}>
        <div className={styles.introductionContent}>
          <div className={styles.introductionParagraph}>
            <h1 className={styles.introduction}>Introduction</h1>
          </div>
          <div className={styles.devtrustDevelopedImagineContainer}>
            <p className={styles.devtrustDevelopedImagine}>
              Devtrust developed Imagine Scholarship, an online platform
              designed to maximize users&apos; access
            </p>
            <p className={styles.devtrustDevelopedImagine}>
              to education funding, aiding them in achieving their academic
              goals. The platform continuously
            </p>
            <p className={styles.devtrustDevelopedImagine}>
              researches and updates scholarship opportunities, ensuring
              comprehensive access to
            </p>
            <p className={styles.devtrustDevelopedImagine}>
              education funding options.
            </p>
          </div>
        </div>
      </section>
      <FrameComponent2 />
      <img
        className={styles.caseStudiesImagineScholarsItem}
        alt=''
        src='/caseStudies/Imagine-Scholarship/group-3051956.svg'
      />
      <SolutionDetails />
      <FrameComponent3 />
      <FrameComponent4 />
      <DevtrustAssistance />
      <div className={styles.lineParent}>
        <div className={styles.groupChild} />
        <div className={styles.groupItem} />
        <div className={styles.groupInner} />
      </div>
    </div>
  );
};

export default CaseStudiesImagineScholars;
