// components/SolutionWrapper.js
import React from 'react';

const SolutionWrapper = ({ title, description }) => {
  return (
    <section className='solution-wrapper-wrapper'>
      <div className='solution-wrapper'>
        <div className='solution-content'>
          <h2 className='solutions'>{title}</h2>
        </div>
        <div className='solution-text'>{description}</div>
      </div>
    </section>
  );
};

export default SolutionWrapper;
