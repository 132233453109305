import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const SolutionFeatureContent = ({
  className = '',
  propWidth,
  mobileCompatibility,
  mobileCompatibility1,
  designedAsAWebBasedToolPlanw,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div
      className={`w-[795px] flex flex-col items-start justify-start gap-[7px] max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div
        className='w-[436px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'
        style={frameDivStyle}
      >
        <div className='relative font-semibold mq450:text-lg'>
          {mobileCompatibility}
        </div>
      </div>
      <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
        <img
          className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
          loading='lazy'
          alt=''
          src={mobileCompatibility1}
        />
        <div className='flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[447px] max-w-full mq750:min-w-full'>
          <div className='self-stretch relative leading-[30px] z-[1]'>
            {designedAsAWebBasedToolPlanw}
          </div>
        </div>
      </div>
    </div>
  );
};

SolutionFeatureContent.propTypes = {
  className: PropTypes.string,
  mobileCompatibility: PropTypes.string,
  mobileCompatibility1: PropTypes.string,
  designedAsAWebBasedToolPlanw: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
};

export default SolutionFeatureContent;
