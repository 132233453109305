import React from 'react';
import SolutionHeaders from './SolutionHeaders';
import PropTypes from 'prop-types';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[7px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[54px] max-w-full mq450:gap-[27px]'>
        <div className='self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[81px] box-border max-w-full text-center text-17xl'>
          <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
              <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl'>
                Solutions
              </h1>
            </div>
            <div className='self-stretch relative text-xl mq450:text-base'>
              Devtrust implemented a comprehensive solution to address these
              challenges
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-end justify-start gap-[55px] max-w-full mq450:gap-[27px]'>
          <div className='w-[821px] flex flex-row items-start justify-end py-0 px-[26px] box-border max-w-full'>
            <div className='flex-1 flex flex-col items-start justify-start gap-[7px] max-w-full'>
              <div className='w-[488px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
                <div className='relative font-semibold mq450:text-lg'>
                  Custom API Development
                </div>
              </div>
              <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
                <img
                  className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Intrax/api-1-1.svg'
                />
                <div className='flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[430px] max-w-full mq750:min-w-full'>
                  <div className='self-stretch relative leading-[30px]'>
                    <ul className='m-0 font-inherit text-inherit pl-6'>
                      <li className='mb-0'>
                        Developed custom APIs to post order details from the
                        website to NetSuite.
                      </li>
                      <li className='mb-0'>{`Ensured secure and efficient data transfer between the website and `}</li>
                    </ul>
                    <p className='m-0'>
                      {' '}
                      NetSuite for accurate order management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        </div>
        <SolutionHeaders
          automationOfOrderAndInvoice='Automation of Order and Invoice Management'
          automationOfOrderAndInvoice1='/automation-of-order-and-invoice-management-1.svg'
          automatedTheProcessOfManaging='Automated the process of managing orders in NetSuite, reducing the need for manual intervention.'
          implementedASystemToPullInvoic="Implemented a system to pull invoice data from NetSuite and display it on the user's dashboard, providing real-time access to invoice information."
        />
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        <SolutionHeaders
          propWidth='484px'
          automationOfOrderAndInvoice='User-Friendly Dashboard'
          automationOfOrderAndInvoice1='/userfriendly-dashboard-1.svg'
          automatedTheProcessOfManaging='Designed an intuitive user interface for the website, allowing users to easily       place orders and view their invoices.'
          implementedASystemToPullInvoic='Implemented real-time notifications and updates about order statuses and invoices to enhance user experience.'
        />
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
