import React from 'react';
import PaymentBenefitDetails from './PaymentBenefitDetails';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[21px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[37px] max-w-full mq450:gap-[18px]'>
        <div className='w-[844px] flex flex-row items-start justify-end py-0 px-[33px] box-border max-w-full'>
          <div className='flex-1 flex flex-row flex-wrap items-start justify-start gap-[37.2px] max-w-full mq450:gap-[19px]'>
            <div className='h-[75.5px] w-[86.8px] flex flex-col items-start justify-start pt-[22px] px-0 pb-0 box-border'>
              <img
                className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/OZ-Party/group-1000001338.svg'
              />
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-3 min-w-[425px] shrink-0 max-w-full mq750:min-w-full'>
              <div className='relative font-semibold inline-block max-w-full mq450:text-lg'>
                Enhanced Booking Experience
              </div>
              <div className='h-[60px] relative text-lg leading-[30px] inline-block'>
                <p className='m-0'>
                  Users can now book events online or customize OzParty
                  packages, receiving
                </p>
                <p className='m-0'>
                  personalized e-bookings with $0 Upfront Booking Cost.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <div className='w-[798px] flex flex-row flex-wrap items-start justify-start pt-0 px-0 pb-[7px] box-border gap-[51px] max-w-full mq450:gap-[25px]'>
          <div className='w-[60px] flex flex-col items-start justify-start pt-[25px] px-0 pb-0 box-border'>
            <img
              className='self-stretch h-[47.9px] relative max-w-full overflow-hidden shrink-0'
              loading='lazy'
              alt=''
              src='/caseStudies/OZ-Party/group.svg'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold z-[2] mq450:text-lg'>
              Advanced Filtering Features
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
              <p className='m-0'>
                We&apos;ve integrated advanced filters such as price range,
                activities, number of
              </p>
              <p className='m-0'>
                guests, venue types, and regions to significantly enhance user
                experience.
              </p>
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-[7px] box-border gap-[37px] max-w-full mq450:gap-[18px]'>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <PaymentBenefitDetails
            group1000001339='/group-1000001339.svg'
            seamlessPaymentIntegration='Seamless Payment Integration'
            integrationOfTheEWayPayment='Integration of the E-Way Payment gateway enables smooth and secure'
            transactionsDirectlyThrough='transactions directly through the platform.'
          />
        </div>
        <div className='self-stretch h-[5px] flex flex-row items-start justify-start pt-0 px-0 pb-1 box-border max-w-full'>
          <div className='self-stretch flex-1 relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
        </div>
        <PaymentBenefitDetails
          propWidth='803px'
          propGap='46px'
          propWidth1='70px'
          propPadding='18px 0px 0px'
          group1000001339='/group-1000001340.svg'
          seamlessPaymentIntegration='Live Chat Support'
          propDisplay='unset'
          integrationOfTheEWayPayment='By integrating the Olark API, we now offer live chat support to assist users in'
          transactionsDirectlyThrough='real-time with any inquiries they may have.'
        />
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
