import React, { useState } from 'react';
import { FIELD_REQUIRED } from '../../redux/constant/ErrorMessage';
import { checkSize } from '../../utils/Validation';
import downloadfile from '../../assets/images/downloadfile.webp';
import cut from '../../assets/images/cut.webp';
import Button from '../../atoms/button/Button';
import Progressbar from '../../atoms/progress/Progressbar';
const FifthStep = ({ nextStep, formdata, fvalue, prevStep, steps }) => {
  const [validateErr, setValidateErr] = useState({});
  const [image, setImage] = useState([]);

  const handleChange = (event) => {
    formdata(event);
    const { name } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
  };
  const isValid = () => {
    let valid = true;
    if (fvalue?.skillneed === undefined || fvalue?.skillneed.trim() === '') {
      validateErr.skillneed = FIELD_REQUIRED;
      valid = false;
    }

    setValidateErr({ ...validateErr });
    return valid;
  };

  const formsubmit = (e) => {
    e.preventDefault();
    nextbutton();
  };
  const nextbutton = () => {
    if (isValid() !== false) {
      fvalue.file = image;
      nextStep();
    }
  };
  const checkImage = (files) => {
    let status = true;
    let size = checkSize(files);
    validateErr.image = '';
    if (files.length > 5) {
      validateErr.image = 'Only 5 Images Allowed';
      status = false;
    }
    if (size > 5242880) {
      validateErr.image = 'Image below than 5mb';
      status = false;
    }

    setValidateErr({ ...validateErr });
    return status;
  };

  const deleteImage = (file) => {
    let filterimages = image.filter((item) => item.name != file.name);
    setImage(filterimages);
  };
  const handleFileUpload = (e) => {
    let files = e.target.files;
    let allfile = [];
    for (let value of files) {
      let file = value;
      allfile.push(file);
    }

    let checkimage = checkImage([...allfile, ...image]);
    if (checkimage === true) {
      setImage([...allfile, ...image]);
    }
  };
  return (
    <div className=' bg-white py-24'>
      <div className='container mx-auto'>
        <h1 className='font-Gelasio capitalize xl:text-[50px] text-[40px] font-bold xl:w-[1136px] w-auto block mx-auto text-center pt-5 xl:leading-[63px] leading-[51px]'>
          What skills do you{' '}
          <span className='text-custom-green leading-[63.48px] font-Gelasio'>
            need?
          </span>
        </h1>
        <div className='flex justify-center py-10 pb-14 '>
          <Progressbar steps={steps} prevStep={prevStep} nextStep={nextStep} />
        </div>{' '}
        <form
          onSubmit={(e) => {
            formsubmit(e);
          }}
          className='box-content  block mx-auto max-w-2xl bg-[#FCFCFC] xl:p-14 p-7'
        >
          <div className=' bg-white  mb-2'>
            <textarea
              name='skillneed'
              className='resize-none appearance-none border  rounded w-full py-3 px-5 placeholder:text-[#C4C4C4] text-[#000]   focus:outline-none '
              type='text'
              rows='8'
              value={fvalue?.skillneed}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder='Example: We need experienced Angular & Node.Js developers'
            />
            {validateErr && (
              <span className='text-end	block	w-[100%] text-red-600	'>
                {validateErr?.skillneed}
              </span>
            )}
          </div>

          <div className='mb-4'>
            <div className='shadow appearance-none border rounded w-full text-[#c4c4c4]   leading-tight focus:outline-none focus:shadow-outline flex  items-center justify-between  '>
              <div className='flex gap-x-1 px-2'>
                {image?.length >= 1
                  ? [...Array(image?.length)].map((item, index) => {
                      const file = image[index];
                      return (
                        <div key={index} className='flex items-start'>
                          {file.type.includes('image') ? (
                            <img
                              className='rounded h-[35px] w-[35px] object-cover shadow p-1'
                              src={URL.createObjectURL(file)}
                              key={index}
                            />
                          ) : (
                            <img
                              className='ounded h-[35px] w-[35px] object-cover shadow p-1'
                              src='https://img.icons8.com/ios-glyphs/30/000000/document--v1.png'
                            />
                          )}
                          <img
                            className='-mt-[4px] -ml-3 h-4 cursor-pointer text-red-600'
                            onClick={() => deleteImage(file)}
                            src={cut}
                          ></img>
                        </div>
                      );
                    })
                  : 'Upload Your Files'}
              </div>
              <label>
                <a className='text-white bg-[#25C7C7] cursor-pointer w-auto xl:w-[169px]  xl:text-lg text-sm  leading-[21.78px]  font-medium px-5 py-3 text-center flex justify-center items-center  font-[Inter]'>
                  <img
                    className='mr-2 -ml-1 w-[24.48px] h-[20.33px] '
                    src={downloadfile}
                  ></img>
                  Upload
                </a>

                <input
                  onChange={(e) => handleFileUpload(e)}
                  name='file'
                  type='file'
                  accept='image/*,.doc,.pdf,.docx,.csv'
                  placeholder='Upload Your File'
                  className='hidden'
                  multiple
                />
              </label>
            </div>
          </div>

          {validateErr.image && (
            <span className='text-end	block	w-[100%] text-red-600	'>
              {validateErr?.image}
            </span>
          )}
          <p className='font-normal leading-[24px] text-[16px] font-[Inter] p-3 pt-0 text-[#828282]'>
            Maximum file size: 20 MB
          </p>

          <div className='flex  justify-center  xl:mt-14 mt-7 gap-5'>
            <button className='px-[25px] max-w-[300px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'>
              <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
              <span className='relative group-hover:text-white text-lg font-normal font-[Inter] leading-[21px]'>
                Review Summary
              </span>
            </button>
          </div>
          <div className='flex  justify-center py-3'>
            <Button prevStep={prevStep} nextStep={nextbutton} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FifthStep;
