import React from 'react';
import PropTypes from 'prop-types';

const SolutionDetails = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-0 pb-[17px] pl-[37px] pr-5 box-border max-w-full text-left text-3xl text-[#000] ${className}`}
    >
      <div className='w-[931px] flex flex-col items-start justify-start gap-[43.3px] max-w-full mq750:gap-[22px]'>
        <div className='self-stretch flex flex-row items-center justify-center py-0   box-border max-w-full mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
          <div className='flex flex-col w-[80%] items-start justify-start gap-[11px] max-w-full'>
            <div className='flex flex-row items-start justify-start gap-[9px] text-lg'>
              <div className='w-[114px] flex flex-col items-start justify-start pt-[79px] px-0 pb-0 box-border'>
                <img
                  className='w-[70px] h-[73.9px] relative'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Krugg/group-1000001329.svg'
                />
              </div>
              <div className='flex flex-col'>
                <div className='w-[530px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-full'>
                  <h3 className='m-0 relative text-[22px] text-inherit font-semibold font-inherit mq450:text-lg'>
                    Warehouse API Integration
                  </h3>
                </div>
                <div className='flex flex-row gap-[9px] text-lg'>
                  <div className='flex flex-col items-start justify-start pt-3.5 px-0 pb-0'>
                    <div className='flex flex-col items-start justify-start gap-[81.5px]'>
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                    </div>
                  </div>
                  <div className='relative leading-[30px] z-[3] font-n'>
                    <p className='m-0'>{`A comprehensive solution for warehouse, shipment, and inventory `}</p>
                    <p className='m-0'>management.</p>
                    <p className='m-0'>&nbsp;</p>
                    <p className='m-0'>{`Developed an application for the kitchen and bath utility industry client `}</p>
                    <p className='m-0'>to streamline shipping and invoicing.</p>
                    <p className='m-0'>&nbsp;</p>
                    <p className='m-0'>{`Features included admin panel shipping management, quote comparison, `}</p>
                    <p className='m-0'>{`label generation, email notifications, real-time tracking, and `}</p>
                    <p className='m-0'>QuickBooks integration.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />

        <div className='self-stretch flex flex-row items-center justify-center py-0   box-border max-w-full mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
          <div className='flex flex-col w-[80%] items-start justify-start gap-[11px] max-w-full'>
            <div className='flex flex-row items-start justify-start gap-[9px] text-lg'>
              <div className='w-[114px] flex flex-col items-start justify-start pt-[79px] px-0 pb-0 box-border'>
                <img
                  className='w-[70px] h-[73.9px] relative'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Krugg/group.svg'
                />
              </div>
              <div className='flex flex-col pl-8'>
                <div className='w-[530px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-full'>
                  <h3 className='m-0 relative text-[22px] text-inherit font-semibold font-inherit mq450:text-lg'>
                    E-commerce Platform Enhancements
                  </h3>
                </div>
                <div className='flex flex-row gap-[9px] text-lg'>
                  <div className='flex flex-col items-start justify-start pt-3.5 px-0 pb-0'>
                    <div className='flex flex-col items-start justify-start gap-[81.5px]'>
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      {/* <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' /> */}
                    </div>
                  </div>
                  <div className='relative leading-[30px] z-[3] font-n'>
                    <p className='m-0'>
                      Expanded product range with customizable options for LED
                      mirrors.
                    </p>
                    <p className='m-0'>&nbsp;</p>
                    <p className='m-0'>
                      Ensured free shipping with FedEx and UPS and secure
                      payment processing through QuickBooks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />

        <div className='self-stretch flex flex-row items-center justify-center py-0   box-border max-w-full mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
          <div className='flex flex-col w-[80%] items-start justify-start gap-[11px] max-w-full'>
            <div className='flex flex-row items-start justify-start gap-[9px] text-lg'>
              <div className='w-[114px] flex flex-col items-start justify-start pt-[79px] px-0 pb-0 box-border'>
                <img
                  className='w-[70px] h-[73.9px] relative'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Krugg/group-1000001330.svg'
                />
              </div>
              <div className='flex flex-col'>
                <div className='w-[530px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-full'>
                  <h3 className='m-0 relative text-[22px] text-inherit font-semibold font-inherit mq450:text-lg'>
                    Barcode Scanner Implementation
                  </h3>
                </div>
                <div className='flex flex-row gap-[9px] text-lg'>
                  <div className='flex flex-col items-start justify-start pt-3.5 px-0 pb-0'>
                    <div className='flex flex-col items-start justify-start gap-[81.5px]'>
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                      {/* <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' /> */}
                    </div>
                  </div>
                  <div className='relative leading-[30px] z-[3] font-n'>
                    <p className='m-0'>{`The Barcode scanner on Krugg's website facilitates efficient inventory `}</p>
                    <p className='m-0'>{`management by enabling real-time location checks of items in racks, `}</p>
                    <p className='m-0'>{`tracking inventory changes, and ensuring readiness for `}</p>
                    <p className='m-0'>shipment boarding.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionDetails.propTypes = {
  className: PropTypes.string,
};

export default SolutionDetails;
