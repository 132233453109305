import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[67%] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '45px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#1876D1,#18233A)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-16 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,#1876D1,#18233A)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] gap-3 px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 text-[48px] self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[48.5px] w-[50px] relative object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Financial-Chatbot/hugeiconsbot.svg'
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start gap-[20px] max-w-full text-center mt-20'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Financial Data Integration'
            propBackgroundColor='#193255'
            propBackgroundColor1='#193255'
            definingAndImplementingFeatures='Aggregating accurate and 
            real-time financial data 
            from multiple sources was 
            complex and resource-
            intensive.'
            // setTheCoachingMarketplaceApart='The follow-up procedure for the leads
            // was manual because of which there '
            // competitors='were high chances of missing a lead.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#223D64'
            propBackgroundColor1='#223D64'
            placeholder='2'
            marketplace='Financial Chatbot'
            definingAndImplementingFeatures='Developed a Financial Chatbot 
            using prompt engineering and 
            OpenAI integration to cater to 
            various financial needs, such 
            as checking stock prices, 
            comparing stocks, and handling 
            stock predictions.'
            // setTheCoachingMarketplaceApart='time-consuming process into different onboarding and
            // payroll systems.'
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#2C4D7A'
            propBackgroundColor1='#2C4D7A'
            placeholder='3'
            marketplace='User Engagement'
            definingAndImplementingFeatures='Providing a seamless and 
            interactive user experience 
            to engage investors and 
            meet their financial needs.'

            // setTheCoachingMarketplaceApart='Providing a seamless and challenges in managing theirh conditions outside of healthcare facilities'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#476DA4'
            propBackgroundColor1='#476DA4'
            placeholder='4'
            marketplace='Investment Insights'
            definingAndImplementingFeatures='Offering advanced tools to 
            analyze stock performance, 
            compare assets, and predict 
            financial trends effectively.'
            // setTheCoachingMarketplaceApart='privacy breaches, underscoring
            // the need for more robust digital solutions.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
