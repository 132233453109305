import React, { useState, useEffect } from 'react';
import style from './Modal.module.css';
import HireTalentModal from './HireTalentModal';
import closeimg from '../../assets/images/close.webp';
import downloadfile from '../../assets/images/downloadfile.webp';
import ReCAPTCHA from 'react-google-recaptcha';
import { submitForm } from '../../utils';
import { useDispatch } from 'react-redux';
import { setAlert, setLoader } from '../../redux/Action/actions';
import { FIELD_REQUIRED } from '../../redux/constant/ErrorMessage';
import { checkEmail, checkSize } from '../../utils/Validation';
import { useNavigate } from 'react-router-dom';
export default function HeaderModal(props) {
  const [show, setShow] = useState(false);
  const [third, setThird] = useState(false);
  const [data, setData] = useState();
  const [validateErr, setValidateErr] = useState({});
  const [image, setImage] = useState();
  const { showModal, hideModal } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const dispatch = useDispatch();
  const hide = () => {
    setShow(false);
  };
  const close = () => {
    hideModal();
    setThird(false);
  };
  /*  const thirdModel = () => {
    setThird(true);
  };
 */
  const onChange = () => {
    console.log('captcha working need token');
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
    setData({ ...data, [name]: value });
  };

  const isValid = () => {
    let valid = true;
    if (data?.name === undefined || data?.name.trim() === '') {
      validateErr.name = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.email === undefined || data?.email.trim() === '') {
      validateErr.email = FIELD_REQUIRED;
      valid = false;
    } else {
      let { status, msg } = checkEmail(data?.email);
      if (status !== undefined) {
        valid = status;
        validateErr.email = msg;
      }
    }
    if (data?.help === undefined || data?.help.trim() === '') {
      validateErr.help = FIELD_REQUIRED;
      valid = false;
    }
    if (image === undefined) {
      validateErr.image = FIELD_REQUIRED;
      valid = false;
    }

    setValidateErr({ ...validateErr });
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid();
    if (validateData === true) {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', data?.name);
      formdata.append('email', data?.email);
      formdata.append('help', data?.help);
      formdata.append('file', image[0]);
      formdata.append('type', 'anothercomment');
      let res = await submitForm(formdata);
      dispatch(setLoader(false));
      if (res.status === 200) {
        dispatch(setAlert({ type: 'success', msg: 'File Sent Successfully.' }));
        setData({});
        setImage();
        return;
      }
      dispatch(setAlert({ type: 'danger', msg: 'Somethings Went Wrong.' }));
    }
  };

  const checkImage = (files) => {
    let status = true;
    let size = checkSize(files);
    validateErr.image = '';
    if (size > 2097152) {
      validateErr.image = 'File Should below than 2mb';
      status = false;
    }
    setValidateErr({ ...validateErr });
    return status;
  };
  const handleFileUpload = (e) => {
    let files = e.target.files;
    let checkimage = checkImage(files);
    if (checkimage === true) {
      setImage(files);
    }
  };
  const navigateTo = () => {
    hideModal();
    navigate('/get-in-touch');
  };

  const navigateToComponent = () => {
    hideModal();
    navigate('/career');
  };
  return (
    <>
      {showModal ? (
        <>
          <div
            className='justify-center items-center duration-[3000ms]
  transition-all delay-1000	 flex overflow-hidden overflow-y-hidden fixed text-[#000] inset-0 z-50 outline-none focus:outline-none p-2 xl:p-10'
          >
            <div
              className='relative w-auto my-6 mx-auto max-w-6xl'
              data-aos='fade-down'
              data-aos-offset='100'
              data-aos-delay='100'
            >
              <div className={`${style.modalbg}`}>
                <div className=' p-5  text-end'>
                  <button
                    className=' ml-auto  border-0 text-black  text-3xl leading-none font-semibold'
                    onClick={() => close()}
                  >
                    <img src={closeimg}></img>
                  </button>
                </div>

                <div className='px-4  flex flex-col justify-center items-center '>
                  <h1 className='xl:text-[50px] w-auto text-xl xl:w-[503px]  text-center font-bold leading-5 xl:leading-[54px]'>
                    {`Let's`} get in{' '}
                    <span className='text-custom-green' data-testid='name'>
                      touch with
                    </span>{' '}
                    us
                  </h1>
                  <h3
                    className='xl:text-[32px] text-xl font-Gelasio leading-[40.63px] py-2 xl:py-5'
                    data-testid='name'
                  >
                    How can we help you?
                  </h3>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className='flex flex-col  xl:p-10 p-0  gap-y-6 '>
                    <button
                      data-testid='bttn'
                      className='bg-[#ffffff] shadow  xl:h-[50px] h-auto xl:text-lg text-sm xl:leading-[30px]  font-medium uppercase  px-6 py-3 hover:bg-[#282828] hover:text-white  hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-[Inter]'
                      type='button'
                      onClick={() => navigateTo()}
                    >
                      I want to hire tech talent
                    </button>

                    <button
                      className='bg-[#ffffff] font-[Inter] font-medium uppercase xl:h-[50px] h-auto xl:text-lg text-sm xl:leading-[30px] px-6 py-3 hover:bg-[#282828] hover:text-white shadow hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => navigateToComponent()}
                    >
                      I want to apply for a job at DEVtrust
                    </button>

                    {/* <button
                      className='bg-[#ffffff] font-[Inter] shadow  xl:h-[50px] h-auto xl:text-lg text-sm leading-[30px] font-medium uppercase  px-6 py-3   hover:bg-[#282828] hover:text-white hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={thirdModel}
                    >
                      I have another question or comment
                    </button> */}

                    {third ? (
                      <div className='font-[Inter]'>
                        <input
                          name='name'
                          value={data?.name || ''}
                          type='text'
                          onChange={(e) => handleChange(e)}
                          className='w-full p-3 mb-6 outline-none border-[1px] border-[#E3E3E3]'
                          placeholder='Full Name'
                        ></input>
                        {validateErr && (
                          <span className='text-end	block	w-[100%] text-red-600	'>
                            {validateErr?.name}
                          </span>
                        )}
                        <br />
                        <input
                          name='email'
                          value={data?.email || ''}
                          type='text'
                          onChange={(e) => handleChange(e)}
                          className=' w-full p-3 mb-6 outline-none border-[1px] border-[#E3E3E3]'
                          placeholder='Email Address'
                        ></input>
                        {validateErr && (
                          <span className='text-end	block	w-[100%] text-red-600	'>
                            {validateErr?.email}
                          </span>
                        )}
                        <br />
                        <textarea
                          name='help'
                          value={data?.help || ''}
                          onChange={(e) => handleChange(e)}
                          className='w-full p-3 mb-6 outline-none border-[1px] border-[#E3E3E3]'
                          placeholder='How can we help?'
                          rows='6'
                        ></textarea>
                        {validateErr && (
                          <span className='text-end	block	w-[100%] text-red-600	'>
                            {validateErr?.help}
                          </span>
                        )}
                        <div className='mt-10 flex xl:justify-between justify-center gap-14 flex-wrap'>
                          <h1 className=' fo(nt-[Inter] text-[19px] font-bold leading-[19.5px]'>
                            Uploads Your Documents
                            <br />{' '}
                            <span className='font-light leading-[20px]'>
                              (pdf doc docx | Max.: 2Mb)
                            </span>
                            <span className='font-light leading-[20px]'>
                              {image && image[0]?.name}
                            </span>
                          </h1>
                          <label>
                            <a className='cursor-pointer text-white bg-[#25C7C7] w-auto xl:w-[289px] h-[50px] text-lg focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50  leading-[22px]  font-semibold px-5 py-2.5 text-center flex justify-center items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2'>
                              <img
                                className='mr-2 -ml-1 w-[24.48px] h-[20.33px] '
                                src={downloadfile}
                              ></img>
                              Upload file
                            </a>

                            <input
                              onChange={(e) => handleFileUpload(e)}
                              name='file'
                              type='file'
                              placeholder='Upload Your File'
                              className='hidden'
                              multiple
                            />
                          </label>
                        </div>
                        {validateErr && (
                          <span className='text-end	block	w-[100%] text-red-600	'>
                            {validateErr?.image}
                          </span>
                        )}
                        <div className='flex justify-center pt-10'>
                          <ReCAPTCHA
                            sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                            '
                            onChange={onChange}
                          />
                        </div>
                        <div className='flex justify-center pt-10'>
                          <button className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block '>
                            <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                            <span className='relative group-hover:text-white text-lg font-medium font-[[Inter]] leading-[21px]'>
                              Send Message
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed  inset-0 z-40 bg-black'></div>
        </>
      ) : null}
      <HireTalentModal showModal={show} hideModal={hide} />
    </>
  );
}
