import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/N-foundation/image1.png';
import logo from '../../../assets/N-foundation/group-1000001263.svg';
import benefitLogo from '../../../assets/N-foundation/group-1000001263.svg';
import rectangle from '../../../assets/N-foundation/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/N-foundation/icon1.svg';
import icon2 from '../../../assets/N-foundation/icon2.svg';
import icon3 from '../../../assets/N-foundation/icon3.svg';
import icon4 from '../../../assets/N-foundation/icon4.svg';
import icon5 from '../../../assets/N-foundation/icon5.svg';
import interfaceImg from '../../../assets/N-foundation/interface.svg';

import benefitIcon from '../../../assets/N-foundation/bxuparrow@2x.png';
import benefitImage from '../../../assets/N-foundation/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Seamless Integration and User-Friendly Design',
    description:
      'Developing a platform with dynamic features like audio-enhanced vocabulary, animated storybooks, and interactive globe animations required seamless integration and a user-friendly design.',
    boxClass: '#00B8F2',
  },
  {
    number: 2,
    title: 'Engaging and Interactive Content',
    description:
      'Keeping children engaged necessitates captivating and interactive content tailored to maintain their attention spans and promote continuous learning.',
    boxClass: '#33CEFF',
  },
  {
    number: 3,
    title: 'Precise Translation and Localization',
    description:
      'Providing vocabulary and book content in various languages demanded precise translation and localization to ensure cultural relevance and linguistic accuracy.',
    boxClass: '#5AD6FD',
  },
];

const solutionData = [
  {
    title: 'Immersive Language Learning Experience',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'By leveraging text-to-speech (TTS) APIs and pre-recorded audio files, the platform offers an immersive language learning experience.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Animated and Interactive Storybooks',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: [
      'The platform features animated stories with visually stimulating illustrations and interactive elements.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Diverse Audio Options',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Integrated multiple audio options within the platform. Featuring various narrators and languages, the solution utilizes the Web Audio API for superior audio playback quality and enhanced control.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Global Accessibility',
    imageSrc: icon4,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'To enhance global accessibility, the platform integrates Google translator, supporting multiple.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Interactive 3D Globe',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Implemented an innovative 3D globe with interactive hotspots.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Increased User Engagement',
    description: 'The interactive and captivating content led to a',
    percentage: '40% ',
    rest: 'increase in user engagement.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Improved Vocabulary Acquisition',
    description: 'The multilingual features contributed to a ',
    percentage: '35% ',
    rest: 'improvement in vocabulary acquisition across different linguistic backgrounds.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Enhanced User Satisfaction',
    description:
      'Customizable audio options and interactive features significantly enhanced user satisfaction, reflected in a',
    percentage: '30% ',
    rest: 'increase in positive feedback.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
];

const N_Foundation = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent'>
        <HomeBanner
          title='N-Foundation'
          textcolor='#459FE3'
          description=''
          image={frame1}
          How=' '
          DEVtrust={''}
          highlightedText={
            'Building Nfoundation with DEVtrust An E-Learning Platform'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Sandeep and Nirmal Foundation is a non-profit organization dedicated to providing education
        to different age group. When they sought a web app development company to build 
        an effective e-learning platform for children, DEVtrusts expertise stood out.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #ABDCFF, #0396FF)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges'
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#343C55'}
        textColor={'#70C3FF'}
        gradient='linear-gradient(135deg, #ABDCFF, #0396FF 60.5%)'
      />
      <InterfaceSection
        heading='N Foundation’s user-friendly interface'
        Description='A non-profit organization, provides online tools to develop vocabulary, grammar, and geographic knowledge'
        interfaceImg={interfaceImg}
      />
      {/* <InterfaceSection
        heading='Quant Capital’s Admin Portal'
        Description=' '
        interfaceImg={interfaceImg}
      /> */}
    </div>
  );
};

export default N_Foundation;
