import React, { useEffect, useMemo, useState } from 'react';
import style from './Portfolio.module.css';
import Modal from '../../atoms/modal/Modal';
import icon from '../../assets/images/portfoliobanner.svg';
import { setPortfolio } from '../../redux/Action/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PortfolioCard from '../../atoms/portfoliocard/portfolioCard/PortfolioCard';
// import PortfolioMobileCard from '../../atoms/portfoliocard/portfolioCard/PortfolioMobileCard';
import '../portfolio/Portfolio.css';
const Portfolio = () => {
  const dataTabs = [
    'ALL',
    'EDUCATION',
    'FINANCE',
    'MARKETING',
    'CRM',
    'HEALTH',
    'MARKETPLACE',
    'SAAS',
    'REAL ESTATE',
  ];
  const [category, setCategory] = useState('ALL');
  const [showModal, setShowModal] = useState(false);
  const [portfoliodata, setPortfolioData] = useState([]);
  const [visibleItems, setVisibleItems] = useState(5);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { portfolio } = useSelector((reducers) => reducers.portfolioreducer);
  useEffect(() => {
    if (portfolio === undefined) {
      dispatch(setPortfolio());
      return;
    }
    setPortfolioData(portfolio);
  }, [portfolio]);
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setShowModal(false);
  };
  const openModal = () => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
    setData();
    setShowModal(true);
  };
  // const colorClasses = [
  //   'bg-lavender',
  //   'bg-mint',
  //   'bg-peach',
  //   'bg-misty',
  //   'bg-blue',
  //   'bg-coral',
  // ];
  const reversedportfoliodata = [...portfoliodata].reverse();
  const portfolioData = useMemo(() => {
    return reversedportfoliodata?.slice(0, visibleItems).map((item, key) => (
      // <PortfolioData data={item} key={item.id} open={openModal} />
      <div key={key}>
        <div className=''>
          <PortfolioCard
            data={item}
            key={item.id}
            open={openModal}
            imageUrl={item?.portfolio_bg_url}
            bgType={item?.portfolio_background_type}
            bgColor={item?.portfolio_background_color}
            bgImage={item?.portfolio_background_image}
            portfolioImage={item?.secondary_portfolio_image}
            contentColor={item?.portfolio_content_color}
            textContentColor={item?.acf?.text_color}
          />
        </div>
        {/* <div
          className={`block md:hidden ${
            colorClasses[key % colorClasses.length]
          }`}
        >
          <PortfolioMobileCard
            data={item}
            key={item.id}
            open={openModal}
            imageUrl={item?.portfolio_mobile_bg_url}
            textContentColor={item?.acf?.text_color}
          />
        </div> */}
      </div>
    ));
  }, [reversedportfoliodata, visibleItems, openModal]);

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  };

  const handleCategory = (selectedcategory) => {
    setCategory(selectedcategory);
    if (selectedcategory !== 'ALL') {
      let filterportfolio = portfolio.filter(
        (item) => item.acf.portfolio_type === selectedcategory
      );
      setPortfolioData(filterportfolio);
      return;
    }
    setPortfolioData(portfolio);
  };
  return (
    <div
      className='bg-white xl:pt-22 pt-24 pb-8 overflow-hidden'
      data-testid='text'
    >
      <Helmet
        meta={[
          { name: 'title', content: 'DEVtrust Portfolio' },
          {
            name: 'description',
            content:
              'Let’s start defining our showcase work. This will help you to understand DEVtrust work and technical expertise.',
          },
          {
            name: 'keywords',
            content:
              'IT Services, Mobile Apps Development, Web Development Company, Custom Website Design, Software Development, Showcase Work, Portfolio ',
          },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: 'English' },
        ]}
        title='Portfolio'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>

      <div className='max-w-7xl xl:px-20 md:p-6 xsm:px-4 mx-auto'>
        <div className=' pt-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#000] font-bold leading-[50.78px] font-Gelasio'>
              Our Innovation &nbsp;
              <br />
              <span className='text-[#43BDC1]'>& Experties</span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              We go beyond being a typical product development firm that simply
              handles client needs. Instead, we serve as visionary architects of
              digital transformation & progress, driven by unwavering passion &
              a strong commitment to achieving excellence.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  common_btn group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='relative  text-lg font-medium leading-[21px]'>
                  Get In Touch
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
      </div>

      <div className='max-w-7xl xl:px-20 md:p-6 xsm:px-4 mx-auto'>
        <h1 className='text-center text-[40px] font-bold font-Gelasio py-3 leading-[63px]'>
          Projects <span className='text-[#25C7C7]'>We’ve done</span>
        </h1>
        <div className='flex justify-center gap-x-10 gap-y-5 py-5 flex-wrap'>
          {dataTabs.map((item, index) => (
            <button
              className={`${
                item == category ? style.customdesign : 'text-green'
              } font-[Inter] text-[14px] font-medium leading-[16.94px] hover:text-custom-green`}
              key={index}
              onClick={() => handleCategory(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <div className='w-full h-full'>
          {portfolioData}

          {/* Load More button */}
          {reversedportfoliodata.length > visibleItems && (
            <div className='flex justify-center'>
              <button
                onClick={handleLoadMore}
                className='load-more-button shadow-loadShadow text-center px-8 p-4 text-sm text-[#474747] font-medium'
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal showModal={showModal} data={data} hideModal={closeModal} />
    </div>
  );
};
export default Portfolio;
