import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({ className = '' }) => {
  return (
    <div
      className={`w-[1371px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[87px] max-w-full text-center text-45xl text-gray-300 font-inter lg:flex-wrap mq450:gap-[22px] mq750:gap-[43px] ${className}`}
    >
      <div className='w-[505px] flex flex-col items-start justify-start pt-[230px] px-0 pb-0 box-border min-w-[505px] max-w-full lg:flex-1 mq450:pt-[149px] mq450:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start gap-7'>
          <h1 className='m-0 self-stretch relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
            <span>{`OZ Party `}</span>
            <span className='text-orange'>Events</span>
          </h1>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px]'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='flex flex-row items-start justify-start py-0 pl-[71px] pr-[74px] text-xl text-dimgray-200 mq750:pl-[35px] mq750:pr-[37px] mq750:box-border'>
            <div className='h-[60px] relative leading-[30px] inline-block mq450:text-base mq450:leading-[24px]'>
              <p className='m-0'>Australia’s premier event planning and</p>
              <p className='m-0'>management platform</p>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/OZ-Party/mask-group@2x.png'
      />
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
