import React from 'react';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`w-[1429px] flex flex-row items-start justify-center pt-0 px-5 pb-[82px] box-border max-w-full text-left text-29xl text-white font-inter mq750:pb-[34px] mq750:box-border mq1050:pb-[53px] mq1050:box-border ${className}`}
    >
      <div className='w-[1151px] flex flex-row items-end justify-center gap-[84px] max-w-full lg:gap-[42px] mq750:gap-[21px] mq1050:flex-wrap'>
        <div className='flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[30px] box-border min-w-[494px] max-w-full mq750:min-w-full'>
          <div className='self-stretch flex flex-col items-start justify-start gap-[102px] max-w-full mq450:gap-[25px] mq1050:gap-[51px]'>
            <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full'>
              <img
                className='h-[73px] w-[60px] absolute !m-[0] top-[-71px] right-[86px]'
                loading='lazy'
                alt=''
              />
              <div className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#e1af16,_#754400)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[30px] box-border gap-4 max-w-full z-[2] mq750:pl-[39px] mq750:box-border mq450:pt-5 mq450:pb-[53px] mq450:box-border'>
                <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#e1af16,_#754400)] hidden max-w-full' />
                <div className='self-stretch h-44 relative'>
                  <h1 className='m-0 absolute top-[60px] left-[0px] text-inherit z-[3] font-inherit mq450:text-10xl mq1050:text-19xl'>
                    <p className='m-0'>
                      <b>{`Problem `}</b>
                    </p>
                    <p className='m-0'>Statement</p>
                  </h1>
                  <img
                    className='absolute top-[0px] left-[552px] w-[52px] h-[67px] object-cover z-[3]'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/RankUpAcadmey/63f1443e4db3b97d912eea64-1-1@2x.png'
                  />
                </div>
                <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[28px]'>
                  <p className='m-0'>Here are the challenges which we faced</p>
                  <p className='m-0'>at the time of develop the application</p>
                </div>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-start py-0 pl-[3px] pr-0 box-border max-w-full text-center text-13xl'>
              <div className='flex-1 flex flex-row items-start justify-start gap-[51px] max-w-full mq750:flex-wrap mq1050:gap-[25px]'>
                <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[249px] max-w-full mq450:gap-5'>
                  <div className='rounded-3xs bg-saddlebrown flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                    <div className='h-[69px] w-[69px] relative rounded-3xs bg-saddlebrown hidden' />
                    <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                      1
                    </b>
                  </div>
                  <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                    <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                      <p className='m-0'>User Roles hierarchy</p>
                      <p className='m-0'>and Permissions</p>
                    </div>
                    <div className='relative text-lg leading-[30px] text-dimgray-200 inline-block min-h-[210px] z-[1]'>
                      <ol className='m-0 font-inherit text-inherit pl-6'>
                        <li className='mb-0'>{`Designing a system to accommodate five `}</li>
                      </ol>
                      <p className='m-0'>
                        {' '}
                        distinct user roles, each with specific access rights
                        and functionalities.
                      </p>
                      <p className='m-0'>{`2. Ensuring that each role had appropriate `}</p>
                      <p className='m-0'>
                        {' '}
                        access to data and tools relevant to
                      </p>
                      <p className='m-0'> their responsibilities.</p>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start gap-10 min-w-[323px] max-w-full mq750:flex-1 mq450:gap-5'>
                  <div className='rounded-3xs bg-darkgoldenrod-200 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                    <div className='h-[69px] w-[69px] relative rounded-3xs bg-darkgoldenrod-200 hidden' />
                    <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                      2
                    </b>
                  </div>
                  <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                    <div className='h-[60px] relative leading-[30px] font-semibold inline-block z-[1] mq450:text-lg mq450:leading-[24px]'>
                      <p className='m-0'>Real-Time Data and</p>
                      <p className='m-0'>Commission Tracking</p>
                    </div>
                    <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                      <p className='m-0'>{`Implementing accurate commission `}</p>
                      <p className='m-0'>{`calculations and tracking for different `}</p>
                      <p className='m-0'>{`sales roles with real time data `}</p>
                      <p className='m-0'>synchronisation.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[307px] flex flex-col items-start justify-start gap-10 min-w-[307px] text-center text-13xl mq450:gap-5 mq1050:flex-1'>
          <div className='rounded-3xs bg-darkgoldenrod-100 flex flex-row items-start justify-start py-[15px] pl-6 pr-[23px] z-[1]'>
            <div className='h-[69px] w-[69px] relative rounded-3xs bg-darkgoldenrod-100 hidden' />
            <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
              3
            </b>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
            <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
              <p className='m-0'>Data Management</p>
              <p className='m-0'>and Reporting</p>
            </div>
            <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
              <ol className='m-0 font-inherit text-inherit pl-6'>
                <li className='mb-0'>{`Developing detailed dashboards `}</li>
              </ol>
              <p className='m-0'>{`     for various roles to track sales `}</p>
              <p className='m-0'>{`     performance, commissions, and `}</p>
              <p className='m-0'>{`     team metrics.2. Ensuring robust data security `}</p>
              <p className='m-0'>{`    and efficient handling of large `}</p>
              <p className='m-0'>{`    volumes of sales data along with `}</p>
              <p className='m-0'> the client transaction details .</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent1;
