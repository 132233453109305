import React from 'react';

const BenefitsSection = ({
  benefitsData,
  benefitLogo,
  benefitIMG,
  gradient,
  bgColor,
  textColor,
}) => {
  return (
    <section className='results-background-container-parent'>
      <div className='results-background-container'>
        <img
          className='results-background-icon'
          loading='lazy'
          alt=''
          src={benefitIMG}
        />
      </div>
      <div className='results-content'>
        <div className='results-header' style={{ background: gradient }}>
          <div className='results-header-child'></div>
          <div className='results-title'>
            <div className='results-which-we-container'>
              <h3 className='results'>
                <b>Impact & Achievements</b>
              </h3>
              {/* <p className='which-we-achieved'>Which we achieved</p> */}
            </div>
            <div className='the-implementation-of'>
              The implementation of solutions yielded impressive results
            </div>
          </div>
          <div className='results-logo-container'>
            <img className='logo-new-icon1' alt='' src={benefitLogo} />
          </div>
        </div>
        <div className='frame-div' style={{ background: bgColor }}>
          {benefitsData.map((benefit, index) => (
            <div key={index} className='benefits-list'>
              <div className='bxup-arrow-container'>
                <img
                  className='bxup-arrow-icon'
                  loading='lazy'
                  alt={benefit.imageAlt}
                  src={benefit.imageSrc}
                />
              </div>
              <div className='benefit-descriptions'>
                <b className='benefit-title'>{benefit.title}</b>
                <div className='benefit-description-container'>
                  <span>{benefit.description}</span>
                  <b className='benefit-percentage'>
                    <span style={{ color: textColor }}>
                      {' '}
                      {benefit.percentage}
                    </span>
                  </b>
                  <span>{benefit.rest}</span>
                  {/* <span className='percentage-decimal'>.</span> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
