import React from 'react';
import ChallengeHeaders from './ChallengeHeaders';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[95px] pl-5 pr-[25px] box-border max-w-full text-left text-29xl text-white font-inter mq750:pb-10 mq750:box-border mq1050:pb-[62px] mq1050:box-border ${className}`}
    >
      <div className='w-[1135px] flex flex-col items-end justify-start gap-[13px] max-w-full'>
        <div className='w-[1078px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <img className='h-[73px] w-[60px] relative' loading='lazy' alt='' />
        </div>
        <div className='self-stretch flex flex-row flex-wrap items-end justify-center gap-2.5 max-w-full'>
          <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[561px] max-w-full mq750:min-w-full mq450:gap-[22px] mq1050:gap-[45px]'>
            <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#2c4bd7,_#09085e)] flex flex-col items-start justify-start pt-[25px] pb-[82px] pl-[78px] pr-[25px] box-border gap-[15.5px] max-w-full z-[2] mq750:pl-[39px] mq750:box-border mq450:pt-5 mq450:pb-[53px] mq450:box-border'>
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#2c4bd7,_#09085e)] hidden max-w-full' />
              <div className='self-stretch flex flex-row items-start justify-end'>
                <img
                  className='h-[50px] w-[43.5px] relative object-cover z-[3]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Planwell/vector-2-1@2x.png'
                />
              </div>
              <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Problem `}</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-start py-0 pl-[3px] pr-0 box-border max-w-full text-center text-13xl'>
              <div className='flex-1 flex flex-row items-start justify-start gap-[27.5px] max-w-full mq750:flex-wrap'>
                <ChallengeHeaders
                  emptyChallengeHeader='1'
                  complexityOf='Complexity of'
                  financialPlanning='Financial Planning'
                  manyIndividualsFind='Many individuals find '
                  traditionalRetirementPlanning='traditional retirement planning '
                  toolsComplexAndDifficultTo='tools complex and difficult to '
                  navigateOftenRequiring='navigate, often requiring '
                  extensiveFinancialKnowledge='extensive financial knowledge '
                  andTimeConsuming='and time consuming '
                  dataEntry='data entry.'
                />
                <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[179px] mq450:gap-5'>
                  <div className='rounded-3xs bg-slateblue-200 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                    <div className='h-[69px] w-[69px] relative rounded-3xs bg-slateblue-200 hidden' />
                    <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                      2
                    </b>
                  </div>
                  <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                    <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                      <p className='m-0'>Lack of</p>
                      <p className='m-0'>Accessibility</p>
                    </div>
                    <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                      <p className='m-0'>Existing tools, particularly Excel</p>
                      <p className='m-0'>spreadsheets, may not be easily</p>
                      <p className='m-0'>accessible across different</p>
                      <p className='m-0'>devices or to users with varying</p>
                      <p className='m-0'>levels of technical proficiency.</p>
                    </div>
                  </div>
                </div>
                <ChallengeHeaders
                  propFlex='1'
                  propMinWidth='175px'
                  propBackgroundColor='#3e3dba'
                  propBackgroundColor1='#3e3dba'
                  emptyChallengeHeader='3'
                  propWidth='22px'
                  propMinWidth1='22px'
                  propAlignSelf='stretch'
                  propHeight='unset'
                  propDisplay='unset'
                  complexityOf='Inability to Visualize'
                  financialPlanning='Scenarios'
                  manyIndividualsFind='Users often struggle to '
                  traditionalRetirementPlanning='visualize the impact of different'
                  toolsComplexAndDifficultTo='financial decisions on their'
                  navigateOftenRequiring='retirement, home buying, or'
                  extensiveFinancialKnowledge='college savings goals without'
                  andTimeConsuming="robust 'what-if' analysis"
                  dataEntry='capabilities.'
                />
              </div>
            </div>
          </div>
          <div className='w-[262px] flex flex-col items-start justify-start gap-10 text-center text-13xl mq450:gap-5'>
            <div className='rounded-3xs bg-mediumslateblue-100 flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
              <div className='h-[69px] w-[69px] relative rounded-3xs bg-mediumslateblue-100 hidden' />
              <b className='relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                4
              </b>
            </div>
            <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
              <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                <p className='m-0'>Static and</p>
                <p className='m-0'>Outdated Tools</p>
              </div>
              <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                Traditional calculators often lack real-time updates and
                interactive features, making it challenging for users to adjust
                their plans dynamically based on changing financial
                circumstances.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
