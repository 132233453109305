import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const BenefitItems = ({
  className = '',
  propAlignSelf,
  propWidth,
  fullyFunctionalInteractive,
  propDisplay,
  usersNowHaveAccessToAComprehe,
}) => {
  const benefitItemsStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      width: propWidth,
    };
  }, [propAlignSelf, propWidth]);

  const fullyFunctionalInteractiveStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter mq750:flex-wrap ${className}`}
      style={benefitItemsStyle}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
          loading='lazy'
          alt=''
          src='/caseStudies/Portfolio-Watc/bxuparrow@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
        <b
          className='relative inline-block max-w-full z-[3] mq450:text-base'
          style={fullyFunctionalInteractiveStyle}
        >
          {fullyFunctionalInteractive}
        </b>
        <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
          {usersNowHaveAccessToAComprehe}
        </div>
      </div>
    </div>
  );
};

BenefitItems.propTypes = {
  className: PropTypes.string,
  fullyFunctionalInteractive: PropTypes.string,
  usersNowHaveAccessToAComprehe: PropTypes.string,

  /** Style props */
  propAlignSelf: PropTypes.any,
  propWidth: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default BenefitItems;
