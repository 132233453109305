import React from 'react';

const IntroWrapper = ({ intro, decription }) => {
  return (
    <div className='intro_top_spacing'>
      <section className='intro-wraper'>
        <div className='intro-content'>
          <div className='intro-heading'>
            <h2 className='introduction'>{intro}</h2>
          </div>
          <div
            className='intro-text'
            dangerouslySetInnerHTML={{ __html: decription }}
          >
            {/* <p>{decription}</p> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default IntroWrapper;
