import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { getCaseStudies, searchCaseStudies } from '../../../../utils';

const StoriesHeader = ({ className = '' }) => {
  const [caseStudiesRecord, setStudiesRecord] = useState([]);
  const [visibleItems, setVisibleItems] = useState(6);
  const [searchRecord, setSearchRecord] = useState('');
  const [noDataFound, setNoDataFound] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    toGetCaseStudiesData();
  }, []);

  const toGetCaseStudiesData = async () => {
    let data = await getCaseStudies();
    console.log('dasfasdf', data);
    setStudiesRecord(data);
  };

  const handleChangeSearchData = async (e) => {
    const value = e.target.value;
    if (value.trim() === '') {
      setNoDataFound(false);
      toGetCaseStudiesData();
      return;
    } else {
      setSearchRecord(value);
    }
  };

  const toSearchCaseStudiesData = async (e) => {
    if (searchRecord !== '') {
      if (e.key === 'Enter') {
        if (inputRef.current) {
          const value = inputRef.current.value;
          let filterData = await searchCaseStudies(value);
          if (filterData.length > 0) {
            setStudiesRecord(filterData);
            setNoDataFound(false);
          } else {
            console.log('No data');
            setNoDataFound(true);
          }
        }
      }
    }
  };

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start gap-16 max-w-full text-center text-21xl text-gray-200 font-gelasio mq750 mq1275 ${className}`}
    >
      <div className='self-stretch flex flex-col items-start justify-start max-w-full msq750 gap-8'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
          <h1 className='m-0 w-full relative text-black font-bold inline-block mq450 text-[40px] mq750'>
            <span>{`Case `}</span>
            <span className='text-mediumturquoise text-[#43BDC1]'>Studies</span>
          </h1>
        </div>
        <div className='self-stretch flex flex-col items-start justify-start gap-8 max-w-full text-left text-base text-white font-inter'>
          <div className='self-stretch flex flex-col items-start justify-start gap-6 max-w-full'>
            <TextField
              onChange={handleChangeSearchData}
              onKeyDown={toSearchCaseStudiesData}
              className='[border:none] bg-[transparent] self-stretch h-[50px] font-inter text-base text-darkgray'
              placeholder='Search your keyword'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <img
                    width='24px'
                    height='24px'
                    src='/caseStudies/landingPage/ictwotonesearch.svg'
                    alt='search icon'
                  />
                ),
                inputRef: inputRef,
              }}
              sx={{
                '& fieldset': { border: 'none' },
                '& .MuiInputBase-root': {
                  height: '50px',
                  backgroundColor: '#F5F5F5',
                  paddingLeft: '10px',
                  borderRadius: '5px',
                },
                '& .MuiInputBase-input': { paddingLeft: '14px', color: '#999' },
              }}
            />
            {noDataFound && (
              <div className='flex justify-center bg-[#ccc] text-[#000] alert alert-danger'>
                Data not found!
              </div>
            )}
            {/* card section */}
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-full self-stretch items-start justify-start'>
              {caseStudiesRecord?.slice(0, visibleItems).map((item, index) => (
                <a
                  key={index}
                  href={item.acf.visit_case_study}
                  className='flex-1 flex flex-col items-start justify-start gap-6 min-w-[270px] max-w-full no-underline'
                  style={{ textDecoration: 'none', position: 'relative', zIndex: '999' }}
                >
                  <div
                    className='self-stretch h-[400px] rounded-[5px] flex flex-col items-start justify-start pt-4 px-6 pb-4 box-border gap-6 max-w-full'
                    style={{
                      background: `url(${item?.case_study_bg_url}) no-repeat center center / cover`,
                      color: '#fff',
                    }}
                  >
                    <div className='flex-1 flex flex-col items-cennter justify-center pt-10 gap-2'>
                      <div
                        className='h-12 relative leading-[24px] font-medium inline-block z-[1]'
                        dangerouslySetInnerHTML={{
                          __html: item?.excerpt?.rendered,
                        }}
                      ></div>
                    </div>

                    <div className='flex flex-row items-start justify-start px-0  '>
                      <div className='relative leading-[24px] z-[1] font-normal overflow-hidden'>
                        {item?.acf?.website_link}
                      </div>
                    </div>

                    <div>
                      <div className='relative font-semibold inline-block min-w-[73px] z-[1] pb-2'>
                        Industry Type
                      </div>
                      <div className='border-whitesmoke-200 border-[1px] border-solid overflow-hidden items-start justify-start py-1 px-4 z-[1] text-sm'>
                        <div className='relative inline-block min-w-[74px] overflow-hidden'>
                          {item?.categories[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            {/* end card section */}
          </div>
        </div>
      </div>
      {caseStudiesRecord.length > visibleItems && (
        <div className='self-stretch bg-white flex flex-row items-start justify-center  px-6 py-2 box-border w-full z-[1] text-center text-sm text-darkslategray font-inter'>
          <Button
            onClick={handleLoadMore}
            className='px-6 py-2 bg-[#474747] case-study-btn'
          >
            <div className='h-[50px] w-[1140px] relative shadow-[0px_0px_30px_rgba(0,_0,_0,_0.08)] bg-white hidden max-w-full' />
            <div className='relative font-bold text-white inline-block min-w-[72px] z-[1]'>
              Load More
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

StoriesHeader.propTypes = {
  className: PropTypes.string,
};

export default StoriesHeader;
