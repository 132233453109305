import React, { useState } from 'react';
import CallSchedule from '../../common/CallSchedule';
import Contact from '../../assets/socialpng/contact-img.png';
import Alert from '../alert/alert';
import { Helmet } from 'react-helmet';

const GetInTouch = () => {
  return (
    <div className='bg-white xl:py-10 xl:pb-32 xsm:py-10' id='touchwithus'>
      <Helmet
        meta={[
          {
            name: 'Description',
            content:
              'If you have any questions. just fill in the contact form, and we will answer you shortly.',
          },
          {
            name: 'Keywords',
            content:
              'App Development, Web Development, Software Development, Dynamic Website Development, Responsive Web Development',
          },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: 'English' },
        ]}
        title='Get in touch – DEVtrust'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div id='get_sec' className='banner_sec bottom_form_sec'>
        <div className='container  mx-auto xl:py-10'>
          <div className='flex justify-between flex-wrap md:flex-nowrap items-center gap-10'>
            <div className='left_side'>
              <div id='get-in-touch'>
                <GetInTouchCopy />
              </div>
            </div>
            <div className='right_side'>
              <div className='mb-5'>
                <img
                  src={Contact}
                  alt='contact'
                  className='xl:relative md:relative xsm:relative xl:ml-auto xl:mr-auto w-[100%]'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert />
    </div>
  );
};

export default GetInTouch;

export const GetInTouchCopy = () => {
  const [showCalendly, setShowCalendly] = useState(false);

  const handleBookCall = () => setShowCalendly(true);
  const handleCancel = () => setShowCalendly(false);

  return (
    <div className=' w-full'>
      <CallSchedule
        showCalendly={showCalendly}
        handleBookCall={handleBookCall}
        handleCancel={handleCancel}
      />
    </div>
  );
};
