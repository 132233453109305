// Header.js
import React, { useState } from 'react';
import CallSchedule from '../../common/CallSchedule';
import './Header.css';

function Header() {
  const [showCalendly, setShowCalendly] = useState(false);

  const handleBookCall = () => setShowCalendly(true);
  const handleCancel = () => setShowCalendly(false);

  return (
    <div className='banner_sec px-16 mt-10'>
      <div className='landing_banner mx-auto'>
        <div className='flex flex-col lg:flex-row items-center md:gap-10 w-full'>
          <div>
            <div className='banner_content'>
              <h1 className='text-[36px] font-bold'>
                Hire{' '}
                <span className='text-[#43BDC1] text-[40px]'>
                  Expert
                  <br /> Development
                </span>{' '}
                Teams
              </h1>
              <p className='text-gray-700 mt-3'>
                Non enim eu excepteur cupidatat consectetur do ea est
                reprehenderit incididunt irure veniam cupidatat est non amet.
              </p>

              <div className='flex w-full ml-[-18px] mt-4'>
                <img
                  src='img/upwork-icon.png'
                  alt='upwork'
                  className='xl:max-w-[172px] lg:max-w-[130px] max-w-[115px]'
                />
                <img
                  src='img/clutch-icon.png'
                  alt='clutch'
                  className='xl:max-w-[172px] lg:max-w-[130px] max-w-[115px]'
                />
                <img
                  src='img/google-icon.png'
                  alt='google'
                  className='xl:max-w-[172px] lg:max-w-[130px] max-w-[115px]'
                />
              </div>
            </div>
          </div>
          <CallSchedule
            showCalendly={showCalendly}
            handleBookCall={handleBookCall}
            handleCancel={handleCancel}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
