import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <div
      className={`w-[1301px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[158px] max-w-full text-center text-45xl text-seagreen font-inter lg:gap-[79px] mq450:gap-5 mq750:gap-[39px] mq1050:flex-wrap ${className}`}
    >
      <div className='w-[364px] flex flex-col items-start justify-start pt-[231px] px-0 pb-0 box-border min-w-[364px] max-w-full mq450:pt-[150px] mq450:box-border mq450:min-w-full mq1050:flex-1'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[25px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[23px] pr-5'>
            <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
              Koach
            </h1>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[30px] text-xl text-dimgray-200'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
              <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
            </div>
            <div className='h-[60px] relative leading-[30px] inline-block mq450:text-base mq450:leading-[24px]'>
              <p className='m-0'>Empowering Lives Through Accessible</p>
              <p className='m-0'>Coaching with Devtrust</p>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Koach/mask-group@2x.png'
      />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
