import React from 'react';
import FeatureDividers from './FeatureDividers';
import PropTypes from 'prop-types';

const SolutionDetails = ({ className = '' }) => {
  return (
    <section
      className={`w-[1401px] flex flex-row items-start justify-center pt-0 px-5 pb-[17px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[953px] flex flex-col items-start justify-start gap-[54.5px] max-w-full mq750:gap-[27px]'>
        <div className='self-stretch flex flex-row items-start justify-end max-w-full'>
          <div className='flex flex-col items-start justify-start gap-[7px] max-w-full'>
            <div className='w-[513px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                Automated Booking System
              </div>
            </div>
            <div className='flex flex-row items-start justify-center gap-[47px] max-w-full text-lg mq450:gap-[23px] mq1050:flex-wrap'>
              <img
                className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/Top-Spin/booking.svg'
              />
              <div className='flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border max-w-full'>
                <div className='h-[90px] relative leading-[30px] inline-block'>
                  <p className='m-0'>
                    Developed a browser bot/extension using Python and Selenium
                    to automate the
                  </p>
                  <p className='m-0'>
                    process of logging in and booking Tennis and Golf Court
                    slots precisely at 12 am PST,
                  </p>
                  <p className='m-0'>8 days ahead of time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
        <FeatureDividers
          scheduledExecution='Scheduled Execution'
          scheduledExecution1='/caseStudies/Top-Spin/scheduled-execution.svg'
          scheduledTheBotToRunAutomatica='Scheduled the bot to run automatically at specific times aligned with the opening of the booking window, ensuring that users had the highest chance of securing their preferred slots without any manual intervention.'
        />
        <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
        <FeatureDividers
          propWidth='533px'
          scheduledExecution='Chrome Extension Integration'
          scheduledExecution1='/caseStudies/Top-Spin/chrome-extension-integration.svg'
          scheduledTheBotToRunAutomatica="Built a Chrome extension that integrated seamlessly with users' browsers, allowing them to set preferences and automate the booking process directly from their browser interface."
        />
        <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
        <FeatureDividers
          propWidth='415px'
          scheduledExecution='Robust Tech Stack'
          scheduledExecution1='/caseStudies/Top-Spin/robust-tech-stack.svg'
          scheduledTheBotToRunAutomatica='Utilized a robust tech stack including Django, Python, SQLite3 and Selenium for reliability, scalability, and performance in handling automated booking tasks effectively.'
        />
      </div>
    </section>
  );
};

SolutionDetails.propTypes = {
  className: PropTypes.string,
};

export default SolutionDetails;
