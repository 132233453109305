// components/ChallengeCard.js
import React from 'react';

const ChallengeCard = ({ number, title, description, boxClass }) => {
  return (
    <div className='col'>
      <div className='card desc_card'>
        <div className={`comm_box mb-3`}>
          <span style={{ backgroundColor: boxClass }}>{number}</span>
        </div>
        <div className='card-body'>
          <div className='text-[20px] font-medium'>{title}</div>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
