import React from 'react';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import FrameComponent3 from './components/FrameComponent3';
import FrameComponent4 from './components/FrameComponent4';
import FrameComponent5 from './components/FrameComponent5';

const CaseStudiesCoachability = () => {
  return (
    <div className='w-full relative font-Inter bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[85px] box-border gap-[145px] leading-[normal] tracking-[normal] mq450:gap-9 mq750:gap-[72px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Walead/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Walead/background.svg'
      />
      <img
        className='w-full h-[734px] absolute !m-[0] top-[1600px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Walead/workstepbg@2x.png'
      />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[3023px] right-[-187.4px]'
        alt=''
        src='/caseStudies/Walead/group-3051956.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start max-w-full'>
        <FrameComponent />
      </section>
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[2]'
        alt=''
        src='/caseStudies/Walead/gas.png'
        style={{ borderRadius: '45px' }}
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[90px] box-border max-w-full text-center text-[36px] font-inter mq450:pb-[58px] mq450:box-border'>
        <div className='md:w-[1012px] flex flex-col items-center justify-center gap-[19px] max-w-full'>
          <div className='self-stretch text-[#000] flex flex-row items-start justify-center py-0'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='h-[200px] relative font-normal text-[20px] text-[#000] leading-[40px]'>
            <p className='m-0'>
              Gas Money started as a home maintenance services provider,
              connecting homeowners with
            </p>
            <p className='m-0'>
              local students for a range of services including but not limited
              to lawn and garden care, moving
            </p>
            <p className='m-0'>
              and hauling, car washing and furniture assembling.
            </p>
            <p className='m-0'>
              Recognizing the need to scale up, they partnered with DEVtrust to
              develop a
            </p>
            <p className='m-0'>web and mobile app.</p>
          </div>
        </div>
      </section>
      <FrameComponent1 />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[5px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[750px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='font-semibold text-[36px] leanding-[43.57px] text-[#000]'>
              Solutions
            </h1>
          </div>
          <div className='text-[20px] font-normal leading-6 text-[#000]'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <FrameComponent2 />
      <FrameComponent3 />
      <FrameComponent4 />
      <FrameComponent5 />
      <div className='w-5 h-3 relative hidden z-[18]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesCoachability;
