/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import style from './database.module.css';
import icon from '../../assets/socialpng/database.png';
import mongodb from '../../assets/images/mongodb.png';
import mysql from '../../assets/images/mySql.png';
import postsql from '../../assets/images/postsql.png';
import Uihovercard from '../../atoms/card/Uihovercard';
import DatabaseCard from './databaseCard/DatabaseCard';
import { Link } from 'react-router-dom';

const Database = () => {
  const cardItems1 = useMemo(() => {
    return imga.map((item) => (
      <Uihovercard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);
  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='Database'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='lg:max-w-7xl xl:px-20 md:px-6 xsm:px-4 mx-auto mb-20'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#000] font-bold leading-[50.78px] font-Gelasio'>
              <span className='text-[#43BDC1]'>Database Analysis,</span>
              <br /> Development, & Maintenance
            </h1>
            <p className='text-lg xl:w-[400px] w-auto my-5  font-normal leading-[30px] py-2 font-[Inter]'>
              {`Our comprehensive range of database services is designed to meet your business's unique challenges, whether you need structured SQL databases, NoSQL solutions, or cutting-edge multi-model databases.`}
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get In Touch
                </span>
              </Link>
            </div>
          </div>

          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
      </div>

      <div className={style.backend}>
        <div className='container mx-auto'>
          <h1 className='text-[40px] text-center text-[#000] font-bold leading-[50.78px] font-Gelasio'>
            <span className='text-[#43BDC1]'>Database </span>Tools
          </h1>
          <p className='text-lg m-auto text-center font-normal leading-[30px] py-2 font-[Inter] mb-8'>
            Encompasses various database management systems that facilitate data
            storage, retrieval, and management, each offering unique features to
            cater to different application needs.
          </p>
          <div className='m-auto flex flex-col gap-7 md:flex-row'>
            <div className='font-[Inter] grid xl:grid-cols-3 xsm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-5 my-3 items-center justify-center '>
              {cardItems1}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto'>
          <h1 className='text-[40px] text-center text-[#000] font-bold leading-[50.78px] font-Gelasio'>
            <span className='text-[#43BDC1]'>Database </span>Process
          </h1>
          <p className='text-lg m-auto text-center font-normal leading-[30px] py-2 font-[Inter]'>
            Encompasses all stages of database management, from initial planning
            and design to ongoing maintenance, ensuring efficient data handling
            and system performance.
          </p>
        </div>
        <DatabaseCard />
      </div>
    </div>
  );
};
export default Database;

const imga = [
  {
    id: 1,
    img: mongodb,
    description:
      'MongoDB is a popular open-source NoSQL database management system that falls under the category of document-oriented databases. It is designed to handle unstructured or semi-structured data and is particularly well-suited for use in web applications.',
  },
  {
    id: 2,
    img: mysql,
    description:
      'MySQL is a popular open-source relational database management system (RDBMS). It is widely used for building scalable and reliable web applications and is known for its speed, reliability, and ease of use.',
  },
  {
    id: 3,
    img: postsql,
    description:
      'PostgreSQL, often referred to as "Postgres," is a powerful open-source relational database management system (RDBMS) known for its extensibility, standards compliance, and robust feature sets like ACID Compliance and FDW.',
  },
];
