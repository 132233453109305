import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Loader from '../../shared/loader/loader';

import Header from '../../shared/Header/Header';
import CardHeader from '../../shared/cardHeader/CardHeader';
import Summary from '../../shared/summry/Summary';
import ServicesPortfolio from '../../shared/servicesPortfolio/ServicesPortfolio';
import Clients from '../../shared/clients/Clients';
import LatestBlog from '../../shared/latestBlog/LatestBlog';
import EngagementModal from '../../shared/engagementModal/EngagementModal';
import Technologies from '../../shared/technology/Technologies';
import Customer from '../../shared/customer/Customer';
import Contact from '../../assets/socialpng/contact-img.png';
import { GetInTouchCopy } from '../../shared/contact/GetInTouch';

function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToGetInTouch) {
      const getInTouchElement = document.getElementById('get-in-touch');
      if (getInTouchElement) {
        getInTouchElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Suspense fallback={<Loader status={true} />}>
      <div className='overflow-hidden select-none'>
        <Helmet
          data-testid='homes'
          meta={[
            {
              name: 'title',
              content:
                'Hire Dedicated Developers | Top Software Development Agency - DEVtrust',
            },
            {
              name: 'description',
              content:
                'Looking to hire dedicated developers? DEVtrust is a leading software development agency offering expert developers for web, mobile app, and custom software solutions.',
            },
            {
              name: 'keywords',
              content:
                'hire dedicated developers, software development agency, dedicated software developers, web development agency',
            },
            { name: 'robots', content: 'index, follow' },
            { name: 'language', content: 'English' },
          ]}
          title='DEVtrust'
          link={[{ rel: 'preconnect', href: '#' }]}
        />
        <Header />
        <CardHeader />
        <ServicesPortfolio />
        <Technologies />
        <Summary />
        <EngagementModal />
        <Clients />
        <Customer />
        <LatestBlog />

        <div id='get_sec' className='banner_sec bottom_form_sec'>
          <div className='container  mx-auto xl:py-16'>
            <div className='flex justify-between flex-wrap md:flex-nowrap items-center gap-10'>
              <div className='left_side'>
                <div id='get-in-touch'>
                  <GetInTouchCopy />
                </div>
              </div>
              <div className='right_side'>
                <div className='mb-5'>
                  <img
                    src={Contact}
                    alt='contact'
                    className='xl:relative md:relative xsm:relative xl:ml-auto xl:mr-auto w-[100%]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default Home;
