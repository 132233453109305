import React from 'react';
import './database.css';
import img from '../../../assets/socialsvg/img.svg';
import img01 from '../../../assets/socialsvg/img_02.svg';
import img02 from '../../../assets/socialsvg/img_03.svg';
import img03 from '../../../assets/socialsvg/img_04.svg';
import img04 from '../../../assets/socialsvg/img_05.svg';

const DatabaseCard = () => {
  return (
    <div>
      <section className='database_sec my-5'>
        <div className='container mx-auto'>
          <div className='database_body'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2'>
              <div>
                <div className='product_parrent first_col'>
                  <div className='product_box mb-3'>
                    <span>
                      <img
                        className='min-w-[80px] h-[80px] m-auto mb-3'
                        src={img01}
                        alt={img03}
                      />
                    </span>
                    <h5 className='text-[18px] leading-[25px] font-bold font-Inter text-[#5A5A5A] mb-3'>
                      Database Maintenance and Monitoring
                    </h5>
                    <p className='text-[14px] leading-[22px] font-Inter text-[#5A5A5A]'>
                      Database maintenance and monitoring are critical aspects
                      of ensuring the ongoing performance, reliability, and
                      security of a database system.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className='product_parrent second_col'>
                  <div className='product_box mb-3'>
                    <span>
                      <img
                        className='min-w-[80px] h-[80px] m-auto mb-3'
                        src={img}
                        alt={img03}
                      />
                    </span>
                    <h5 className='text-[18px] leading-[25px] font-bold font-Inter text-[#5A5A5A] mb-3'>
                      Database Planning
                    </h5>
                    <p className='text-[14px] leading-[22px] font-Inter text-[#5A5A5A]'>
                      Involves careful consideration and decision-making to
                      ensure that the database meets the requirements of the
                      organization and the applications that will interact with
                      it.
                    </p>
                  </div>
                  <div className='product_box mb-3'>
                    <span>
                      <img
                        className='min-w-[80px] h-[80px] m-auto mb-3'
                        src={img02}
                        alt={img03}
                      />
                    </span>
                    <h5 className='text-[18px] leading-[25px] font-bold font-Inter text-[#5A5A5A] mb-3'>
                      Data Manipulation and Querying
                    </h5>
                    <p className='text-[14px] leading-[22px] font-Inter text-[#5A5A5A]'>
                      Data manipulation and querying operations involve
                      retrieving, updating, inserting, and deleting data from a
                      database using SQL (Structured Query Language) or other
                      query languages.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className='product_parrent'>
                  <div className='product_box mb-3'>
                    <span>
                      <img
                        className='min-w-[80px] h-[80px] m-auto mb-3'
                        src={img03}
                        alt={img03}
                      />
                    </span>
                    <h5 className='text-[18px] leading-[25px] font-bold font-Inter text-[#5A5A5A] mb-3'>
                      Database Design
                    </h5>
                    <p className='text-[14px] leading-[22px] font-Inter text-[#5A5A5A]'>
                      It involves the process of defining the structure that
                      will store and manage data in a relational database.
                    </p>
                  </div>

                  <div className='product_box mb-3'>
                    <span>
                      <img
                        className='min-w-[80px] h-[80px] m-auto mb-3'
                        src={img04}
                        alt={img03}
                      />
                    </span>
                    <h5 className='text-[18px] leading-[25px] font-bold font-Inter text-[#5A5A5A] mb-3'>
                      Implementation
                    </h5>
                    <p className='text-[14px] leading-[22px] font-Inter text-[#5A5A5A]'>
                      It refers to the process of creating and setting up a
                      database based on the design and requirements established
                      during the planning and design phases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DatabaseCard;
