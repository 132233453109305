import React, { useState, useMemo } from 'react';
import FifthStep from '../../shared/multistepform/FifthStep';
import FirstStep from '../../shared/multistepform/FirstStep';
import FourthStep from '../../shared/multistepform/FourthStep';
import SecondStep from '../../shared/multistepform/SecondStep';
import ThirdStep from '../../shared/multistepform/ThirdStep';
import UserDetails from '../../shared/multistepform/UserDetails';
import Success from '../../atoms/success/Success';

const GetStarted = () => {
  const [step, setStep] = useState(1);
  const [heading] = useState('');
  const [user, setUser] = useState({
    fullname: '',
    company: '',
    email: '',
    needs: '',
    message: '',
    file: [],
    timeframe: '',
    duration: '',
    skillneed: '',
  });
  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = (value) => {
    if (value === undefined || value === 'step') {
      setStep(step - 1);
      return;
    }
    if (step >= value) {
      setStep(value);
    }
  };

  const formdata = (event) => {
    let { value, name } = event.target;
    setUser({ ...user, [name]: value });
  };
  const formPart = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            steps={step}
            heading={heading}
            nextStep={nextStep}
            formdata={formdata}
            fvalue={user}
          />
        );
      case 2:
        return (
          <SecondStep
            steps={step}
            heading={heading}
            nextStep={nextStep}
            prevStep={prevStep}
            fvalue={user}
          />
        );
      case 3:
        return (
          <ThirdStep
            steps={step}
            heading={heading}
            nextStep={nextStep}
            prevStep={prevStep}
            fvalue={user}
          />
        );
      case 4:
        return (
          <FourthStep
            steps={step}
            heading={heading}
            nextStep={nextStep}
            prevStep={prevStep}
            fvalue={user}
          />
        );
      case 5:
        return (
          <FifthStep
            nextStep={nextStep}
            heading={heading}
            prevStep={prevStep}
            formdata={formdata}
            fvalue={user}
            steps={step}
          />
        );
      case 6:
        return (
          <UserDetails
            nextStep={nextStep}
            steps={step}
            prevStep={prevStep}
            fvalue={user}
          />
        );
      case 7:
        return <Success />;
      default:
        break;
    }
  }, [step, user]);
  return <div>{formPart}</div>;
};

export default GetStarted;
