import React from 'react';
import { Button } from '@mui/material';

const FrameComponent5 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[141px] box-border max-w-full text-center text-13xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[822px] flex flex-col items-end justify-start gap-[37px] max-w-full mq450:gap-[18px]'>
        <div className='flex flex-col items-end justify-start gap-3.5'>
          <h2 className='m-0 relative text-inherit font-semibold font-inherit mq450:text-lgi mq1050:text-7xl'>
            Partner with DEVtrust
          </h2>
          <div className='flex flex-row items-start justify-end py-0 pl-[61px] pr-[60px] text-xl text-dimgray-200 mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
            <div className='w-[701px] relative inline-block mq450:text-base'>
              {`Contact us for more information or to `}
              <span>schedule a meeting</span> with our experts
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
          <Button
            className='h-[42px] w-[143px]'
            disableElevation
            variant='contained'
            sx={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '18',
              background: '#474747',
              borderRadius: '0px 0px 0px 0px',
              '&:hover': { background: '#474747' },
              width: 143,
              height: 42,
            }}
          >
            Contact us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent5;
