import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter self-stretch flex flex-row items-end justify-center pt-0 px-0 pb-2.5 box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0 object-cover lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/Krugg/rectangle-3422@2x.png'
          style={{ borderTopRightRadius: '45px' }}
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start min-w-[617px] max-w-full mq750:min-w-full'>
        <div className='self-stretch [background:linear-gradient(135deg,_#0c83a2,_#01cabb)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-[37px] box-border max-w-full gap-5 z-[1] mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
          <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#0c83a2,_#01cabb)] hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
            <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-inherit mq450:text-10xl mq1050:text-19xl text-[36px]'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <p className='m-0 relative  leading-[35px] font-medium font-inherit z-[2] mq450:text-lgi mq450:leading-[28px] text-[#fff] text-[16px]'>
              The solutions delivered impactful outcomes
            </p>
          </div>
          <div className='h-[106px] w-[70px] flex flex-col items-start justify-start'>
            <img
              className='self-stretch h-[45.2px] relative max-w-full overflow-hidden shrink-0 object-cover z-[2]'
              alt=''
              src='/caseStudies/Krugg/blklogo-1-1-1@2x.png'
            />
          </div>
        </div>
        <div
          className='self-stretch bg-[#111616] flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[206px] box-border gap-[55px] max-w-full z-[2] text-xl mq450:gap-[27px] mq750:pt-[49px] mq750:pb-[134px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'
          style={{ borderBottomLeftRadius: '45PX' }}
        >
          <img
            className='w-[950px] h-[592px] relative hidden max-w-full'
            alt=''
            src='/caseStudies/Krugg/rectangle-3424.svg'
          />
          <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
              <img
                className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Krugg/bxuparrow@2x.png'
              />
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
              <h3 className='m-0 relative text-inherit font-bold font-inherit z-[3] mq450:text-base'>
                Enhanced Customer Experience
              </h3>
              <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
                Increased customer retention by 25% due to reliable free
                shipping options with real-time tracking and a 15% rise in
                customer satisfaction scores.
              </div>
            </div>
          </div>
          <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
              <img
                className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                alt=''
                src='/caseStudies/Krugg/bxuparrow@2x.png'
              />
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
              <h3 className='m-0 relative text-inherit font-bold font-inherit z-[3] mq450:text-base'>
                Business Growth
              </h3>
              <div className='relative text-lg leading-[30px] z-[3]'>
                <p className='m-0'>
                  Improved operational efficiencies and streamlined processes
                  led to a 30% increase in overall B2B e-commerce revenue.
                </p>
                <p className='m-0'>&nbsp;</p>
                <p className='m-0'>
                  Overheads reduced by 25% through QuickBooks integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
