import React from 'react';
import AcademyInfo from './components/AcademyInfo';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import FrameComponent3 from './components/FrameComponent3';
import FrameComponent4 from './components/FrameComponent4';
import FrameComponent5 from './components/FrameComponent5';

const CaseStudiesRankUpAcademy = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[84px] box-border gap-[145px] leading-[normal] tracking-[normal] mq750:gap-[72px] mq450:gap-9'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/RankUpAcadmey/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/RankUpAcadmey/background.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start max-w-full'>
        <AcademyInfo />
      </section>
      <img
        className='w-full h-[889px] absolute !m-[0] top-[1466px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/RankUpAcadmey/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1288px] right-[-48px] rounded-26xl object-cover z-[1]'
        alt=''
        src='/caseStudies/RankUpAcadmey/rectangle-3416@2x.png'
      />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2891px] right-[-187.4px]'
        alt=''
        src='/caseStudies/RankUpAcadmey/group-3051956.svg'
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[87px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            Rank Up Academy needed a dynamic dashboard system tailored for
            different user roles within a sales organization. The system
            required real-time updates on sales, payments, and commissions,
            integrating seamlessly with Stripe for payment processing. The goal
            was to provide a comprehensive and user-friendly platform to manage
            sales data and financial metrics for various user roles including
            Admin, Setter Manager, Closer Manager, Setter, and Closer.
          </div>
        </div>
      </section>
      <FrameComponent1 />
      <FrameComponent2 />
      <FrameComponent3 />
      <FrameComponent4 />
      <FrameComponent5 />
      <div className='w-5 h-3 relative hidden z-[16]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesRankUpAcademy;
