import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './client.css';
import ClientImage from '../../atoms/clientimage/ClientImage';
import { getBusinessCase } from '../../utils';

const Clients = () => {
  const [businessdata, setBusinessdata] = useState([]);
  const getdata = async () => {
    let data = await getBusinessCase();
    setBusinessdata(data);
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <div className='max-w-7xl lg:px-24 md:px-6  xsm:px-4 xl:mx-auto'>
      <div className='  xsm:m-0 flex justify-between items-start xl:flex-nowrap md:flex-nowrap gap-x-5 xl:gap-x-20 flex-wrap xl:py-3 xsm:py-0'>
        <div className='xl:basis-1/3 md:basis-1/4 basis-none w-full block xl:py-4 xl:px-0 xl-px-5 md:pl-0 md:pr-0 xsm:py-9 bg-white md:bg-transparent xl:bg-transparent'>
          <h1 className='font-bold lg:text-[40px] xsm:text-[28px] md:leading-[36px] md:text-center xsm:text-center xl:text-start xl:mt-4 xsm:mt-0'>
            <span className=' font-Gelasio leading-[63.48px] md:leading-[20px]'>
              Our
            </span>{' '}
            <span className='leading-[66.65px] md:leading-[20px] text-custom-green'>
              Clients
            </span>
          </h1>
          <p className='text-lg md:text-[14px] xl:text-start xsm:text-center	font-normal leading-[30px] md:leading-[20px] font-[Inter]	'>
            Our reputation is built on creating great outcomes for clients.
          </p>
        </div>
        <div className='min-w-0 min-h-0 xl:basis-2/3 md:basis-3/4 sm:basis-2/3 xsm:basis-full px-6 '>
          <Slider {...settings}>
            {businessdata?.map((item) => (
              <ClientImage key={item.id} data={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: '#43BDC1 !important' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: 'green' }}
      onClick={onClick}
    />
  );
}
const settings = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  slidesPerRow: 2,
  speed: 3000,
  autoplay: true,
  pauseOnHover: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        slidesPerRow: 1,
        infinite: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        infinite: true,
        autoplay: true,
      },
    },
  ],
};
export default Clients;
