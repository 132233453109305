import React from 'react';
import styles from './Cards.module.css';

const Uihovercard = (props) => {
  const { img, title, id, description } = props;
  return (
    <div className='mb-[-17px] p-0'>
      <div
        className={`${styles['main-card']} shadow-lg group container `}
        key={id}
      >
        <div
          className={`flex items-center ${styles.customWidth} rounded-b-md absolute opacity-0 group-hover:opacity-100 duration-[1000ms] transition-all`}
        >
          <p className={`${styles.description} sm:m-5`}>{description}</p>
        </div>
        <div className='flex flex-col justify-center text-center items-center group-hover:opacity-0 duration-[1000ms]'>
          <div className='flex justify-center'>
            <img src={img} className='object-contain ' />
          </div>
        </div>
      </div>
      <p style={{ paddingLeft: '5.25rem' }} className={`${styles.title}`}>
        {title}
      </p>
    </div>
  );
};

export default Uihovercard;
