import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/Coachabilty/frame1.svg';
import logo from '../../../assets/Coachabilty/group-1000001292.svg';
import benefitLogo from '../../../assets/Coachabilty/group-1000001292.svg';
import rectangle from '../../../assets/Coachabilty/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets//Coachabilty/icon1.svg';
import icon2 from '../../../assets/Coachabilty/icon2.png';
import icon3 from '../../../assets/Coachabilty/icon3.svg';
import icon4 from '../../../assets/Coachabilty/icon4.svg';
import icon5 from '../../../assets/Coachabilty/icon5.svg';
import interfaceImg from '../../../assets/Coachabilty/interface.svg';

import benefitIcon from '../../../assets/Coachabilty/bxuparrow@2x.png';
import benefitImage from '../../../assets/Coachabilty/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Marketplace',
    description:
      'Defining and implementing features that set the coaching marketplace apart from competitors.',
    boxClass: '#2C5197',
  },
  {
    number: 2,
    title: 'Integration and Interoperability',
    description:
      'Integrating multiple APIs to enhance platform functionality, including scheduling, payment processing, and user analytics.',
    boxClass: '#4E75C0',
  },
  {
    number: 3,
    title: 'Scaling Operations',
    description:
      'Developing and scaling backend infrastructure to accommodate increased traffic and support future growth effectively.',
    boxClass: '#7CA2EC',
  },
];

const solutionData = [
  {
    title: 'Efficient Integration with Map box',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'Successfully implemented **Mapbox** for precise location-based search.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Efficient Integration with Stripe',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: [
      'Integrated **Stripe** for secure and fast online payments.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Tailored Marketplace Functionality with Share tribe',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Customized the Sharetribe platform to meet marketplace needs for coachingservices, ensuring flexibility and ease of use for coaches and clients.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Streamlined Workflow Automation via Zapier',
    imageSrc: icon4,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Integrated Zapier to automate administrative tasks such as scheduling and notifications, optimizing operational efficiency and user experience.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Robust Backend Architecture and Security Measures',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Designed and implemented a scalable backend infrastructure to support growth and maintain high performance under varying user loads.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Improved Booking Process',
    description:
      'Users benefited from a streamlined booking process and secure payment options, resulting in a ',
    percentage: '30% ',
    rest: 'increase in bookings.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Automated Workflow',
    description:
      'Zapier integration reduced manual efforts, saving approximately',
    percentage: '40 hours ',
    rest: 'per week in administrative tasks.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Increased Revenue',
    description:
      'The scalable infrastructure and seamless payment system contributed to a ',
    percentage: '25% ',
    rest: 'revenue growth within the first year.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Customer Retention',
    description:
      'Enhanced service features and security measures boosted customer satisfaction, leading to a',
    percentage: '20% ',
    rest: 'increase in client retention rates.',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
  {
    title: 'Market Expansion',
    description:
      'Coachability Group expanded its reach across London and the UK, establishing a strong market presence in the coaching services industry.',
    percentage: '',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
];

const Coachability = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Coachability'
          textcolor='#2C5197'
          description=''
          image={frame1}
          How=''
          DEVtrust={''}
          highlightedText={
            'Find a qualified, experienced & verified coach for private training in your local area'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Coachability Group sought to create a scalable web marketplace connecting individuals in London and the UK with qualified football coaches. The platform required seamless integration with various applications, automated workflow tasks, and advanced features for coaching events. Coachability Group chose to partner with DEVtrust, recognized for its expertise in developing scalable and secure web marketplaces.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #FF5757, #2C5197 60.5%)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#243A63'}
        textColor={'#F95658'}
        gradient='linear-gradient(135deg, #FF5757, #2C5197 60.5%)'
      />
      <InterfaceSection
        heading='Coachability’s user-friendly interface'
        Description='Showcasing Key Features and Functional Workflows'
        interfaceImg={interfaceImg}
      />
    </div>
  );
};

export default Coachability;
