import React from 'react';
import * as DOMPurify from 'dompurify';
const ClientImage = ({ data }) => {
  return (
    <div
      className='p-3'
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(data?.content?.rendered),
      }}
    ></div>
  );
};
export default ClientImage;
