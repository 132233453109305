import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import style from '../blogdetails/BlogDetails.module.css';
import * as DOMPurify from 'dompurify';
const BlogDetails = () => {
  const [newdata, setNewdata] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.details) {
      localStorage.setItem(
        'blogdata',
        JSON.stringify(location?.state?.details)
      );
      setNewdata(location?.state?.details || undefined);
    }
  }, [location?.state?.details]);
  useEffect(() => {
    if (localStorage.getItem('blogdata')) {
      setNewdata(JSON.parse(localStorage.getItem('blogdata')));
    }
  }, [localStorage.getItem('blogdata')]);

  console.log('newdata', newdata?.title.rendered);
  return (
    <div className='py-28'>
      <div className={style.Container}>
        <div>
          {' '}
          <img
            className={style.Image}
            src={newdata?.feature_img_url ? newdata?.feature_img_url : ''}
          ></img>
          <h1
            className={style.Heading}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(newdata?.title.rendered),
            }}
          ></h1>
          <div
            className={style.para}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(newdata?.content.rendered),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
