import React from 'react';
import PropTypes from 'prop-types';
import styles from './FrameComponent4.module.css';

const FrameComponent4 = ({ className = '' }) => {
  return (
    <section
      className={[styles.userInterfaceContentWrapper, className].join(' ')}
    >
      <div className={styles.userInterfaceContent}>
        <div className={styles.userInterfaceDescription}>
          <div className={styles.interfaceParagraph}>
            <h1 className={styles.theBountysUserFriendly}>
              The Bounty’s user-friendly interface
            </h1>
            <div className={styles.keyFeatures}>
              <div className={styles.keyFeaturesAnd}>
                Key Features and User Experience of Imagine Scholarship
              </div>
            </div>
          </div>
        </div>
        <img
          className={styles.userInterfaceContentChild}
          loading='lazy'
          alt=''
          src='/caseStudies/Imagine-Scholarship/group-1000001300@2x.png'
        />
      </div>
    </section>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent4;
