import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/draydex/frame1.svg';
import logo from '../../../assets/draydex/download-2-1@2x.png';
import benefitLogo from '../../../assets/draydex/download-2-1@2x.png';
import rectangle from '../../../assets/draydex/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/draydex/icon1.svg';
import icon2 from '../../../assets/draydex/icon2.svg';
import icon3 from '../../../assets/draydex/icon3.svg';
import icon4 from '../../../assets/draydex/icon4.svg';
import icon5 from '../../../assets/draydex/icon5.svg';
import productImg from '../../../assets/draydex/rectangle-3430@2x.png';
import interfaceImg from '../../../assets/draydex/interface.svg';
import clientlogo from '../../../assets/draydex/group-3051952@2x.png';
import benefitIcon from '../../../assets/draydex/bxuparrow@2x.png';
import benefitImage from '../../../assets/draydex/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';
import TestimonialSection from '../../../common/TestimonialSection';
import ProductAttributes from '../../../common/ProductAttributes';

const challengeCardsData = [
  {
    number: 1,
    title: 'Outdated Software',
    description:
      'The existing system was almost 20 years old, leading to inefficiencies and a cumbersome user experience.',
    boxClass: '#002863',
  },
  {
    number: 2,
    title: 'Data Migration',
    description:
      'A large dataset from the old system needed to be imported and understood. ',
    boxClass: '#003A8E',
  },
  {
    number: 3,
    title: 'Rate Management',
    description:
      'Lack of real-time data and historical tracking affected the accuracy and efficiency of rate transactions.',
    boxClass: '#0251C5',
  },
  {
    number: 4,
    title: 'Third-Party Integrations',
    description:
      'Difficulty in integrating with modern tools and services such as Mapbox and payment gateways.',
    boxClass: '#0364F1',
  },
];

const solutionData = [
  {
    title: "Instant 'Book Now' Technology",
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'Enabled instant drayage pricing, allowing users to get ',
      'immediate rate quotes.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Rate Management System',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: [
      'Developed a paid and free rate management system for easy rate comparison.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Real-Time and Historical Data',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Provided real-time data for rate transactions and integrated historical tracking',
      'for better decision-making.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Google Maps Integration',
    imageSrc: icon4,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Utilized integrated **Google Maps** for effective routing.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Third Party Integration',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Integrated **Mapbox** for accurate location information and a payment gateway',
      'for secure transactions.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Instant Pricing',
    description:
      'Reduced wait times, boosting transaction efficiency by approximately ',
    percentage: '35%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Efficient Rate Management',
    description: 'Simplified rate comparison, resulting in a  ',
    percentage: '25% ',
    rest: 'increase in user satisfaction.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Accurate Location and Secure Payments',
    description:
      'Integrated tools ensured precision and security, reducing payment errors by',
    percentage: '20%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Improved Reporting',
    description: 'Enhanced transaction tracking accuracy, leading to a',
    percentage: '30% ',
    rest: 'improvement in data reliability.',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
  {
    title: 'User Experience Enhancement',
    description: 'Optimized interface and performance, resulting in a',
    percentage: '40% ',
    rest: 'decrease in user complaints.',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
];

const productAttributes = {
  title: 'Product Attributes',
  attributes: [
    {
      title: 'Quote Listing and Bidding Flow',
      description:
        'Enhanced the process of listing and bidding on the platform, with brokers adding fees to the final bid price and customers choosing the most relevant quote.',
    },
    {
      title: 'Administrative Functions and Authorization Levels',
      description:
        'Implemented a super admin role with access to all listings and defined different authorization levels for various user roles.',
    },
    {
      title: 'Historical Data and Reporting',
      description:
        'Developed a reporting and dashboard feature for tracking processed listings and subscriptions, and collecting historical data for accurate bids and transaction tracking.',
    },
    {
      title: 'User Experience Enhancement',
      description:
        'Focused on revamping the user interface design, navigation, and overall system performance to ensure a more intuitive and efficient user experience.',
    },
  ],
  imageUrl: productImg,
};

const testimonials = [
  {
    description:
      'Working with DEVtrust was a game-changer for us. Their expertise in developing a modern rate management system not only streamlined our operations but also enhanced our competitive edge in the freight industry.',
    author: 'Bob Mayo',
    founder: 'COO - Freight Management, Inc. Founder & CEO - Draydex, LLC',
    clientlogo: clientlogo,
  },
  {
    description:
      'Working with DEVtrust was a game-changer for us. Their expertise in developing a modern rate management system not only streamlined our operations but also enhanced our competitive edge in the freight industry.',
    author: 'Bob Mayo',
    founder: 'COO - Freight Management, Inc. Founder & CEO - Draydex, LLC',
    clientlogo: clientlogo,
  },
];

const Draydex = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Draydex'
          textcolor='#00337F'
          description='Revolutionizing Rate Management 
          in the Freight Industry '
          image={frame1}
          How='How'
          DEVtrust={'DEVtrust'}
          highlightedText={
            ' Enabled Draydex to Enhance Rate Management and Improve User Experience '
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Bob Mayo, COO of Freight Management Inc. and Founder & CEO of Draydex, LLC, 
        sought to modernize the rate management system (RMS) for his 3PL brokerage company.
        Partnering with DEVtrust, Draydex developed an advanced digital platform that streamlined 
        rate management operations, improved real-time data access, and facilitated
        seamless integration with third-party tools.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Draydex faced several challenges with its legacy system'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #01409C, #002760)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <ProductAttributes productAttributes={productAttributes} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#3D577D'}
        textColor={'#FF9802'}
        gradient='linear-gradient(135deg, #01409C, #002760 60.5%)'
      />
      <InterfaceSection
        heading='Draydex’s user-friendly interface'
        Description='Visual insights into the Draydex platform and its transformative features'
        interfaceImg={interfaceImg}
      />
      <TestimonialSection testimonials={testimonials} />
    </div>
  );
};

export default Draydex;
