import React from 'react';
import styles from './Cards.module.css';
const Cards = (props) => {
  const { img, title, id, description } = props;
  return (
    <div className='mx-2'>
      <div
        className={`${styles['main-card']} h-[275px]  shadow-lg group container `}
        key={id}
      >
        <div
          className='p-2  flex items-center w-[240px] h-[275px] rounded-b-md absolute opacity-0 group-hover:opacity-100 duration-[1000ms]
transition-all '
        >
          <p className={styles.description}>{description}</p>
        </div>
        <div
          className='flex flex-col justify-center text-center items-center group-hover:opacity-0 duration-[1000ms]
transition-all '
        >
          <div className='w-[80px] h-[80px] flex justify-center'>
            <img src={img} className='object-contain' />
          </div>
          <p className={`${styles.title}`}>{title}</p>
        </div>
      </div>
    </div>
  );
};
export default Cards;
