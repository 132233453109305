import React from 'react';
import PropTypes from 'prop-types';

const Testimonial = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-3.5 box-border max-w-full text-center text-17xl text-[#000] font-inter ${className}`}
    >
      <div className='w-[1140px] flex flex-col items-start justify-start gap-[88px] max-w-full lg:gap-11 mq750:gap-[22px]'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='w-[826px] flex flex-col items-center justify-start gap-[23px] max-w-full text-[#000]'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[25px] pr-5'>
              <h1 className='m-0 relative text-inherit font-semibold font-inherit mq450:text-3xl mq1050:text-10xl text-[36px] '>
                The Krugg’s user-friendly interface
              </h1>
            </div>
            <p className='m-0 relative text-[20px] leading-[40px] font-normal font-inherit mq450:text-lg mq450:leading-[32px]'>
              Revolutionizing home improvement and decor through cutting-edge
              technology
            </p>
          </div>
        </div>
        <div className='self-stretch h-[942px] relative'>
          <img
            className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
            alt=''
            src='/caseStudies/Krugg/image-5@2x.png'
          />
          <img
            className='absolute top-[140px] left-[470px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
            loading='lazy'
            alt=''
            src='/caseStudies/Krugg/image-4@2x.png'
          />
          <img
            className='absolute top-[501px] left-[235px] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
            alt=''
            src='/caseStudies/Krugg/image-3@2x.png'
          />
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = {
  className: PropTypes.string,
};

export default Testimonial;
