import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../atoms/button/Button';
import { setAlert, setLoader } from '../../redux/Action/actions';
import Progressbar from '../../atoms/progress/Progressbar';
import { submitForm } from '../../utils';
const UserDetails = ({ fvalue, nextStep, prevStep, steps }) => {
  const dispatch = useDispatch();
  const handleRequest = async () => {
    dispatch(setLoader(true));
    let formdata = new FormData();
    formdata.append('fullname', fvalue?.fullname);
    formdata.append('company', fvalue?.company);
    fvalue?.file.forEach((item) => {
      formdata.append('file', item);
    });
    formdata.append('message', fvalue?.message);
    formdata.append('email', fvalue?.email);
    formdata.append('needs', fvalue?.needs);
    formdata.append('timeframe', fvalue?.timeframe);
    formdata.append('duration', fvalue?.duration);
    formdata.append('skillneed', fvalue?.skillneed);
    formdata.append('type', 'techtalent');
    let res = await submitForm(formdata);
    dispatch(setLoader(false));
    if (res.status === 200) {
      nextStep();
      return;
    }
    dispatch(setAlert({ type: 'danger', msg: 'Something Went Wrong.' }));
  };

  return (
    <div className='py-24'>
      <h1 className='text-center xl:text-[50px] text-[40px] font-Gelasio font-bold leading-[63.48px] xl:pt-5 pt-0 xl:pb-0 pb-5'>
        Summary
      </h1>
      <div className='flex justify-center py-5 pb-14 '>
        <Progressbar steps={steps} prevStep={prevStep} nextStep={nextStep} />
      </div>
      <div className='container mx-auto'>
        <div className='block mx-auto max-w-3xl bg-[#FCFCFC] p-5 md:px-20 px-5'>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>Name</h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.fullname}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Company
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.company}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Email
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.email}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Needs
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.needs}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Timeframe
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.timeframe}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 border-b-[1px] border-[#C4C4C4]'>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Duration
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-start'>
              {fvalue?.duration}
            </h1>
          </div>
          <div className='flex justify-between items-center py-8 '>
            <h1 className='font-Inter text-xl font-semibold leading-5'>
              Skills Needed
            </h1>
            <h1 className='font-Inter text-xl font-normal leading-5 text-end'>
              {fvalue?.skillneed}
            </h1>
          </div>
          <div className='flex justify-center xl:mt-14 mt-7 gap-5'>
            <button className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'>
              <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
              <span
                onClick={() => handleRequest()}
                className='relative group-hover:text-white text-lg font-semibold font-[Inter] leading-[21px]'
              >
                Send Request
              </span>
            </button>
          </div>
          <div className='flex justify-center py-3'>
            <Button prevStep={prevStep} nextStep={'last'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
