import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import icon4 from '../../../assets/Quant-Capital/frame1.svg';
import logo from '../../../assets/Quant-Capital/frame.svg';
import benefitLogo from '../../../assets/Quant-Capital/frame.svg';
import rectangle from '../../../assets/Quant-Capital/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/Quant-Capital/icon1.png';
import icon2 from '../../../assets/Quant-Capital/icon2.svg';
import icon3 from '../../../assets/Quant-Capital/icon3.svg';
import icon5 from '../../../assets/Quant-Capital/icon4.svg';
import icon6 from '../../../assets/Quant-Capital/icon5.svg';
import phoneimg from '../../../assets/Quant-Capital/Group 1000001284.svg';
import interfaceImg from '../../../assets/Quant-Capital/Group 1000001278.svg';

import benefitIcon from '../../../assets/Quant-Capital/bxuparrow@2x.png';
import benefitImage from '../../../assets/Quant-Capital/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Manual Deposits',
    description:
      'The funds were deposited and all the details were tracked using Excel sheets.',
    boxClass: '#2671FE',
  },
  {
    number: 2,
    title: 'Efficiency',
    description:
      'Tremendous overhead was generated due to a manual process that was hampering the efficiency.',
    boxClass: '#4F8CFF',
  },
  {
    number: 3,
    title: 'Massive Calculation',
    description:
      'Excel sheets were used in a cumbersome manner to calculate profit and commission.',
    boxClass: '#72A3FF',
  },
  {
    number: 4,
    title: 'Sloppy Tracking',
    description:
      'Inefficient tracking of deposits, depositors, commissions and profits.',
    boxClass: '#8FB6FF',
  },
];

const solutionData = [
  {
    title: 'Payment Processing',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      '**Stripe integration** to enable the users to deposit and withdraw funds using the Web and Mobile App.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Analytics',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: [
      'Online **web-based dashboard** to track all the transactions and other key metrics.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Personalized Depiction',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'The data stats can be viewed on a cumulative and per-user basis using **Apexchart**.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Streamlining Fund Calculation',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Automatic profit calculation and funds distribution using ACH as per the set schedule.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Referrals',
    imageSrc: icon6,
    imageAlt: 'Google Calendar Image',
    descriptions: ['Web-based referral tracking.'],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Fund Management',
    description:
      'End users can easily deposit and withdraw the funds through the Quant Capital tool.',
    // percentage: '45%',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Impressive Portfolio',
    description:
      'Better visibility of their investment portfolio, including profits and withdrawals, through web and mobile dashboards.',
    // percentage: '40%',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Time Management by Automation',
    description: 'Automation leads to reduced overheads, thus saving time.',
    // percentage: '60%',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
];

const Quant_Capital = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Quant Capital'
          textcolor='#222222'
          description='Algorithmic Hedge Fund'
          image={icon4}
          How=''
          DEVtrust={''}
          highlightedText={
            'A fintech platform that simplifies trading and access to the financial market for the end users.'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Quant Capital is a fintech platform that enables cumulative funds collected
        from multiple users to be invested using MetaTrader4 API.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #43CBFF, #9708CC)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#343C55'}
        textColor={''}
        gradient='linear-gradient(135deg, #2DE6FF, #255FFE, #E106FF)'
      />
      <InterfaceSection
        heading='Quant Capitals user-friendly Mobile App'
        Description='Quant Capital provides accessibility to the users to invest money on Metatrader4
      without taking the pain of market study.'
        interfaceImg={phoneimg}
      />
      <InterfaceSection
        heading='Quant Capital’s Admin Portal'
        Description=' '
        interfaceImg={interfaceImg}
      />
    </div>
  );
};

export default Quant_Capital;
