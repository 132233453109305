import React from 'react';
import SolutionFeatureContent from './SolutionFeatureContent';
import PropTypes from 'prop-types';

const SolutionFeatures = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[17px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[54.5px] max-w-full mq450:gap-[27px]'>
        <div className='w-[821px] flex flex-row items-start justify-end py-0 px-[26px] box-border max-w-full'>
          <div className='flex-1 flex flex-col items-start justify-start gap-[7px] max-w-full'>
            <div className='w-[464px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                User-Friendly Interface
              </div>
            </div>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[47px] max-w-full text-lg mq450:gap-[23px]'>
              <img
                className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/Planwell/userfriendly-interface.svg'
              />
              <div className='flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[430px] max-w-full mq750:min-w-full'>
                <div className='h-[90px] relative leading-[30px] inline-block'>
                  <p className='m-0'>
                    Planwell provides a user-friendly interface that simplifies
                    the input of financial
                  </p>
                  <p className='m-0'>
                    data and offers clear, actionable recommendations for
                    retirement planning,
                  </p>
                  <p className='m-0'>
                    home affordability, and college savings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <SolutionFeatureContent
          mobileCompatibility='Mobile Compatibility'
          mobileCompatibility1='/mobile-compatibility.svg'
          designedAsAWebBasedToolPlanw='Designed as a web-based tool, Planwell ensures accessibility across various devices, allowing users to manage their financial planning on-the-go without restrictions.'
        />
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        <SolutionFeatureContent
          propWidth='522px'
          mobileCompatibility="Advanced 'What-If' Analysis"
          mobileCompatibility1='/advanced-whatif-analysis.svg'
          designedAsAWebBasedToolPlanw="The tool incorporates advanced 'what-if' analysis features, enabling users to adjust variables such as income, savings, and retirement goals in real-time to instantly see the impact on their financial plans."
        />
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        <SolutionFeatureContent
          propWidth='548px'
          mobileCompatibility='Interactive Reports and Graphs'
          mobileCompatibility1='/interactive-reports-and-graphs.svg'
          designedAsAWebBasedToolPlanw="Planwell generates interactive graphs and PDF reports that visually depict savings progress, investment strategies, and future financial projections, enhancing users' understanding and decision-making process."
        />
      </div>
    </section>
  );
};

SolutionFeatures.propTypes = {
  className: PropTypes.string,
};

export default SolutionFeatures;
