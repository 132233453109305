import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import prev from '../../assets/images/prev.svg';
import * as DOMPurify from 'dompurify';
import { useLocation, useNavigate } from 'react-router-dom';
export default function PortfolioDetail() {
  const location = useLocation();
  let data = location?.state?.detail;
  const [newdata, setNewdata] = useState();

  const openTab = (url) => {
    window.open(url, '_blank');
  };
  function SampleNextArrow(propss) {
    const { className, stylew, onClick } = propss;
    return (
      <div
        className={className}
        style={{ ...stylew, display: 'block', color: '#43BDC1 !important' }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    if (data) {
      localStorage.setItem('mydatas', JSON.stringify(data));
      setNewdata(data);
    }
  }, [data]);
  useEffect(() => {
    if (localStorage.getItem('mydatas')) {
      setNewdata(JSON.parse(localStorage.getItem('mydatas')));
    }
  }, [localStorage.getItem('mydatas')]);
  function SamplePrevArrow(propss) {
    const { className, style, onClick } = propss;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', color: 'green' }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 0,
    speed: 2500,
    cssEase: 'linear',
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          slidesPerRow: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <div className='bg-white xl:py-28 overflow-hidden'>
      <div className=' container mx-auto '>
        <div className='pt-sm-auto text-end'>
          <button
            className='font-normal text-base text-[#25C7C7] font-Inter py-2  rounded inline-flex items-center'
            onClick={() => navigate(-1)}
          >
            <img className='w-[6px] h-[11px]' src={prev} alt='back' />
            <span className='pl-2'>Back</span>
          </button>
        </div>
      </div>
      <div className=' container mx-auto '>
        <div className=' w-full flex xl:flex-nowrap  lg-flex-nowrap lg:flex-nowrap flex-wrap  gap-12'>
          <div className=' w-full  h-auto'>
            <h1 className='xl:text-[30px] text-xl font-bold xl:leading-[50.78px] leading:[20px] font-Gelasio capitalize'>
              All About {newdata?.title?.rendered}
            </h1>

            <div className='flex justify-between xl:py-4 py-3 gap-x-4 border-b-2'>
              <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                Published Year
              </h1>
              <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'>
                {newdata?.acf?.published}
              </h1>
            </div>
            <div className='flex flex-col xl:flex-row md:flex-row gap-x-12 justify-between xl:py-4 py-3 border-b-2'>
              <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                Technology{' '}
              </h1>
              <div className='flex gap-x-3 flex-wrap'>
                {newdata?.acf?.technology?.split(',').map((item, index) => (
                  <h1
                    className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'
                    key={index}
                  >
                    {item}
                    {index < newdata?.acf?.technology?.split(',').length - 1 &&
                      ','}
                  </h1>
                ))}
              </div>
            </div>
            <div className='flex justify-between xl:py-4 py-3'>
              <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                Industry
              </h1>
              <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'>
                {newdata?.acf?.indusdivy}
              </h1>
            </div>
          </div>

          <div className='shadow-lg xl:p-7 p-3  xl:h-[300px] xl:w-[559px]	 w-full h-full '>
            <h3 className='text-xl  font-semibold font-[Inter] leading-5'>
              {newdata?.title?.rendered}
            </h3>
            <div
              className='xl:text-base text-sm font-normal truncateseven leading-[30px] text-[#828282] xl:w-[483px] w-auto my-3 font-[Inter]'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newdata?.content?.rendered),
              }}
            ></div>
            <div className='flex justify-start xl:mt-6 mt-2'>
              <button
                onClick={() => {
                  openTab(newdata?.acf?.link);
                }}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block '
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21px]'>
                  View Live Site
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className='mt-5 hello'>
          <Slider {...settings}>
            {newdata?.acf?.images.split(',').map((item, index) => (
              <img className='' key={index} src={item}></img>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
