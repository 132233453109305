import React from 'react';

// ProductAttributes Component
const ProductAttributes = ({ productAttributes }) => {
  return (
    <div className='productAttributes font-Inter w-full flex flex-row items-center justify-center text-[#000] mt-0 pt-0 px-5 pb-[63px] box-border max-w-full'>
      {/* Content Section */}
      <div className='w-[90%] md:w-[70%] text-lg flex flex-col lg:flex-row justify-between items-center gap-5'>
        <div className='w-[576px] flex flex-col items-start justify-start gap-[50px] max-w-full mq750:gap-[25px]'>
          <h1 className='m-0 w-[322px] relative text-inherit font-semibold font-[inherit] inline-block max-w-full mq450:text-3xl mq1050:text-10xl text-3xl whitespace-nowrap'>
            {productAttributes.title}
          </h1>
          <div className='self-stretch flex flex-col items-start justify-start gap-[22px] max-w-full text-left text-lg'>
            {productAttributes.attributes.map((attribute, index) => (
              <div
                key={index}
                className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[17px] max-w-full'
              >
                <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                  <input
                    type='checkbox'
                    className="cursor-pointer appearance-none w-6 h-6 border-2 border-gray-300 rounded-full bg-white checked:bg-[#FF9802] checked:border-transparent flex items-center justify-center checked:before:content-['✔'] checked:before:text-white checked:before:text-sm checked:before:leading-none checked:before:flex checked:before:items-center checked:before:justify-center focus:ring-2"
                  />
                </div>
                <div className='flex flex-col items-start text-lg justify-start gap-1.5 max-w-[calc(100%_-_41px)]'>
                  <div className='relative leading-[30px] font-semibold'>
                    {attribute.title}
                  </div>
                  <div className='relative text-lg leading-[24px]'>
                    {attribute.description.split('\n').map((line, idx) => (
                      <p key={idx} className='m-0'>
                        {line}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Image Section */}
        <div className='w-[100%] md:w-[448px] h-[567px] pt-10 pb-10 md:p-0'>
          <img
            src={productAttributes.imageUrl}
            alt='Product'
            className='w-auto h-auto object-cover rounded-[20px] opacity-100' // Ensure image opacity is set to 100 to display it
          />
        </div>
      </div>
    </div>
  );
};

export default ProductAttributes;
