import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import icon4 from '../../../assets/skyward/mask-group@2x.png';
import logo from '../../../assets/skyward/logo-new@2x.png';
import benefitLogo from '../../../assets/skyward/logo-new@2x.png';
import rectangle from '../../../assets/skyward/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/skyward/icon1.svg';
import icon2 from '../../../assets/skyward/icon2.svg';
import icon3 from '../../../assets/skyward/icon3.svg';
import icon5 from '../../../assets/skyward/icon4.svg';
import interfaceImg from '../../../assets/skyward/group-1000001283@2x.png';
import clientlogo from '../../../assets/skyward/group-3051952@2x.png';
import benefitIcon from '../../../assets/skyward/bxuparrow@2x.png';
import benefitImage from '../../../assets/skyward/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';
import TestimonialSection from '../../../common/TestimonialSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Client Verification',
    description:
      'Verifying clients manually was time-consuming and allowed fake users to exploit the system.',
    boxClass: '#004F6B',
  },
  {
    number: 2,
    title: 'Payment Processing',
    description:
      'Collecting booking payments was cumbersome, affecting transaction efficiency.',
    boxClass: '#00749D',
  },
  {
    number: 3,
    title: 'Document Management',
    description:
      'Managing and organizing client documents required significant manual effort.',
    boxClass: '#0099CF',
  },
  {
    number: 4,
    title: 'Appointment Scheduling',
    description:
      'Coordinating property viewings with clients was inefficient, impacting agent productivity.',
    boxClass: '#00B9FB',
  },
];

const solutionData = [
  {
    title: 'Client Verification',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'Integrated **Plaid API** to ensure authentic client verification, significantly reducing fraudulent activities.',
      'Transunion: To gather information on credit history and activity to generate credit reports.',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Payment Processing',
    imageSrc: icon2,
    imageAlt: 'Stripe Payment Image',
    descriptions: ['Utilized **Stripe** for quick and secure online payment.'],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Document Management',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Developed a custom **PDF annotator** for efficient document editing.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Appointment Scheduling',
    imageSrc: icon5,
    imageAlt: 'Google Calendar Image',
    descriptions: [
      'Quick appointment booking with alerts, integrated with **Google Calendar**.',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Streamlined Client Onboarding',
    description:
      'Clients could easily sign up and apply for properties, reducing manual efforts by',
    percentage: '45%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Secure Transactions',
    description:
      'Stripe integration ensured secure payment processing, increasing user trust by',
    percentage: '40%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Efficient Document Management',
    description: 'The PDF editor improved document handling efficiency by',
    percentage: '60%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Enhanced Appointment Scheduling',
    description:
      'Notification system improved scheduling efficiency, enhancing agent productivity by',
    percentage: '25%',
    rest: '.',
    imageSrc: benefitIcon,
    imageAlt: 'Enhanced Appointment Scheduling Arrow',
  },
];

const testimonials = [
  {
    description:
      'Devtrust has completely transformed our real estate management process. Their solutions are intuitive and have significantly reduced our manual workload, allowing us to focus more on our clients.',
    author: 'JOSIAH HYATT',
    founder: 'Founder | Lic. R. E. Associate Broker',
    clientlogo: clientlogo,
  },
  {
    description:
      'Devtrust has completely transformed our real estate management process. Their solutions are intuitive and have significantly reduced our manual workload, allowing us to focus more on our clients.',
    author: 'JOSIAH HYATT',
    founder: 'Founder | Lic. R. E. Associate Broker',
    clientlogo: clientlogo,
  },
  {
    description:
      'Devtrust has completely transformed our real estate management process. Their solutions are intuitive and have significantly reduced our manual workload, allowing us to focus more on our clients.',
    author: 'JOSIAH HYATT',
    founder: 'Founder | Lic. R. E. Associate Broker',
    clientlogo: clientlogo,
  },
];

const SkywardPage = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Skyward'
          textcolor='#004F6B'
          description='Transforming Real Estate Management'
          image={icon4}
          How='How'
          DEVtrust={'DEVtrust '}
          highlightedText={
            'Enabled Skyward to Digitize Real Estate Transactions and Enhance Client Experience'
          }
        />
      </section>
      <IntroWrapper
        intro='Overview'
        decription='Skyward identified a significant gap in the real estate industry: the heavy reliance on manual processes for property transactions, leading to inefficiencies and client dissatisfaction. Partnering with DEVtrust, Skyward revolutionized real estate management by creating a digital platform that streamlined operations, improved client verification, and facilitated seamless transactions.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Skyward faced several challenges in the traditional real estate management approach:'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #90E2AC, #465EFB)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#343C55'}
        textColor={'#8EDFAE'}
        gradient='linear-gradient(135deg, #90E2AC, #465EFB)'
      />
      <InterfaceSection
        heading="Skyward's user-friendly interface"
        Description='Simplifies property exploration and transaction management'
        interfaceImg={interfaceImg}
      />
      <TestimonialSection testimonials={testimonials} />
    </div>
  );
};

export default SkywardPage;
