import {
  SET_PROJECT_DETAILS,
  SET_PROCESS,
  SET_SCREEN,
  GET_BLOG,
  SET_ALERT,
  SET_PORTFOLIO,
  SET_LOADER,
  SET_CATEGORY,
} from './ActionType';
import { getBlogs, getPortfolioBanner } from '../../utils/index';
export const setProjectsDetails = (projects) => (dispatch) => {
  dispatch({ payload: projects, type: SET_PROJECT_DETAILS });
};

export const setProcess = (process) => (dispatch) => {
  dispatch({ payload: process, type: SET_PROCESS });
};

export const setScreen = (screen) => (dispatch) => {
  dispatch({ payload: screen, type: SET_SCREEN });
};
export const getblog = () => async (dispatch) => {
  dispatch(setLoader(true));
  let blogdata = await getBlogs();
  dispatch(setLoader(false));
  dispatch({
    payload: blogdata,
    type: GET_BLOG,
  });
};

export const setAlert = (payload) => (dispatch) => {
  dispatch({
    payload: payload,
    type: SET_ALERT,
  });
};

export const setPortfolio = () => async (dispatch) => {
  dispatch(setLoader(true));
  let portfolios = await getPortfolioBanner();
  dispatch(setLoader(false));
  dispatch({
    payload: portfolios,
    type: SET_PORTFOLIO,
  });
};

export const setLoader = (status) => (dispatch) => {
  dispatch({ payload: status, type: SET_LOADER });
};

export const setBlogTypes = (value) => (dispatch) => {
  dispatch({ payload: value, type: SET_CATEGORY });
};
