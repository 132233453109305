import React from 'react';
import GroupComponent from './GroupComponent';
import PropTypes from 'prop-types';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex h-[1100px] flex-row items-start justify-center pt-0 px-0 pb-[5px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      {/* Image Container */}
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0 lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/Doctornal-Health/rectangle-3422@2x.png'
        />
      </div>

      {/* Text Container */}
      <div className='flex-1 flex flex-col items-end justify-start min-w-[617px] max-w-full mq750:min-w-full mt-auto'>
        <div className='self-stretch [background:linear-gradient(135deg,_#72EDF2,_#5151E5_60.5%)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq1050:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
          <div className='h-[252px] w-[950px] relative hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
            <h1 className='text-[48px] leading-[58.09px]'>
              <p className='m-0 font-semibold '>
                <b>{`Results `}</b>
              </p>
              <p className='m-0 font-normal'>Which we achieved</p>
            </h1>
            <div className='text-[24px] leading-[35px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='h-[108px] flex flex-col items-start justify-start'>
            <img
              className='w-[50px] h-[50px] relative z-[2]'
              alt=''
              src='/caseStudies/Contractor-ETA/group-1000001311-1.svg'
            />
          </div>
        </div>

        {/* Results List */}
        <div className='self-stretch rounded-t-none rounded-br-none rounded-bl-[45px] bg-[#333454] flex flex-row items-start justify-start py-[75px] px-[62px] box-border min-h-[590px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
          <div className='flex flex-col items-start justify-start gap-[55px] max-w-full z-[3] mq750:gap-[27px]'>
            {/* Item 1 */}
            <GroupComponent
              improvedBookingProcess='Increased Consultation Requests'
              usersBenefitedFromAStreamlined='Enabled patients to connect with licensed healthcare providers anytime, leading to a '
              prop='40% '
              increaseInBookings='increase in consultation requests.'
            />

            {/* Item 4 */}
            <GroupComponent
              improvedBookingProcess='Reduced Administrative Tasks'
              usersBenefitedFromAStreamlined='Streamlined appointment booking process resulted in a 50% reduction in booking-related'
              increaseInBookings='administrative tasks.'
            />
            <GroupComponent
              improvedBookingProcess='Improved Patient Satisfaction'
              usersBenefitedFromAStreamlined='Secure and seamless video consultations via WebRTC API improved patient 
              satisfaction'
              increaseInBookings='and trust.'
            />
            <GroupComponent
              improvedBookingProcess='Increased Repeat Consultations'
              usersBenefitedFromAStreamlined={
                <>
                  Achieved a{' '}
                  <span className='text-[#71E8F2] font-bold'>35% </span>{' '}
                  increase in repeat consultations due to the quality and
                  convenience{' '}
                </>
              }
              increaseInBookings='of the video chat feature.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
