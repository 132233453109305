import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const FeatureDividers = ({
  className = '',
  propWidth,
  scheduledExecution,
  scheduledExecution1,
  scheduledTheBotToRunAutomatica,
}) => {
  const frameDiv2Style = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div
      className={`self-stretch flex flex-row items-start justify-end py-0 px-[39px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[795px] flex flex-col items-start justify-start gap-[7px] max-w-full'>
        <div
          className='w-[441px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'
          style={frameDiv2Style}
        >
          <div className='relative font-semibold mq450:text-lg'>
            {scheduledExecution}
          </div>
        </div>
        <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
          <img
            className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
            loading='lazy'
            alt=''
            src={scheduledExecution1}
          />
          <div className='flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='self-stretch relative leading-[30px] z-[3]'>
              {scheduledTheBotToRunAutomatica}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeatureDividers.propTypes = {
  className: PropTypes.string,
  scheduledExecution: PropTypes.string,
  scheduledExecution1: PropTypes.string,
  scheduledTheBotToRunAutomatica: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
};

export default FeatureDividers;
