import React from 'react';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import SolutionDetails from './components/SolutionDetails';
import FrameComponent2 from './components/FrameComponent2';
import Testimonial from './components/Testimonial';
import FrameComponent3 from './components/FrameComponent3';

const CaseStudiesKrugg = () => {
  return (
    <div className=' font-Inter w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[85px] box-border gap-[135px] leading-[normal] tracking-[normal] mq450:gap-[34px] mq750:gap-[67px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Krugg/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Krugg/background.svg'
      />
      <img
        className='w-full h-[766px] absolute !m-[0] top-[1457px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Krugg/workstepbg@2x.png'
      />
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/Krugg/rectangle-3416@2x.png'
        style={{ borderRadius: '45px' }}
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-2.5 box-border max-w-full'>
        <FrameComponent />
      </section>
      <section className='font-Inter self-stretch flex flex-row items-start justify-center pt-0 pb-[100px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-[#000]  mq450:pb-[65px] mq450:box-border'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl text-[#000] text-4xl'>
              Introduction
            </h1>
          </div>
          <h3 className='m-0 self-stretch relative leading-[40px] font-normal  mq450:text-lg mq450:leading-[25px] text-[16px]'>
            <p className='m-0 leading-[25px] text-[16px]'>
              Krugg.us, a leading e-commerce platform for home improvement and
              decor, sought to enhance its digital infrastructure to better
              showcase its mirrors and cabinets, engage customers, and
              strengthen its online presence. To achieve these objectives,
              Krugg.us partnered with DEVtrust, a renowned digital solutions
              provider known for its expertise in e-commerce platform
              development.
            </p>
          </h3>
        </div>
      </section>
      <FrameComponent1 />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2872px] right-[-187.4px] z-[2]'
        alt=''
        src='/caseStudies/Krugg/group-3051956.svg'
      />
      <section className=' font-Inter self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-[#000]'>
        <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl text-4xl'>
              Solutions
            </h1>
          </div>
          <h3 className='m-0 self-stretch relative text-xl font-normal font-inherit mq450:text-base'>
            Devtrust implemented a comprehensive solution to address these
            challenges
          </h3>
        </div>
      </section>
      <SolutionDetails />
      <FrameComponent2 />
      <Testimonial />
      <FrameComponent3 />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesKrugg;
