import React from 'react';
import PropTypes from 'prop-types';
import icon1 from '../../../../assets/aimly/icon1.svg';
import icon2 from '../../../../assets/aimly/icon2.svg';
import icon3 from '../../../../assets/aimly/icon5.svg';
import icon4 from '../../../../assets/aimly/icon3.svg';
import icon5 from '../../../../assets/aimly/icon4.svg';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-row items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
        <div className='self-stretch w-[90%] flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
          {solutionData?.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start justify-start py-0 box-border max-w-full'
            >
              <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                  <img
                    className='w-full h-auto max-w-full overflow-hidden object-cover'
                    loading='lazy'
                    alt=''
                    src={item?.image}
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
                  <b className='font-semibold text-[20px] leading-[43.57px] text-[#000]'>
                    {item?.title}
                  </b>
                  <div className='text-[18px] leading-[30px] text-[#000]'>
                    <p className='m-0'>{item?.discription}</p>
                  </div>
                </div>
              </div>
              <div className='self-stretch mt-4'>
                <hr className='border-t-[1px] border-solid border-lightgray' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

const solutionData = [
  {
    id: 1,
    image: icon1,
    title: 'Single Platform',
    discription: `Alimac provided a platform to connect multiple onboarding, and payroll software 
    through API integrations and transfer employees' data from onboarding to payroll 
    systems (iSolved, Crunchtime, Altametrics) through automated processes.`,
  },
  {
    id: 2,
    image: icon2,
    title: 'Automated Salary Calculation',
    discription: `The application pulls data from punch recording software (like 7shift) and posts 
    it on the payroll system to calculate the salary and then transfer it to the 
    employee's bank account.`,
  },
  {
    id: 3,
    image: icon3,
    title: 'Paystub',
    discription: `Employees receive automated paystubs one day before the paycheck date. Each 
    company has a different paycheck period based on that, each employee gets 
    their paystub report as per their pay period weekly, bi-weekly, and monthly. 
    Boldesk API integration to read, create, edit, and delete service requests.`,
  },
  {
    id: 4,
    image: icon4,
    title: 'Automated Hire and Termination',
    discription: `The system automated employee termination and hiring.`,
  },
  {
    id: 5,
    image: icon5,
    title: 'Reports & Analytics',
    discription: `Businesses can view multiple reports with graphs and charts`,
  },
];

export default FrameComponent2;
