import React from 'react';
import style from './Modal.module.css';
import closeimg from '../../assets/images/close.webp';
import * as DOMPurify from 'dompurify';

import Slider from 'react-slick';

export default function Modal(props) {
  const { showModal, hideModal, data } = props;
  const openTab = (url) => {
    window.open(url, '_blank');
  };
  function SampleNextArrow(propss) {
    const { className, stylew, onClick } = propss;
    return (
      <div
        className={className}
        style={{ ...stylew, display: 'block', color: '#43BDC1 !important' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(propss) {
    const { className, styles, onClick } = propss;
    return (
      <div
        className={className}
        style={{ ...styles, display: 'block', color: 'green' }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 0,
    speed: 2500,
    cssEase: 'linear',
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
          slidesPerRow: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center  flex overflow-x-hidden overflow-y-hidden fixed  inset-0 z-50 outline-none focus:outline-none'>
            <div
              className='relative w-auto  mt-10 mx-4 max-w-7xl'
              data-aos='fade-down'
              data-aos-offset='100'
              data-aos-delay='100'
            >
              <div className={`${style.modalbg2} bg-[#FFF]`}>
                <div className=' p-2  text-end'>
                  <button
                    className=' ml-auto  border-0 text-black  text-3xl leading-none font-semibold'
                    onClick={() => hideModal()}
                  >
                    <img src={closeimg}></img>
                  </button>
                </div>

                <div className='relative p-2 py-0 pb-7  xl:px-32 px-0 w-full flex xl:flex-nowrap  lg-flex-nowrap  flex-wrap  gap-x-12'>
                  <div className=' w-full  h-auto'>
                    <h1 className='xl:text-[30px] text-xl font-bold xl:leading-[50.78px] leading:[20px] font-Gelasio capitalize'>
                      All About {data?.title?.rendered}
                    </h1>
                    <div className=''>
                      <div className='flex justify-between xl:py-4 py-3 gap-x-4 border-b-2'>
                        <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                          Published Year
                        </h1>
                        <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'>
                          {data?.acf?.published}
                        </h1>
                      </div>
                      <div className='flex flex-col xl:flex-row md:flex-row gap-x-12 justify-between xl:py-4 py-3 border-b-2'>
                        <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                          Technology{' '}
                        </h1>
                        <div className='flex gap-x-3 flex-wrap justify-end'>
                          {data?.acf?.technology
                            ?.split(',')
                            .map((item, index) => (
                              <h1
                                className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'
                                key={index}
                              >
                                {item}
                                {index <
                                  data?.acf?.technology?.split(',').length -
                                    1 && ','}
                              </h1>
                            ))}
                        </div>
                      </div>
                      <div className='flex justify-between xl:py-4 py-3'>
                        <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#000] font-[Inter]'>
                          Industry
                        </h1>
                        <h1 className='xl:text-xl text-sm font-semibold leading-[30px] text-[#808080] font-[Inter]'>
                          {data?.acf?.indusdivy}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className='shadow-lg xl:p-7 p-3  xl:h-[300px] xl:w-[559px]	 w-full h-full '>
                    <h3 className='text-xl  font-semibold font-[Inter] leading-5'>
                      {data?.title?.rendered}
                    </h3>
                    <div
                      className='xl:text-base text-sm font-normal truncateseven leading-[30px] text-[#828282] xl:w-[483px] w-auto my-3 font-[Inter]'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.content?.rendered),
                      }}
                    ></div>
                    <div className='flex justify-start xl:mt-6 mt-2'>
                      <button
                        onClick={() => {
                          openTab(data?.acf?.link);
                        }}
                        className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block '
                      >
                        <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                        <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21px]'>
                          View Live Site
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='mx-10 p-10 '>
                  <Slider {...settings}>
                    {data?.acf?.images.split(',').map((item, index) => (
                      <img
                        className='ml-5 max-w-[500px]'
                        key={index}
                        src={item}
                      ></img>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
}
