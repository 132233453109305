import React from 'react';

const Progressbar = ({ steps, prevStep }) => {
  return (
    <div className='w-1/2 '>
      <ol className='flex justify-center'>
        {[...Array(6)].map((item, index) => (
          <li
            onClick={() => {
              if (prevStep) {
                return prevStep(index + 1);
              } else {
                return null;
              }
            }}
            key={index}
            className={`relative w-[150px] text-center text-sm font-light italic
            before:content-[''] before:absolute before:left-[-50%] before:top-[calc(200%+0.5rem)]  ${
              index == 0 ? '' : 'before:w-full'
            } before:h-1 
            ${index + 1 <= steps ? 'before:bg-[#25C7C7]' : 'before:bg-gray-200'}
            after:content-[''] after:absolute after:left-[50%] after:top-[200%] after:w-5 after:h-5 
            ${
              index + 1 <= steps ? 'after:bg-[#25C7C7]' : 'after:bg-gray-200'
            } after:rounded-full after:z-10
    `}
          >
            {' '}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Progressbar;
