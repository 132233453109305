import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ContactSection = () => {
  const [isEnable, setIsEnable] = useState('block');
  const navigate = useNavigate();
  const location = useLocation();

  const handleContactUS = () => {
    navigate('/contactus', { state: { scrollToGetInTouch: true } });
  };

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName === '/contactus' || pathName === '/get-in-touch') {
      setIsEnable('none');
    } else {
      setIsEnable('block');
    }
  }, [location.pathname]);

  return (
    <div className='contact_top-spacing mt-20' style={{ display: isEnable }}>
      <section className='call-to-action-container-wrapper'>
        <div className='call-to-action-container'>
          <div className='call-to-action-content'>
            <h2 className='discover-how-devtrust'>
              Partner with <span className='text-custom-green'>DEVtrust</span>
            </h2>
            <div className='contact-prompt'>
              <div className='contact-us-for-container'>
                Contact us for more information or to{' '}
                <span className='schedule-a-meeting'>schedule a meeting</span>{' '}
                with our experts
              </div>
            </div>
          </div>
          <div className='contact-button-container'>
            <button className='contact-button-content'>
              <div onClick={handleContactUS} className='contact-us'>
                Contact Us
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactSection;
