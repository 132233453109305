/* eslint-disable react/jsx-key */
import React from 'react';
import { Helmet } from 'react-helmet';
import style from './carrier.module.css';
import carrierImg from '../../assets/socialsvg/carrier.svg';
import carriergroup from '../../assets/socialsvg/carrierImg.svg';
import medical from '../../assets/socialsvg/medical.svg';
import health from '../../assets/socialsvg/health.svg';
import growth from '../../assets/socialsvg/growth.svg';
import activites from '../../assets/socialsvg/activites.svg';
import arrow from '../../assets/socialsvg/arrow.svg';
import carrierWork from '../../assets/socialsvg/carrierWork.svg';
import JobCards from '../../atoms/jobCards/JobCards';
import OurValue from './ourValue/OurValue';
import OurCulture from './ourCulture/OurCulture';
import CarrierField from './carrierField/CarrierField';
import JobOption from '../../atoms/jobOption/JobOption';
import { Link } from 'react-router-dom';

const Carrier = () => {
  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='career'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto mb-20'>
        <div className='flex flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#000] font-bold leading-[50.78px] font-Gelasio'>
              A fantastic career &nbsp;
              <br />
              <span className='text-[#43BDC1]'>is in front</span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              Significant accomplishments often result from collaborative
              endeavors. If you possess enthusiasm for becoming part of an
              advanced team focused on the forefront of technological
              advancements, we encourage you to reach out to us.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get In Touch
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={carrierImg}></img>
          </div>
        </div>
      </div>

      <div className={style.backend}>
        <div className='container mx-auto'>
          <div className={style.child}>
            <div className='m-auto'>
              <img src={carriergroup} alt='img' />
            </div>

            <div className='p-4 m-auto mb-4'>
              <h1 className='text-[40px] text-[#43BDC1] font-bold leading-[50.78px] font-Gelasio'>
                Perks & Benefits &nbsp;
                <br />
                <span className='text-[#000]'>you will get</span>
              </h1>
              <p className='text-lg m-auto text-start font-normal leading-[30px] py-2 font-[Inter]'>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum incididunt ut
                labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className='flex flex-wrap justify-between'>
            {facility.map((item, index) => (
              <div key={index} className='w-full sm:w-auto'>
                <img
                  className='w-full min-w-[187.99px] max-h-[212.51px] mb-4'
                  src={item.img}
                  alt='img'
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto'>
          <h1 className='text-4xl md:text-[90px] text-[#000] font-bold leading-7 md:leading-[140px] font-Gelasio'>
            Let’s work &nbsp;
            <br />
            <span className='text-3xl md:text-[150px] leading-7 md:leading-[140px] text-[#43BDC1] font-Gelasio'>
              together
            </span>
            <span className='inline-flex px-5 align-middle'>
              <img src={arrow} alt={arrow} />
            </span>
          </h1>
          <div className={style.childTitle}>
            <div className='m-auto'>
              <h1 className='text-[40px] text-[#43BDC1] font-bold leading-[50.78px] font-Gelasio'>
                @DEVtrust &nbsp;
              </h1>
              <p className='italic text-lg m-auto text-start font-normal leading-[30px] py-2 font-[Inter]'>
                You will get cultivates teamwork, open communication, and mutual
                respect. It embraces diversity, encourages innovation, and
                prioritizes employee well-being. This supportive environment
                fosters creativity, loyalty, and high morale, ultimately
                contributing to increased productivity and the overall success
                of the organization.
              </p>
            </div>

            <div className='p-4 m-auto'>
              <img src={carrierWork} alt='img' />
            </div>
          </div>
        </div>
      </div>

      <div className={style.backendCarrier}>
        <div className='container mx-auto'>
          <h1 className='text-[40px] text-center text-[#000] font-bold leading-[50.78px] font-Gelasio'>
            Are you ready{' '}
            <span className='text-[#43BDC1]'>to join DEVtrust? </span>
          </h1>
          <p className='text-lg m-auto max-w-[937px] text-center font-normal leading-[30px] py-2 font-[Inter] mb-8'>
            Below are the open positions we have
          </p>

          <div className='flex flex-col xl:flex-row justify-between gap-2 '>
            <div className='sm:col-span-3 w-full'>
              <div className='mt-2'>
                <JobOption
                  id='country'
                  name='country'
                  autoComplete='country-name'
                  className='block w-full sm:min-w-[370px] min-h-[55px] border-0 ring-1 sm:text-sm sm:leading-6 bg-white text-[#000] font-Inter text-[16px] leading-[19.36px] font-normal'
                  defaultValue='Job Location'
                  options={['Lucknow, India', 'Gurgaon, India', 'Mexico']}
                />
              </div>
            </div>

            <div className='sm:col-span-3 w-full'>
              <div className='mt-2'>
                <JobOption
                  id='title'
                  name='title'
                  autoComplete='title'
                  className='block w-full sm:min-w-[370px] min-h-[55px] border-0 ring-1 sm:text-sm sm:leading-6 bg-white text-[#000] font-Inter text-[16px] leading-[19.36px] font-normal'
                  defaultValue='Job Title'
                  options={[
                    'Backend Developer',
                    'Frontend Developer',
                    'QA Developer',
                  ]}
                />
              </div>
            </div>
            <div className='sm:col-span-3 w-full'>
              <div className='mt-2'>
                <JobOption
                  id='exprince'
                  name='exprince'
                  autoComplete='exprince'
                  className='block w-full sm:min-w-[370px] min-h-[55px] border-0 ring-1 sm:text-sm sm:leading-6 bg-white text-[#000] font-Inter text-[16px] leading-[19.36px] font-normal'
                  defaultValue='Exprince'
                  options={['2+ Year ', '4+ Year', '6+ Year']}
                />
              </div>
            </div>
          </div>
          <div>
            <JobCards />
          </div>
        </div>
      </div>

      <div className={style.childDiv}>
        <div className='container mx-auto'>
          <p className='text-[24px] m-auto max-w-[937px] text-center font-normal leading-[30px] py-2 font-[Gelasio] text-[#FFFFFF]'>
            Below are the open positions we have
          </p>
          <h1 className='text-[40px] mb-4 text-center text-[#FFFFFF] font-bold leading-[50.78px] font-Gelasio'>
            Join DEVtrust and kickstart your career with us!
          </h1>
          <div className='flex justify-center'>
            <button
              type='button'
              className='text-white bg-[#474747] font-medium font-Inter text-sm px-5 py-2.5'
            >
              APPLY NOW
            </button>
          </div>
        </div>
      </div>

      <div className='container mx-auto mb-20'>
        <OurCulture />
      </div>

      <div className='container mx-auto mb-20'>
        <OurValue />
      </div>
      <div>
        <CarrierField />
      </div>
    </div>
  );
};
export default Carrier;

const facility = [
  {
    img: medical,
  },
  {
    img: activites,
  },
  {
    img: growth,
  },
  {
    img: health,
  },
];
