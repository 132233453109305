import React from 'react';
import PropTypes from 'prop-types';

const ContentArea = ({ className = '' }) => {
  return (
    <div
      className={`w-[1305px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[104px] max-w-full text-center text-45xl text-chocolate-100 font-inter mq450:gap-[26px] mq750:gap-[52px] mq1050:flex-wrap ${className}`}
    >
      <div className='w-[422px] flex flex-col items-start justify-start pt-[216px] px-0 pb-0 box-border min-w-[422px] max-w-full mq450:pt-[140px] mq450:box-border mq750:min-w-full mq1050:flex-1'>
        <div className='self-stretch flex flex-col items-start justify-start gap-[27px]'>
          <div className='flex flex-row items-start justify-start py-0 px-[75px] mq450:pl-5 mq450:pr-5 mq450:box-border'>
            <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
              Top Spin
            </h1>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='h-[90px] relative text-xl leading-[30px] text-dimgray-200 inline-block mq450:text-base mq450:leading-[24px]'>
            <p className='m-0'>Enhancing user satisfaction and streamlining</p>
            <p className='m-0'>the booking process for sports enthusiasts</p>
            <p className='m-0'>worldwide.</p>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Top-Spin/mask-group.svg'
      />
    </div>
  );
};

ContentArea.propTypes = {
  className: PropTypes.string,
};

export default ContentArea;
