import React from 'react';
import PropTypes from 'prop-types';

const SolutionFeatures = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[17px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[38px] max-w-full mq450:gap-[19px]'>
        <div className='w-[838px] flex flex-row items-start justify-end py-0 px-[45px] box-border max-w-full mq1050:pl-[22px] mq1050:pr-[22px] mq1050:box-border'>
          <div className='flex-1 flex flex-row flex-wrap items-start justify-start gap-14 max-w-full mq750:gap-7'>
            <div className='h-[83.8px] w-[50px] flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border'>
              <img
                className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
                loading='lazy'
                alt=''
                src='/caseStudies/Bounty/layer-19.svg'
              />
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[417px] max-w-full mq750:min-w-full'>
              <div className='relative font-semibold inline-block max-w-full mq450:text-lg'>
                Comprehensive Task Management
              </div>
              <div className='h-[60px] relative text-lg leading-[30px] inline-block'>
                <p className='m-0'>{`Implemented features like "Create Bounty", "Claim Bounty", "Close Bounty", `}</p>
                <p className='m-0'>
                  &quot;Manage Bounty&quot;, and &quot;Recipe Creation&quot; for
                  thorough task management.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <div className='w-[798px] flex flex-row flex-wrap items-start justify-start pt-0 px-0 pb-1 box-border gap-[51px] max-w-full mq450:gap-[25px]'>
          <div className='flex flex-col items-start justify-start pt-[18px] px-0 pb-0'>
            <img
              className='w-[60px] h-[61.3px] relative'
              loading='lazy'
              alt=''
              src='/caseStudies/Bounty/group.svg'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold mq450:text-lg'>
              Role-Based Access
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
              Developed distinct roles within the system, including Admin,
              Quality Manager, and Recipe Creator, to streamline
              responsibilities and access.
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-1.5 box-border gap-[37px] max-w-full mq450:gap-[18px]'>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <div className='w-[795px] flex flex-row flex-wrap items-end justify-start gap-[58px] max-w-full mq450:gap-[29px]'>
            <div className='flex flex-col items-start justify-end pt-0 px-0 pb-[22.8px]'>
              <img
                className='w-[50px] h-[52.2px] relative'
                alt=''
                src='/caseStudies/Bounty/group-1.svg'
              />
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-3 min-w-[447px] max-w-full mq750:min-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                Quality Assurance Tools
              </div>
              <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
                <p className='m-0'>{`Enabled Quality Managers to ensure tasks meet the `}</p>
                <p className='m-0'>required standards.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-1 flex flex-row items-start justify-start pt-0 px-0 pb-[3px] box-border max-w-full'>
          <div className='self-stretch flex-1 relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
        </div>
        <div className='w-[800px] flex flex-row flex-wrap items-end justify-start gap-[53px] max-w-full mq450:gap-[26px]'>
          <div className='h-[76px] w-[60px] flex flex-col items-start justify-end pt-0 px-0 pb-[21.2px] box-border'>
            <img
              className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
              loading='lazy'
              alt=''
              src='/caseStudies/Bounty/group-1000001341.svg'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold mq450:text-lg'>
              User-Friendly Interface
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
              <p className='m-0'>{`Built using PHP and Laravel, providing an intuitive and efficient `}</p>
              <p className='m-0'>user experience.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionFeatures.propTypes = {
  className: PropTypes.string,
};

export default SolutionFeatures;
