import React, { useMemo, useEffect, useRef } from 'react';
import Cards from '../../atoms/card/Cards';
import team from '../../assets/images/dadicatedteam.webp';
import project from '../../assets/images/projectmanagement.webp';
import account from '../../assets/images/accountmanagement.webp';
import contracts from '../../assets/images/flexiblecontract.webp';
import style from './CardHeader.module.css';
import Slider from 'react-slick';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

function NextArrow({ onClick }) {
  return (
    <div
      className='absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer z-10 lg:hidden'
      onClick={onClick}
    >
      <BsChevronRight size={24} />
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div
      className='absolute top-1/2 left-4 transform -translate-y-1/2 cursor-pointer z-10 lg:hidden'
      onClick={onClick}
    >
      <BsChevronLeft size={24} />
    </div>
  );
}

function CardHeader() {
  const sliderref = useRef();

  const cardItems = useMemo(() => {
    return data.map((item) => (
      <Cards
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  useEffect(() => {
    if (sliderref !== undefined) {
      sliderref.current.slickGoTo(4);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: true, // Enable arrows for mobile view
        },
      },
    ],
  };

  return (
    <div className='xl:py-8 xsm:py-0' style={{ overflow: 'hidden' }}>
      <h1
        className='text-center font-Gelasio xl:text-[36px] xsm:text-[28px] xl:p-2 xsm:px-0 xsm:pb-5 font-bold xl:leading-[40px] xsm:leading-[40.63px]
        xsm:bg-white xl:bg-transparent'
      >
        Why Hire{' '}
        <span className='text-custom-green  xl:text-[40px] xsm:text-[32px] xl:leading-[40px] xsm:leading-[40.63px]'>
          DEVtrust?
        </span>
      </h1>
      <div className={`${style['main-cardheader']}`}>
        <Slider {...settings} ref={sliderref}>
          {cardItems}
        </Slider>
      </div>
    </div>
  );
}

export default CardHeader;

const data = [
  {
    id: 1,
    img: team,
    title: 'Dedicated Team',
    description:
      'Scalable team of skillful, dedicated & experienced software professionals.',
  },
  {
    id: 2,
    img: project,
    title: 'Project Management',
    description:
      'As you scale your development team, DEVtrust engages Project Manager to make task assignment & communication seamless.',
  },
  {
    id: 3,
    img: account,
    title: 'Account Management',
    description:
      'Dedicated Account Managers to take you through your DEVtrust journey.',
  },
  {
    id: 4,
    img: contracts,
    title: 'Flexible Contracts',
    description: 'Simple flexible monthly billing contracts.',
  },
];
