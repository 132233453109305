import React from 'react';
import BenefitItems from './BenefitItems';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-2.5 box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Portfolio-Watc/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full lg:pt-[58px] lg:box-border mq750:pt-[38px] mq750:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#e70000,_#3e0000)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-[41px] box-border max-w-full gap-5 z-[1] mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#e70000,_#3e0000)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-5xl leading-[35px] font-medium z-[2] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[106px] flex flex-col items-start justify-start'>
              <img
                className='w-[36.2px] h-[46.6px] relative z-[2]'
                alt=''
                src='/caseStudies/Portfolio-Watc/group-1000001359.svg'
              />
            </div>
          </div>
          <div className='self-stretch bg-gray-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[85px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[54px] mq750:box-border mq450:gap-[21px] mq1050:gap-[42px] mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
            <img
              className='w-[950px] h-[715px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Portfolio-Watc/rectangle-3424.svg'
            />
            <div className='w-[738px] flex flex-col items-start justify-start gap-[55px] max-w-full mq750:gap-[27px]'>
              <BenefitItems
                fullyFunctionalInteractive='Fully Functional Interactive Application'
                usersNowHaveAccessToAComprehe='Users now have access to a comprehensive web application that effectively monitors their investments.'
              />
              <BenefitItems
                propAlignSelf='stretch'
                propWidth='unset'
                fullyFunctionalInteractive='Secure Integration with Brokerage Accounts'
                propDisplay='inline-block'
                usersNowHaveAccessToAComprehe='Enabled users to connect their brokerage accounts securely, facilitating monitoring for potential securities fraud and legal activities.'
              />
              <BenefitItems
                propAlignSelf='stretch'
                propWidth='unset'
                fullyFunctionalInteractive='Enhanced Data Safety'
                propDisplay='unset'
                usersNowHaveAccessToAComprehe='Leveraged secure connections to ensure heightened data protection for users.'
              />
            </div>
            <BenefitItems
              propAlignSelf='unset'
              propWidth='738px'
              fullyFunctionalInteractive='Improved User Experience'
              propDisplay='unset'
              usersNowHaveAccessToAComprehe='Implemented search filters to enhance navigation and personalize the user experience.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
