import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const TestimonialContainer = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[3px] box-border max-w-full text-center text-17xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[1140px] flex flex-col items-end justify-start gap-[88px] max-w-full lg:gap-11 mq750:gap-[22px]'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full'>
          <div className='flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <div className='flex flex-row items-start justify-end py-0 pl-[52px] pr-[49px] mq750:pl-[26px] mq750:pr-6 mq750:box-border'>
              <h1 className='m-0 relative text-inherit font-semibold font-inherit mq450:text-3xl mq1050:text-10xl'>
                Intrax&apos;s user-friendly interface
              </h1>
            </div>
            <div className='relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
              Manage construction-related orders and generating invoices
            </div>
          </div>
        </div>
        <div className='self-stretch h-[942px] relative'>
          <div className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] bg-cover bg-no-repeat bg-[top]'>
            <img
              className='absolute top-[0px] left-[0px] rounded-3xs w-full h-full object-cover hidden'
              alt=''
              src='/caseStudies/Intrax/07082024-120401-rec@2x.png'
            />
            <TextField
              className='[border:none] bg-[transparent] absolute top-[394.4px] left-[557.7px] z-[1]'
              variant='outlined'
              sx={{
                '& fieldset': { border: 'none' },
                '& .MuiInputBase-root': {
                  height: '36.1px',
                  backgroundColor: '#fff',
                  borderRadius: '0px 0px 0px 0px',
                },
                width: '94.7px',
              }}
            />
          </div>
          <img
            className='absolute top-[140px] left-[470px] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
            loading='lazy'
            alt=''
            src='/caseStudies/Intrax/07082024-120458-rec@2x.png'
          />
          <img
            className='absolute top-[501px] left-[235px] rounded-3xs w-[670px] h-[441px] object-cover z-[3]'
            alt=''
            src='/caseStudies/Intrax/07082024-120533-rec@2x.png'
          />
        </div>
      </div>
    </section>
  );
};

TestimonialContainer.propTypes = {
  className: PropTypes.string,
};

export default TestimonialContainer;
