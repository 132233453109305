import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[82px] pl-5 pr-[23px] box-border max-w-full text-left text-29xl text-white font-inter mq750:pb-[34px] mq750:box-border mq1050:pb-[53px] mq1050:box-border ${className}`}
    >
      <div className='w-[1137px] flex flex-row flex-wrap items-end justify-center gap-[71px] max-w-full lg:gap-[35px] mq750:gap-[18px]'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[92px] min-w-[519px] max-w-full mq450:gap-[23px] mq750:min-w-full mq1050:gap-[46px]'>
          <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full'>
            <img
              className='h-[73px] w-[60px] absolute !m-[0] top-[-71px] right-[86px]'
              loading='lazy'
              alt=''
            />
            <div className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#009951,_#002916)] flex flex-col items-start justify-start pt-8 pb-[82px] pl-[78px] pr-[30px] box-border gap-4 max-w-full z-[2] mq450:pt-[21px] mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border'>
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#009951,_#002916)] hidden max-w-full' />
              <div className='self-stretch flex flex-col items-start justify-start gap-[0.8px]'>
                <div className='self-stretch flex flex-row items-start justify-end'>
                  <img
                    className='h-[57.2px] w-[50px] relative shrink-0 z-[3]'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Intrax/group-1000001361.svg'
                  />
                </div>
                <h1 className='m-0 w-[249px] relative text-inherit inline-block shrink-0 z-[3] font-inherit mq450:text-10xl mq1050:text-19xl'>
                  <h3 className='m-0'>
                    <b>{`Problem `}</b>
                  </h3>
                  <p className='m-0'>Statement</p>
                </h1>
              </div>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-start py-0 pl-[3px] pr-0 box-border max-w-full text-center text-13xl'>
            <div className='flex-1 flex flex-row items-start justify-start gap-[18px] max-w-full mq750:flex-wrap'>
              <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[270px] max-w-full mq450:gap-5'>
                <div className='rounded-3xs bg-seagreen-200 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-seagreen-200 hidden' />
                  <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    1
                  </b>
                </div>
                <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    <p className='m-0'>Integration with</p>
                    <p className='m-0'>NetSuite</p>
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <ol className='m-0 font-inherit text-inherit pl-6'>
                      <li className='mb-0'>
                        Creating custom APIs to facilitate seamless
                      </li>
                    </ol>
                    <p className='m-0'>{`     communication between the website and `}</p>
                    <p className='m-0'>{`     NetSuite. `}</p>
                    <p className='m-0'>&nbsp;</p>
                    <ol className='m-0 font-inherit text-inherit pl-6'>
                      <li className='mb-0'>{`Ensuring that all order details are accurately `}</li>
                    </ol>
                    <p className='m-0'>
                      {' '}
                      posted to NetSuite for effective management.
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start justify-start gap-10 min-w-[362px] max-w-full mq450:gap-5 mq450:min-w-full mq750:flex-1'>
                <div className='rounded-3xs bg-seagreen-100 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-seagreen-100 hidden' />
                  <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    2
                  </b>
                </div>
                <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    <p className='m-0'>Order Management and</p>
                    <p className='m-0'>Invoice Generation</p>
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 inline-block min-h-[210px] z-[1]'>
                    <ol className='m-0 font-inherit text-inherit pl-6'>
                      <li className='mb-0'>{`Automating the process of order `}</li>
                    </ol>
                    <p className='m-0'>{`     management and invoice generation to `}</p>
                    <p className='m-0'> reduce manual efforts and errors.</p>
                    <p className='m-0'>{`2. Pulling invoice data from NetSuite and `}</p>
                    <p className='m-0'>
                      {' '}
                      displaying it on the user&apos;s dashboard in
                    </p>
                    <p className='m-0'> real-time.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[268px] flex flex-col items-start justify-start gap-10 text-center text-13xl mq450:gap-5'>
          <div className='rounded-3xs bg-mediumseagreen-100 flex flex-row items-start justify-start py-[15px] pl-6 pr-[23px] z-[1]'>
            <div className='h-[69px] w-[69px] relative rounded-3xs bg-mediumseagreen-100 hidden' />
            <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
              3
            </b>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
            <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
              <p className='m-0'>User</p>
              <p className='m-0'>Experience</p>
            </div>
            <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
              <ol className='m-0 font-inherit text-inherit pl-6'>
                <li className='mb-0'>Ensuring a user-friendly</li>
              </ol>
              <p className='m-0'> interface for placing orders</p>
              <p className='m-0'>{`       and viewing invoices. `}</p>
              <p className='m-0'>&nbsp;</p>
              <ol className='m-0 font-inherit text-inherit pl-6'>
                <li className='mb-0'>{`Providing real-time updates `}</li>
              </ol>
              <p className='m-0'>{`      to users about their orders `}</p>
              <p className='m-0'> and invoices.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
