import React from 'react';

const SuccessAlert = ({ isOpen, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className='flex items-center justify-center bg-white h-full min-h-[500px] p-10'>
      <div className='text-center'>
        <img
          src='/img/successCheck.png'
          alt='Success'
          className='w-32 h-32 mx-auto'
        />
        <h1 className='mt-4 text-[40px] font-bold text-[#00C72E]'>{title}</h1>
        <div className='mt-2 text-[20px] font-normal text-center text-[#3C3C3B]'>
          {message}
        </div>
      </div>
    </div>
  );
};

export default SuccessAlert;
