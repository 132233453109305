import React from 'react';
import HomeBanner from '../../../common/HomeBanner';
import '../../../caseStudyStyle/case-study-style.css';
import '../../../caseStudyStyle/case-study-common.css';
import '../../../caseStudyStyle/case-study-global.css';
import '../../../caseStudyStyle/case-study-responsive.css';
import frame1 from '../../../assets/Billing-Zero/frame1.svg';
import logo from '../../../assets/Billing-Zero/billingzerologo-1@2x.png';
import benefitLogo from '../../../assets/Billing-Zero/billingzerologo-1@2x.png';
import rectangle from '../../../assets/Billing-Zero/rectangle-3416@2x.png';
import bg from '../../../assets/skyward/workstepbg@2x.png';
import IntroWrapper from '../../../common/IntroWrapper';
import ChallengeWrapper from '../../../common/ChallengeWrapper';
import SolutionWrapper from '../../../common/SolutionWrapper';
import SolutionBlock from '../../../common/SolutionBlock';
import icon1 from '../../../assets/Billing-Zero/icon1.svg';
import icon2 from '../../../assets/Billing-Zero/icon2.svg';
import icon3 from '../../../assets/Billing-Zero/icon3.png';
import interfaceImg from '../../../assets/Billing-Zero/interface.svg';

import benefitIcon from '../../../assets/Billing-Zero/bxuparrow@2x.png';
import benefitImage from '../../../assets/Billing-Zero/rectangle-3422@2x.png';
import BenefitsSection from '../../../common/BenefitsSection';
import InterfaceSection from '../../../common/InterfaceSection';

const challengeCardsData = [
  {
    number: 1,
    title: 'Process-related Overheads',
    description:
      'Time-consuming proposal, contract, and invoice management processes using Excel sheets and other offline tools.',
    boxClass: '#0093D1',
  },
  {
    number: 2,
    title: 'Offline Payments',
    description:
      'Offline fund transfers with little to no online tracking or reports.',
    boxClass: '#1CACE9',
  },
  {
    number: 3,
    title: 'Repeated Follow-ups',
    description: 'Rigorous follow-ups to get the invoices cleared.',
    boxClass: '#3FC6FF',
  },
];

const solutionData = [
  {
    title: 'Streamlined Workflow',
    imageSrc: icon1,
    imageAlt: 'Client Verification Image',
    descriptions: [
      'The paid subscribers can now create, send, and manage proposals, contracts,',
      'and invoices to their clients — all in one place',
    ],
    steps: [{ isActive: true }, { isActive: false }],
  },
  {
    title: 'Integrated System',
    imageSrc: icon2,
    imageAlt: 'Transcription',
    descriptions: [
      'Eliminates the need for multiple tools and manual processes, saving time and',
      'reducing the risk of errors',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
  {
    title: 'Online Payments',
    imageSrc: icon3,
    imageAlt: 'Document Management Image',
    descriptions: [
      'Stripe API integration to enable seamless and secure bank account verification',
      'and payment processing using ACH, credit & debit cards',
    ],
    steps: [{ isActive: false }, { isActive: false }],
  },
];
const benefitsData = [
  {
    title: 'Efficient Management',
    description: 'Streamlined proposal, contract, and invoice management.',
    // percentage: '60%',
    imageSrc: benefitIcon,
    imageAlt: 'Streamlined Client Onboarding Arrow',
  },
  {
    title: 'Versatile Contracts',
    description: 'Recurring (hourly/weekly/monthly) and one-time contracts.',
    // percentage: '80%',
    imageSrc: benefitIcon,
    imageAlt: 'Secure Transactions Arrow',
  },
  {
    title: 'Payment Automation',
    description: 'Automated payment reminders with secure online payments.',
    // percentage: '100%',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
  {
    title: 'Seamless Transfers',
    description:
      'Automated funds transfer to the paid subscribers using "Stripe Connect".',
    // percentage: '100%',
    imageSrc: benefitIcon,
    imageAlt: 'Efficient Document Management Arrow',
  },
];

const Billing_Zero = () => {
  return (
    <div className='flex flex-col space-y-40'>
      <section className='frame-parent mt-5'>
        <HomeBanner
          title='Billing '
          subtitle='zero'
          textcolor=''
          subtextcolor='#0070B9'
          description=''
          image={frame1}
          How=''
          DEVtrust={''}
          highlightedText={
            'Billing Zero is an all-in-one SAAS solution for automating business workflows  and managing contracts with advanced invoicing and billing features.'
          }
        />
      </section>
      <IntroWrapper
        intro='Introduction'
        decription='Billing Zero is a business process flow automation & Contract management SAAS 
        with extensive automated invoicing and billing features.'
      />
      <ChallengeWrapper
        icon={logo}
        bg={bg}
        rectangle={rectangle}
        cardDes1='Here are the challenges which we faced at the time of develop the application'
        cardDes2=''
        challengeCards={challengeCardsData}
        gradient='linear-gradient(135deg, #49C9FF, #0093D1)'
      />
      <SolutionWrapper
        title='Solutions Blueprint'
        description='DEVtrust implemented several innovative solutions to address these challenges
        '
      />
      <SolutionBlock solutionData={solutionData} />
      <BenefitsSection
        benefitsData={benefitsData}
        benefitLogo={benefitLogo}
        benefitIMG={benefitImage}
        bgColor={'#005F88'}
        textColor={''}
        gradient='linear-gradient(135deg, #49C9FF, #0093D1 60.5%)'
      />
      <InterfaceSection
        heading='Billing Zero’s user-friendly interface'
        Description='Visual Representation of Billing Zero`s Features'
        interfaceImg={interfaceImg}
      />
    </div>
  );
};

export default Billing_Zero;
