import React from 'react';
import bgimg from '../assets/N-foundation/group-3051956.svg';

const convertBoldMarkers = (text) => {
  return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};

const SolutionBlock = ({ solutionData }) => {
  return (
    <section className='solution-block'>
      <img className='case-studies-item' alt='' src={bgimg} />
      <div className='w-[914px] flex flex-row items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
        <div className='self-stretch w-[90%] flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
          {solutionData?.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start justify-start py-0 box-border max-w-full'
            >
              <div className='flex flex-row items-start justify-between w-full gap-[30px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                  <img
                    className='w-full h-auto max-w-full overflow-hidden object-cover'
                    loading='lazy'
                    alt={item?.imageAlt}
                    src={item?.imageSrc}
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3'>
                  <b className='font-semibold text-[20px] leading-[25.57px] md:leading-[43.57px] text-[#000]'>
                    {item?.title}
                  </b>
                  <div
                    className='text-[16px] leading-[25px]  text-[#000]'
                    dangerouslySetInnerHTML={{
                      __html: item?.descriptions
                        .map((desc) => convertBoldMarkers(desc))
                        .join(''),
                    }}
                  />
                  {/* </div> */}
                </div>
              </div>
              <div className='self-stretch mt-4'>
                <hr className='border-t-[1px] border-solid border-lightgray' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolutionBlock;
