import React from 'react';
import IntegrationDetails from './IntegrationDetails';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`w-[1406px] flex flex-row items-start justify-center pt-0 px-5 pb-[7px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[948px] flex flex-col items-start justify-start gap-[54px] max-w-full mq750:gap-[27px]'>
        <div className='w-[914px] flex flex-row items-start justify-center pt-0 px-5 pb-[81px] box-border max-w-full text-center text-17xl'>
          <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
              <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl'>
                Solutions
              </h1>
            </div>
            <div className='self-stretch relative text-xl mq450:text-base'>
              Devtrust implemented a comprehensive solution to address these
              challenges
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-end justify-start gap-[55px] max-w-full mq750:gap-[27px]'>
          <div className='w-[829px] flex flex-col items-start justify-start gap-[11px] max-w-full'>
            <div className='w-[568px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                Custom Dashboard Development
              </div>
            </div>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
              <div className='flex flex-col items-start justify-start pt-[11px] px-0 pb-0'>
                <img
                  className='w-[60px] h-[60px] relative overflow-hidden shrink-0'
                  loading='lazy'
                  alt=''
                  src='/dashboard-10518957.svg'
                />
              </div>
              <div className='flex-1 relative leading-[30px] inline-block min-w-[469px] max-w-full mq750:min-w-full'>
                <ul className='m-0 font-inherit text-inherit pl-6'>
                  <li className='mb-0'>{`Built user-specific dashboards using Laravel for both frontend and backend,  `}</li>
                </ul>
                <p className='m-0'> with MySQL as the database.</p>
                <ul className='m-0 font-inherit text-inherit pl-6'>
                  <li className='mb-0'>{`Developed tailored interfaces for Admin, Setter Manager, Closer Manager, Setter, `}</li>
                </ul>
                <p className='m-0'>
                  {' '}
                  and Closer roles to ensure each user had access to relevant
                  data and tools.
                </p>
              </div>
            </div>
          </div>
          <div className='self-stretch h-px flex flex-row items-start justify-end py-0 pl-0 pr-[34px] box-border max-w-full'>
            <div className='self-stretch flex-1 relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full z-[1]' />
          </div>
        </div>
        <div className='w-[914px] flex flex-col items-end justify-start gap-[54.5px] max-w-full mq450:gap-[27px]'>
          {/* <IntegrationDetails
            realTimeIntegrationWithSt="Real-Time Integration with Stripe"
            realTime10518957="/real-time-10518957.svg"
            integratedStripeForPaymen="Integrated Stripe for payment processing, enabling real-time updates on sales, payments, and commissions."
            implementedAccurateCommis="Implemented accurate commission calculations, ensuring real-time visibility of earnings for sales personnel."
          /> */}
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
          <IntegrationDetails
            propWidth='740px'
            realTimeIntegrationWithSt='Comprehensive Data Reporting and Management'
            realTime10518957='/mamagment-10518957.svg'
            integratedStripeForPaymen='Designed detailed reporting features for tracking sales performance, commissions, and team metrics.'
            implementedAccurateCommis='Developed tools for managing client transactions, future revenue projections, churn rate analysis, and payment issue reporting.'
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
