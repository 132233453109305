import React from 'react';
import ResultItemContainers1 from './ResultItemContainers1';
import ResultItemContainers from './ResultItemContainers';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-end justify-center pt-0 px-0 pb-2.5 box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[936px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-full lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0 object-cover lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/Bounty/rectangle-3422@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start min-w-[617px] max-w-full mq750:min-w-full'>
        <div className='self-stretch [background:linear-gradient(135deg,_#22a7f0,_#130cb7)] flex flex-col items-start justify-start pt-[42px] pb-[43px] pl-[62px] pr-5 box-border relative gap-4 max-w-full mq750:pl-[31px] mq750:box-border'>
          <div className='w-[950px] h-[252px] relative [background:linear-gradient(135deg,_#22a7f0,_#130cb7)] hidden max-w-full z-[0]' />
          <img
            className='w-[50px] h-[50px] absolute !m-[0] top-[calc(50%_-_25px)] right-[40px] object-cover z-[1]'
            alt=''
            src='/caseStudies/Bounty/logoiconlight-1@2x.png'
          />
          <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
            <p className='m-0'>
              <b>{`Results `}</b>
            </p>
            <p className='m-0'>Which we achieved</p>
          </h1>
          <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
            The solutions delivered impactful outcomes
          </div>
        </div>
        <div className='self-stretch bg-gray-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[84px] box-border gap-[54.7px] max-w-full z-[1] text-xl mq450:gap-[27px] mq750:pt-[49px] mq750:pb-[55px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
          <img
            className='w-[950px] h-[595px] relative hidden max-w-full'
            alt=''
            src='/caseStudies/Bounty/rectangle-3424.svg'
          />
          <ResultItemContainers1
            enhancedTaskAllocation='Enhanced Task Allocation'
            streamlinedTaskCreationAnd='Streamlined task creation and assignment, reducing task allocation time by '
            prop='40%'
          />
          <ResultItemContainers
            effectiveRoleManagement='Effective Role Management'
            clearRoleDefinitionsImproved='Clear role definitions improved task management and accountability. '
          />
          <ResultItemContainers1
            enhancedTaskAllocation='Improved Task Quality'
            streamlinedTaskCreationAnd='Quality assurance features increased task adherence to standards by '
            prop='30%'
          />
          <ResultItemContainers
            effectiveRoleManagement='Real-Time Tracking'
            clearRoleDefinitionsImproved='Enhanced task tracking improved project monitoring and timely task completion.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
