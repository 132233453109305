/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import style from './backend.module.css';
import icon from '../../assets/socialpng/backend.png';
import Uihovercard from '../../atoms/card/Uihovercard';
import php from '../../assets/socialpng/php.png';
import node from '../../assets/socialpng/nodejs.png';
import python from '../../assets/socialpng/pytho.png';
import ruby from '../../assets/socialpng/ruby.png';
import backone from '../../assets/socialpng/backone.png';
import backtwo from '../../assets/socialpng/backtwo.png';
import backthree from '../../assets/socialpng/backthree.png';
import BackendCard from './backendCard/BackendCard';
import { Link } from 'react-router-dom';

const Backend = () => {
  const cardItems1 = useMemo(() => {
    return imga.map((item) => (
      <Uihovercard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='backend'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='lg:max-w-7xl xl:px-20 md:px-6 xsm:px-4 mx-auto'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#000] font-bold leading-[50.78px] font-Gelasio'>
              <span className='text-[#43BDC1]'>Scalable & Secure</span>
              <br /> Back-end Development
            </h1>
            <p className='text-lg xl:w-[400px] w-auto mt-5 font-normal leading-[30px] py-2 font-[Inter]'>
              Our back-end development services provide secure, scalable, and
              high-performance solutions, ensuring seamless integration and
              efficient data processing. We deliver custom back-end systems
              tailored to your business needs, empowering growth and innovation.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get In Touch
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
      </div>

      <div className={style.backend}>
        <div className='container mx-auto'>
          <div className={style.child}>
            <div className='p-4 m-auto'>
              <h1 className='text-[#000] text-[40px] font-Gelasio font-bold leading-[50.78px]'>
                Back-end development refers to the powerhouse of the web
              </h1>
              <p className='text-[#000] font-Inter leading-[32px] text-[18px] font-normal'>
                {`Back-end development isn't just about code and servers; it's about
              problem-solving, critical thinking, and a knack for building
              intricate systems that power the digital world.`}
              </p>
            </div>

            <div className=' p-4'>
              {backendData.map((item, index) => (
                <div
                  key={index}
                  className='w-full flex flex-col sm:flex-row items-start mb-4'
                >
                  <img
                    className='min-w-[104px] max-h-[100px]'
                    style={{ padding: '15px' }}
                    src={item.img}
                    alt={`Service ${index + 1}`}
                  />
                  <div>
                    <h5 className='text-[#000] text-[24px] font-semibold font-Inter leading-[29.05px]'>
                      {item.title}
                    </h5>
                    <p className='text-[#8B8C8C] font-[Plus Jakarta Sans] text-[14px] leading-[24px] font-normal'>
                      {item.discription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='md:pl-0 md:pr-0 pl-3 pr-3'>
        <h1 className={style.Title}>
          Back-end{' '}
          <span className='text-custom-green ml-2 '>Development process</span>
        </h1>
        <p className='text-xl font-medium text-center text-gray-medium'>
          Back-end developers follow a series of steps to create and maintain
          the user interface of a website or application. <br />
          These steps typically include:
        </p>
      </div>

      <div className={style.Container}>
        <div className='mt-16'>
          <BackendCard />
        </div>
      </div>

      <div className={style.Container}>
        <h1 className={style.Title}>
          Frameworks, Databases &{' '}
          <span className='text-custom-green ml-2 '>Languages</span>
        </h1>
        <div className='font-[Inter] grid xl:grid-cols-3 xsm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-0 my-3 items-center justify-center '>
          {cardItems1}
        </div>
      </div>
    </div>
  );
};
export default Backend;

const backendData = [
  {
    img: backone,
    title: 'Flexibility and Integration',
    discription:
      'Back-end APIs enable seamless integration with other systems and services, enhancing functionality and user experience.',
  },
  {
    img: backtwo,
    title: 'Security and Control',
    discription:
      'Back-end developers implement security measures to protect sensitive data and ensure the integrity of the application.',
  },
  {
    img: backthree,
    title: 'Stability and Scalability',
    discription:
      'Back-end code creates a robust foundation for websites and applications, allowing them to handle large volumes of traffic and growth.',
  },
];

const imga = [
  {
    id: 1,
    img: php,
    description: `PHP (Hypertext Preprocessor) is an open-source scripting language primarily used for server-side scripting, meaning it runs on the web server and processes code before sending the result to the client's web browser. It is used to create web services and APIs.`,
  },

  {
    id: 3,
    img: node,
    description: `Node.js is an open-source, cross-platform JavaScript runtime built on Chrome's V8 JavaScript engine. It allows developers to run JavaScript code on the server side, enabling the development of scalable and high-performance web applications.`,
  },
  {
    id: 4,
    img: python,
    description: `Python is a versatile, high-level, and interpreted programming language known for its readability and ease of use. It is used in various applications, from web development to data science and artificial intelligence.`,
  },
  {
    id: 5,
    img: ruby,
    description: `Ruby on Rails, often simply referred to as Rails, is a web application framework written in the Ruby programming language. It follows the Model-View-Controller (MVC) architectural pattern and emphasizes convention over configuration.`,
  },
];
