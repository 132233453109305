import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent4 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[1140px] flex flex-col items-end justify-start gap-[92px] max-w-full lg:gap-[46px] mq750:gap-[23px]'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='text-[36px] text-[#000] flex flex-col items-center justify-center gap-[23px] max-w-full'>
            <div className='flex flex-row items-start justify-end py-0 pl-[30px] pr-[27px]'>
              <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl'>
                The Contractor ETA&apos;s user-friendly interface
              </h1>
            </div>
            <div className='self-stretch relative text-[20px] mq450:text-base'>
              <p className='m-0'>
                Integrates with the pro&apos;s CRM / Scheduler
              </p>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[765.6px] flex flex-row items-start justify-start relative shrink-0'>
          <div className='h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]'>
            <img
              className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
              alt=''
              src='/caseStudies/Contractor-ETA/image-5@2x.png'
            />
            <img
              className='absolute top-[116px] left-[600px] rounded-xl w-[300px] h-[649.6px] object-cover z-[1]'
              loading='lazy'
              alt=''
              src='/caseStudies/Contractor-ETA/transactions@2x.png'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent4;
