import React from 'react';
import styles from './Cards.module.css';

const Uxcard = (props) => {
  const { img, title, id, description } = props;

  return (
    <div className=''>
      <div className={`${styles['main-card2']} group container `} key={id}>
        <div
          className='p-2  flex items-center w-[200px] h-[200px] rounded-b-md absolute opacity-0 group-hover:opacity-100 duration-[1000ms] 
transition-all '
        >
          <p className={styles.description}>{description}</p>
        </div>
        <div
          className='flex flex-col justify-center text-center items-center  
'
        >
          <div className='w-[100px] h-[200px] flex  justify-center'>
            <img src={img} className='object-contain' />
          </div>
        </div>
      </div>
      <p
        className='py-5'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default Uxcard;
