import React from 'react';
import PropTypes from 'prop-types';
import styles from './FrameComponent.module.css';

const FrameComponent = ({
  className = '',
  enhancedDataAccess,
  increasedAccuracyAndRangeOf,
  prop,
  prop1,
}) => {
  return (
    <div className={[styles.highlightsIconsParent, className].join(' ')}>
      <div className={styles.highlightsIcons}>
        <img
          className={styles.bxupArrowIcon}
          loading='lazy'
          alt=''
          src='/caseStudies/Imagine-Scholarship/bxuparrow@2x.png'
        />
      </div>
      <div className={styles.highlightsTitles}>
        <b className={styles.enhancedDataAccess}>{enhancedDataAccess}</b>
        <div className={styles.increasedAccuracyAndContainer}>
          <span>{increasedAccuracyAndRangeOf}</span>
          <span className={styles.span}>{prop}</span>
          <span>{prop1}</span>
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  enhancedDataAccess: PropTypes.string,
  increasedAccuracyAndRangeOf: PropTypes.string,
  prop: PropTypes.string,
  prop1: PropTypes.string,
};

export default FrameComponent;
