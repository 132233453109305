import React from 'react';
import style from './Footer.module.css';
import logo from '../../assets/images/logo.webp';
import facebook from '../../assets/socialpng/facebook.png';
import linkedin from '../../assets/socialpng/linkedin.png';
import insta from '../../assets/socialpng/instagram.png';
import twitter from '../../assets/socialpng/twitter.png';
import location from '../../assets/socialpng/location.png';
import mail from '../../assets/socialsvg/gg_phone.svg';

import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className={`${style.maindivg}`}>
      <div className={`${style.containersfooterne}`}>
        <div>
          <div className={`${style.maindivfooterr}`}>
            <div className='md:hidden  w-[100%] flex flex-nowrap md:justify-center justify-between gap-x-10 md:py-0 xsm:py-10'>
              <div>
                <div className={`${style.imgparag}`}>
                  <img
                    className={`${style.image}`}
                    width='215'
                    height='220'
                    src={logo}
                    alt='Workflow'
                  />
                  <p className={`${style.para}`}>
                    Want to start a project? <br />
                    We collaborate with ambitious brand & people
                  </p>
                  <p>
                    <ul className={`${style.para}`}>
                      <Link
                        to='#'
                        onClick={(e) => {
                          window.location.href = 'mailto:hello@devtrust.biz';
                          e.preventDefault();
                        }}
                      >
                        <li className='cursor-pointer flex items-center gap-2'>
                          <img src='/static/media/list.24127b338ca7cb874f57.webp'></img>
                          <a
                            className={`${style.mailLink}`}
                            href='mailto:hello@devtrust.biz'
                          >
                            hello@devtrust.biz
                          </a>
                        </li>
                      </Link>
                    </ul>
                  </p>
                </div>

                <div className={`${style.imgparag}`}>
                  <p className={`${style.headingsfo}`}>Get In Touch</p>
                  <ul className={`${style.ul}`}>
                    <div className='lg:flex md:block gap-14'>
                      <div className={`${style.divnumber}`}>
                        <li className='flex gap-3 items-start'>
                          <img
                            width='15'
                            height='22'
                            src={location}
                            alt='Workflow'
                            className='mt-1'
                          />
                          <span className='leading-5'>
                            221 E Indianola Ave, Phoenix, Arizona 85012
                          </span>
                        </li>
                        <li className='flex gap-3 items-start mt-3'>
                          <img
                            width='18'
                            height='22'
                            src={mail}
                            alt='Workflow'
                            className='mt-1'
                          />
                          <li className='cursor-pointer leading-5'>
                            <span className='leading-5'>
                              India:&nbsp;
                              <Link
                                to='#'
                                onClick={(e) => {
                                  window.location.href = 'tel:+91-8447154118';
                                  e.preventDefault();
                                }}
                              >
                                +91-8447154118
                              </Link>
                              , USA:&nbsp;
                              <Link
                                to='#'
                                onClick={(e) => {
                                  window.location.href = 'tel:+1-347-719-4819';
                                  e.preventDefault();
                                }}
                              >
                                +1-347-719-4819
                              </Link>
                            </span>
                          </li>
                        </li>
                      </div>
                    </div>
                  </ul>

                  <div className={`${style.icondivfo}`}>
                    <div>
                      <a
                        href='https://www.facebook.com/DEVtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={facebook}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://in.linkedin.com/company/devtrust'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={linkedin}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://www.instagram.com/devtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={insta}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://twitter.com/devtrustinc'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={twitter}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden md:block w-1/2'>
              <div className={`${style.imgparag}`}>
                <img
                  className={`${style.image}`}
                  width='215'
                  height='220'
                  src={logo}
                  alt='Workflow'
                />
                <p className={`${style.para} mb-6`}>
                  Want to start a project? <br />
                  We collaborate with ambitious brand & people
                </p>
                <p>
                  <ul className={`${style.para}`}>
                    <Link
                      to='#'
                      onClick={(e) => {
                        window.location.href = 'mailto:hello@devtrust.biz';
                        e.preventDefault();
                      }}
                    >
                      <li className='cursor-pointer flex items-center gap-2'>
                        <img src='/static/media/list.24127b338ca7cb874f57.webp'></img>
                        <a
                          className={`${style.mailLink}`}
                          href='mailto:hello@devtrust.biz'
                        >
                          hello@devtrust.biz
                        </a>
                      </li>
                    </Link>
                  </ul>
                </p>
              </div>
            </div>

            <div className='w-1/2'>
              <div className='hidden md:block' style={{ paddingLeft: '170px' }}>
                <div className={`${style.imgparag}`}>
                  <p className={`${style.headingsfo}`}>Get In Touch</p>
                  <ul className={`${style.ul}`}>
                    <div className='lg:flex md:block gap-14'>
                      <div className={`${style.divnumber}`}>
                        <li className='flex gap-3 items-start'>
                          <img
                            width='15'
                            height='22'
                            src={location}
                            alt='Workflow'
                            className='mt-1'
                          />
                          <span className='leading-5'>
                            221 E Indianola Ave, Phoenix, Arizona 85012
                          </span>
                        </li>
                        <li className='flex gap-3 items-start mt-3'>
                          <img
                            width='18'
                            height='22'
                            src={mail}
                            alt='Workflow'
                            className='mt-1'
                          />
                          <li className='cursor-pointer leading-5'>
                            <span className='leading-5'>
                              India:&nbsp;
                              <Link
                                to='#'
                                onClick={(e) => {
                                  window.location.href = 'tel:+91-8447154118';
                                  e.preventDefault();
                                }}
                              >
                                +91-8447154118
                              </Link>
                              , USA:&nbsp;
                              <Link
                                to='#'
                                onClick={(e) => {
                                  window.location.href = 'tel:+1-347-719-4819';
                                  e.preventDefault();
                                }}
                              >
                                +1-347-719-4819
                              </Link>
                            </span>
                          </li>
                        </li>
                      </div>
                    </div>
                  </ul>

                  <div className={`${style.icondivfo}`}>
                    <div>
                      <a
                        href='https://www.facebook.com/DEVtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={facebook}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://in.linkedin.com/company/devtrust'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={linkedin}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    {/* <div>
                      <a
                        href='https://www.instagram.com/devtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={insta}
                          alt='Workflow'
                        />
                      </a>
                    </div> */}
                    <div>
                      <a
                        href='https://x.com/devtrustinc'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={twitter}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <p className={`${style.copyrightsss}`}>
            Copyright {new Date().getFullYear()}. DEVtrust
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
