import React from 'react';
import FrameComponent from './FrameComponent';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/RankUpAcadmey/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1050:pt-[58px] mq1050:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#e1af16,_#754400)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#e1af16,_#754400)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-inherit mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-5xl leading-[35px] font-medium z-[2] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[116px] w-[52px] flex flex-col items-start justify-start'>
              <img
                className='self-stretch h-[67px] relative max-w-full overflow-hidden shrink-0 object-cover z-[2]'
                alt=''
                src='/caseStudies/RankUpAcadmey/63f1443e4db3b97d912eea64-1-1@2x.png'
              />
            </div>
          </div>
          <div className='self-stretch bg-gray-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[85px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[54px] mq750:box-border mq450:gap-[21px] mq1050:gap-[42px] mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
            <img
              className='w-[950px] h-[652px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/RankUpAcadmey/rectangle-3424.svg'
            />
            {/*  <FrameComponent
              enhancedOperationalEffici="Enhanced Operational Efficiency"
              improvedDataVisibilityAnd="Improved data visibility and management for all user roles with 40% reduction in       administrative tasks due to automated real-time updates and reporting."
            /> */}
            <div className='w-[738px] flex flex-col items-start justify-start gap-[55px] max-w-full mq750:gap-[27px]'>
              <FrameComponent
                propWidth='unset'
                propAlignSelf='stretch'
                enhancedOperationalEffici='Accurate and Transparent Commission Tracking'
                propDisplay='inline-block'
                improvedDataVisibilityAnd='Real-time commission calculations and transparent earnings reports for sales       personnel has increased sales team satisfaction, leading to a 25% boost in overall sales performance.'
              />
              <div className='self-stretch flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
                <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                  <img
                    className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                    alt=''
                    src='/caseStudies/RankUpAcadmey/bxuparrow@2x.png'
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
                  <b className='relative z-[3] mq450:text-base'>
                    Improved Financial Management
                  </b>
                  <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
                    <span className='font-semibold'>Impact:</span>
                    <span className='whitespace-pre-wrap'>
                      {' '}
                      Seamless Stripe integration for efficient payment
                      processing and tracking made 30% reduction in payment
                      processing errors and faster resolution of payment issues.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent3;
